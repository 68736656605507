import { Component } from 'react';
import loginImg from '../Group 1174.png';

import { BrowserRouter as Router } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';

export default class GuestPageLayout extends Component {
	render() {
		return (
			<>
				<Router>
					<Container>
						<div className="login">
							<Row className="justify-content-md-center">
								<Col md={6} style={{ float: 'none', margin: 'auto' }}>
									<div>
										<img className="login-image img-fluid" src={loginImg} alt="Login" />
									</div>
								</Col>
								<Col md={6} style={{ float: 'none', margin: 'auto' }}>
									{this.props.children}
								</Col>
							</Row>
						</div>
					</Container>
				</Router>
			</>
		);
	}
}
