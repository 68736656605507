import React from 'react';
import IdleTimer, { useIdleTimer } from 'react-idle-timer';
export function AutoLogout(props: { timeout: number }) {
	useIdleTimer({
		timeout: props.timeout,
		onIdle: window.location.reload.bind(window.location),
		debounce: 500,
	});
	return <></>;
}
export default AutoLogout;
