import { Component } from 'react';
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import { Row } from 'react-bootstrap';
import { AppDep, AppContext } from '../provider';

export type TabPage = {
	name: string;
	child: JSX.Element;
};

export default class TabLayout extends Component<{ children: TabPage[] }> {
	state = {
		activeItem: 0,
	};

	toggle = (tab: number) => () => {
		if (this.state.activeItem !== tab) {
			this.setState({
				activeItem: tab,
			});
		}
	};

	render() {
		return (
			<MDBContainer>
				<MDBNav className="middle-nav mt-2 mb-3">
					{this.props.children.map((element, index) => (
						<MDBNavItem key={index}>
							<a
								className="mr-4"
								style={{
									textDecoration: 'none',
									color: `${this.state.activeItem === index ? '#DC2E20' : '#D4D4D4'}`,
									//   fontSize: "30px",
									fontSize: '1.5rem',
								}}
								onClick={this.toggle(index)}
								role="tab"
							>
								{element.name}
							</a>
						</MDBNavItem>
					))}
				</MDBNav>
				<MDBTabContent activeItem={this.state.activeItem}>
					{this.props.children.map((element, index) => (
						<MDBTabPane key={index} tabId={index} role="tabpanel">
							<div>{element.child}</div>
						</MDBTabPane>
					))}
				</MDBTabContent>
			</MDBContainer>
		);
	}
}
