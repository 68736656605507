import { GlobalContext } from './../src/common/modal_boundary';
import HomeLayout from './layout/HomeLayout';
import { AppProvider } from './provider';

export function App() {
	return (
		<>
			{GlobalContext.errorModalBoundary}
			{GlobalContext.successModalBoundary}
			<AppProvider>
				<HomeLayout />
			</AppProvider>
		</>
	);
}

export default App;
