import { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// TODO: We should set field to be keyof T. It's not working as of now though.
export class SearchWithDropDown<T> extends Component<
	{
		disabled?: boolean;
		label: string;
		field: string;
		value: T;
		optionList: T[];
		getValue: (option: T | null) => void;
	},
	{}
> {
	render() {
		return (
			<>
				<Autocomplete
					id="combo-box-demo"
					disabled={this.props.disabled}
					options={this.props.optionList}
					value={this.props.value}
					getOptionLabel={(option: any) => option[this.props.field ?? 'title']}
					style={{ width: 300 }}
					onChange={(_, newVal) => this.props.getValue(newVal)}
					renderInput={(params: any) => (
						<TextField {...params} label={this.props.label} value={this.props.value} variant="outlined" />
					)}
				/>
			</>
		);
	}
}

export default SearchWithDropDown;
