import { Component } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBInput } from 'mdbreact';
import GroupCard from '../CardComponent/GroupCard';
import { FormBloc } from '../../../src/controller/form_bloc';
import { Attachment, Group, LocalAttachment, SendMessageCampaign } from '../../../src/controller/do';
import { ValueHandler, FormInputField } from '../../../src/controller/form_bloc';
import { MapStreamBuilder, StreamBuilder } from '../../provider';
import Log from '../../common/util/log';
import { ChipComponent } from '../common/ChipComponent';

const TAG = 'send-message-form';

export class SendMessageForm extends Component<{
	formBloc: FormBloc<SendMessageCampaign.Type>;
	groupList: Group.Type[];
	onClose?: () => void;
}> {
	render() {
		return (
			<>
				<Row style={{ margin: '0' }}>
					<Col className="mt-2">
						<MDBCard style={{ width: '100%', minHeight: 'auto', maxHeight: 'auto' }}>
							<MDBCardBody>
								<div style={{ display: 'flex' }}>
									<MDBCardTitle
										style={{
											color: '#DC2E20',
											fontSize: '1.3rem',
											width: '100%',
										}}
									>
										Send Message To My Contact
									</MDBCardTitle>
									<div
										onClick={() => {
											this.props.formBloc.clearAll();
											this.props.onClose?.();
										}}
										className="btn"
										style={{ marginBottom: '14px', margin: '0', maxHeight: '48px' }}
									>
										<img style={{ width: '15px' }} src="./crossx.png" alt="close" />
									</div>
								</div>
								<Form.Group className="mb-3" controlId="formBasicPassword">
									<FormInputField
										controller={this.props.formBloc}
										objectKey="message"
										builder={(
											value: string | undefined,
											onChange: ValueHandler<string>,
											validationError: string | undefined
										) => {
											return (
												<>
													<MDBInput
														label="MESSAGE TO SEND"
														type="textarea"
														rows="8"
														outline
														value={value ?? ''}
														onChange={(e: any) => onChange(e.target.value)}
													/>
													<Form.Text style={{ color: '#DC2E20' }}>
														{validationError}
													</Form.Text>
												</>
											);
										}}
									/>
								</Form.Group>
								<FormInputField
									controller={this.props.formBloc}
									objectKey="attachmentList"
									builder={(
										value: Attachment[] | undefined,
										onChange: ValueHandler<Attachment[]>
									) => {
										return (
											<>
												<div className="mb-3 m-2 d-flex">
													<div className="mr-4 d-flex" style={{ flexWrap: 'wrap' }}>
														{value?.map(e => (
															<ChipComponent
																attachment={e ?? []}
																onRemove={e => onChange(value?.filter(f => f !== e))}
															/>
														))}
													</div>
												</div>
												<div
													className="mb-3 m-2 mr-2"
													style={{
														border: '1px solid #D4D4D4',
													}}
												>
													<div className="fileUpload">
														<input
															onChange={e =>
																onChange(
																	e.target.files
																		? [new LocalAttachment(e.target.files[0])]
																		: []
																)
															}
															type="file"
															accept="image/*"
															className="upload"
														/>
														<span>
															<MDBIcon icon="upload" /> Attach image
														</span>
													</div>
												</div>
											</>
										);
									}}
								/>
								<div className="mt-3 sendMessage-Button-1">
									<div className="send-yourself-button">
										<FormInputField
											controller={this.props.formBloc}
											objectKey="groups"
											builder={(_: number[] | undefined, onChange: ValueHandler<number[]>) => {
												return (
													<>
														<a
															onClick={() => {
																onChange([-1]);
																this.props.formBloc.onSubmit();
															}}
															style={{
																color: '#DB2A1D',
																border: '1px solid #D4D4D4',
																boxShadow: 'none',
																width: '100%',
																fontSize: '16px',
																margin: ' 0',
															}}
															className="btn"
														>
															Send Test Message To Yourself
														</a>
													</>
												);
											}}
										/>
									</div>
									<div className="clear-all-button">
										<MapStreamBuilder
											stream={this.props.formBloc.submitEnabled}
											map={e => (e ? '' : 'disabled')}
											builder={(cls: string) => {
												return (
													<>
														<a
															style={{
																color: '#DB2A1D',
																border: '1px solid #D4D4D4',
																boxShadow: 'none',
																width: '100%',
																fontSize: '16px',
																margin: ' 0',
															}}
															onClick={() => {
																this.props.formBloc.clearAll();
																this.props.onClose?.();
															}}
															className={`btn  ${cls}`}
														>
															Clear all
														</a>
													</>
												);
											}}
										/>
									</div>
								</div>

								<div className="mt-3 sendMessage-Button-2">
									<FormInputField
										controller={this.props.formBloc}
										objectKey="groups"
										builder={(
											value: number[] | undefined,
											onChange: ValueHandler<number[]>,
											validationError: string | undefined
										) => {
											return (
												<>
													<div className="sendMessage-autoComplete">
														<Autocomplete
															onChange={(_: any, value: any) =>
																onChange(value.map((e: any) => e.id))
															}
															multiple
															value={this.props.groupList.filter(e =>
																value?.find(f => f === e.id)
															)}
															id="tags-outlined"
															options={this.props.groupList}
															getOptionLabel={option => option.name}
															filterSelectedOptions
															renderInput={params => (
																<TextField
																	{...params}
																	variant="outlined"
																	label="Search Groups"
																	placeholder="Search Groups"
																/>
															)}
															renderOption={option => (
																<div style={{ width: '100%' }}>
																	<GroupCard data={option} />
																</div>
															)}
														/>
														<Form.Text style={{ color: '#DC2E20' }}>
															{validationError}
														</Form.Text>
													</div>
												</>
											);
										}}
									/>
									<div className="sendMessage-button">
										<a
											onClick={this.props.formBloc.onSubmit}
											style={{
												backgroundColor: '#DB2A1D',
												color: 'white',
												width: '100%',
												height: '58px',
												fontSize: '16px',
												paddingTop: '17px',
												margin: '0',
											}}
											className="btn"
										>
											Send Message
										</a>
									</div>
								</div>
							</MDBCardBody>
						</MDBCard>
					</Col>
				</Row>
			</>
		);
	}
}

export default SendMessageForm;
