import { Component } from 'react';
import Log from '../../common/util/log';
import { BlocProvider, StreamBuilder, AppDep, StreamModal } from '../../../src/provider';
import { FormBloc } from '../../../src/controller/form_bloc';
import { SendMessageCampaign, CentralMessage, Group, PinnedMessage, Attachment } from '../../../src/controller/do';
import { Backend } from '../../../src/controller/backend';
import { MessageCard } from '../../../src/components/CardComponent/MessageCard';
import { BehaviorSubject, Subscription } from 'rxjs';

import SendMessageForm from '../formComponent/SendMessageForm';
import TableData from '../../../src/components/common/TableData';
import DualLayout, { DualLayoutBloc } from '../../layout/MobileLayout/DualLayout';
import autoBind from 'auto-bind';
import { MDBContainer, MDBTooltip } from 'mdbreact';
import { GlobalContext } from '../../common/modal_boundary';
import { TableDataBloc } from '../../controller/table_data_bloc';
import ModalComponent from '../common/ModalComponent';
import { ProcessingModalLayout, StatusModalLayout } from '../common/ProcessingModal';
import { centralMessageSorter } from '../../controller';
import ModalComponentV2 from '../common/ModalComponentV2';
import { Button } from '../common/button';

const TAG = 'qr_code_modal';

export class QrCodeModalBloc {
	modalVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	qrCodeData: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

	private _qrCodeDataSubscription?: Subscription;

	constructor(private _api: Backend.Api) {
		autoBind(this);
	}

	public doQrCodeValidation(sessionId: number): Promise<void> {
		Log.d(TAG, 'inside startQrCodeValidation');
		return new Promise((res: any) => {
			let _qrCodeAvailable = false;
			this._qrCodeDataSubscription = this._api.subscribeToQrCodeData(sessionId).subscribe(data => {
				Log.d(TAG, 'got-new-qr-data', data);
				this.onModalOpen(data?.['data']);
				let dataHasQrCode = data && data['data'];
				if (!dataHasQrCode && _qrCodeAvailable) {
					this.onQrCodeScanCompleted();
					_qrCodeAvailable = false;
					this._qrCodeDataSubscription?.unsubscribe();
					this._qrCodeDataSubscription = undefined;
					res();
				} else if (dataHasQrCode) {
					_qrCodeAvailable = true;
				}
			});
		});
	}

	onQrCodeScanCompleted() {
		Log.d(TAG, 'inside onQrCodeScanCompleted');
		this.onModalClose();
	}

	onModalOpen(data: any) {
		Log.d(TAG, 'inside onModalOpen', data);
		this.modalVisibility.next(true);
		this.qrCodeData.next(data);
	}

	onModalClose() {
		Log.d(TAG, 'inside onModalClose');
		this._qrCodeDataSubscription?.unsubscribe();
		this._qrCodeDataSubscription = undefined;

		this.modalVisibility.next(false);
		this.qrCodeData.next(undefined);
	}
}

export const QrCodeComponent = (props: { bloc: QrCodeModalBloc }): JSX.Element => (
	<MDBContainer>
		<div style={{ width: '100%' }}>
			<StreamBuilder
				stream={props.bloc.modalVisibility}
				builder={(data: boolean) => {
					return (
						<>
							<ModalComponentV2
								isOpen={data}
								toggle={props.bloc.onModalClose}
								body={
									<StreamBuilder
										stream={props.bloc.qrCodeData}
										builder={(data: any) => {
											return (
												<>
													{data ? (
														<QrCodeModal data={data} />
													) : (
														<ProcessingModalLayout message="Please wait while we process the messages." />
													)}
												</>
											);
										}}
									/>
								}
								buttonList={[
									<Button focused className="btn button" onClick={props.bloc.onModalClose}>
										<StreamBuilder
											stream={props.bloc.qrCodeData}
											builder={(data: any) => {
												return <>{data ? 'Done' : 'Cancel'}</>;
											}}
										/>
									</Button>,
								]}
							/>
						</>
					);
				}}
			/>
		</div>
	</MDBContainer>
);

class QrCodeModal extends Component<{ data: any }, {}> {
	render() {
		return (
			<>
				<StatusModalLayout
					title={'QR Code'}
					body={
						<>
							<div className="text-center">
								<p>
									Open WhatsApp on Your Phone and Choose WhatsApp web in Setting. Click on Link a
									Device to scan the QR Code{' '}
								</p>
							</div>
							<div className="text-center mb-3">
								<img src={this.props.data} />
							</div>
							<div className="text-center">
								<p>Please wait after scan is complete until we process your request</p>
							</div>
						</>
					}
				/>
			</>
		);
	}
}
