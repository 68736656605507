import { Component } from 'react';
import { StreamBuilder, AppDep, BlocProvider, StreamModal } from './../../provider';
import { Tabular } from '../../controller/index';
import Log from './../../common/util/log';
import TableData from '../common/TableData';

import { MDBContainer, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import AddUser from '../common/AddUser';
import FabButton from '../../layout/FabButton';
import AddGroup from '../common/AddGroup';
import ModalComponent from '../common/ModalComponent';
import autoBind from 'auto-bind';
import { TableDataBloc } from '../../controller/table_data_bloc';
import { Group } from '../../controller/do';
import { BehaviorSubject } from 'rxjs';
import { FormBloc } from '../../controller/form_bloc';
import { Backend } from '../../controller/backend';
import { GlobalContext } from '../../common/modal_boundary';
import { ContactBloc } from './AllContact';
import { ModalComponentV2 } from '../common/ModalComponentV2';
import { Button, IconButton, TextButton } from '../common/button';

const TAG = 'group-contact';

export class GroupBloc {
	public readonly groupModal: BehaviorSubject<boolean> = new BehaviorSubject(
		false as boolean
	) as BehaviorSubject<boolean>;
	public readonly deleteItem: BehaviorSubject<Group.Type | undefined> = new BehaviorSubject(
		undefined
	) as BehaviorSubject<Group.Type | undefined>;
	public tableDataBloc: TableDataBloc<Group.Type>;
	public formBloc: FormBloc<Group.Type>;

	private _api: Backend.Api;

	static columns = [
		{ label: 'Id', field: 'id' },
		{ label: 'Name', field: 'name' },
		{ label: 'Number of Contacts', field: 'contacts_count' },
	];

	constructor(appDep: AppDep) {
		autoBind(this);
		this._api = appDep.api;
		this.formBloc = new FormBloc(Group.Constraints, this.onValidated);
		this.tableDataBloc = new TableDataBloc(this.getGroupData, appDep.searchBoxQuery, ['name']);
	}

	destroy() {
		Log.d(TAG, 'inside destroy');
		this.tableDataBloc.destroy();
	}

	@GlobalContext.handlErrorAsModal([])
	async getGroupData(): Promise<Group.Type[]> {
		return this._api.getGroupData();
	}

	@GlobalContext.handlErrorAsModal()
	@GlobalContext.handleSuccessAsModal(Promise, 'Group data updated successfully')
	async onValidated(item: Group.Type): Promise<void> {
		Log.d(TAG, 'inside onValidated', item);
		let ret;

		if (item.id) {
			ret = this.updateGroup(item);
		} else {
			ret = this.saveNewGroup(item);
		}

		await ret.finally(async () => {
			await this.tableDataBloc.updateData();
			this.onModalClose();
		});
	}

	private async saveNewGroup(item: Group.Type): Promise<void> {
		Log.d(TAG, 'inside saveNewGroup', item);
		await this._api.addGroup(item);
	}

	private async updateGroup(item: Group.Type): Promise<void> {
		Log.d(TAG, 'inside editGroup');
		await this._api.updateGroup(item);
	}

	onEditClicked(item: Group.Type) {
		Log.d(TAG, 'inside onEditClicked', item);
		this.onModalOpen(item);
	}

	onDeleteItem(item: Group.Type) {
		Log.d(TAG, 'inside onDeleteItem', item);
		this.deleteItem.next(item);
	}

	onDeleteItemModalClose(): void {
		this.deleteItem.next(undefined);
	}

	@GlobalContext.handlErrorAsModal()
	async onDeleteItemConfirmed(item: Group.Type): Promise<void> {
		Log.d(TAG, 'inside onDeleteItemConfirmed', item);
		await this._api.deleteGroup(item).finally(() => {
			this.deleteItem.next(undefined);
			this.tableDataBloc.updateData();
		});
	}

	onModalClose() {
		Log.d(TAG, 'inside onModalClose');
		this.groupModal.next(false);
	}

	onModalOpen(item: Group.Type | undefined) {
		Log.d(TAG, 'inside onModalOpen', item);
		this.formBloc.setObjectValue(item);
		this.groupModal.next(true);
	}
}

export class GroupContact extends Component<{ contactBloc: ContactBloc; groupBloc: GroupBloc }> {
	render() {
		return (
			<>
				<StreamBuilder
					stream={this.props.groupBloc.tableDataBloc.data}
					builder={(data: Group.Type[]) => (
						<>
							<MDBContainer>
								<DeleteModal bloc={this.props.groupBloc} />
								<StreamModal
									stream={this.props.groupBloc.groupModal}
									onClose={this.props.groupBloc.onModalClose}
								>
									<div className="m-4 mt-0">
										<AddGroup formBloc={this.props.groupBloc.formBloc} />
									</div>
								</StreamModal>
							</MDBContainer>
							<TableData
								onEdit={this.props.groupBloc.onEditClicked}
								onDelete={this.props.groupBloc.onDeleteItem}
								bordered={true}
								striped={true}
								data={{
									rows: data,
									columns: GroupBloc.columns,
								}}
								searchableFields={['name']}
							/>
							<FabButton onClick={() => this.props.groupBloc.onModalOpen(undefined)} />
						</>
					)}
				/>
			</>
		);
	}
}

class DeleteModal extends Component<{ bloc: GroupBloc }, {}> {
	constructor(props: any) {
		super(props);
		autoBind(this);
	}

	render() {
		return (
			<>
				<StreamBuilder
					stream={this.props.bloc.deleteItem}
					builder={(data: Group.Type | undefined) => {
						return (
							<>
								<ModalComponentV2
									isOpen={data ? true : false}
									toggle={this.props.bloc.onModalClose}
									header={'Delete'}
									body={<>Are you sure you want to delete group - {data?.name}?</>}
									buttonList={[
										<Button
											focused
											className="btn  button"
											onClick={this.props.bloc.onDeleteItemModalClose}
										>
											Cancel
										</Button>,
										<Button
											unFocused
											className="btn  button"
											onClick={() => this.props.bloc.onDeleteItemConfirmed(data!)}
										>
											Yes
										</Button>,
									]}
								/>
							</>
						);
					}}
				/>
			</>
		);
	}
}

export default GroupContact;
