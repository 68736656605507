import { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { MDBInput } from 'mdbreact';
import autoBind from 'auto-bind';
import { BehaviorSubject } from 'rxjs';
import { ObservableBuilder, AppDep, BlocProvider, StreamBuilder } from './../../provider';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Store } from './../../controller/index';
import { map } from 'rxjs/operators';
import { SearchWithDropDown } from '../main/SearchWithDropDown';
import {
	Backend,
	getZoneNameForWardId,
	WardList,
	CommunityType,
	CommunityTypeList,
	getDesignationNameForId,
} from '../../controller/backend';
import {
	DESIGNATION,
	DesignationType,
	designationTypeFromId,
	getDesignationList,
	User,
	UserRemarksData,
	Ward,
} from '../../controller/do';

import { FormBloc, FormInputField, ValueHandler } from './../../../src/controller/form_bloc';

export class UserRemarksForm extends Component<{ formBloc: FormBloc<UserRemarksData.Type> }> {
	render() {
		return (
			<>
				<BlocProvider
					create={(_: any) => this.props.formBloc}
					builder={(bloc: FormBloc<UserRemarksData.Type>) => (
						<Form>
							<Row>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<FormInputField
											controller={bloc}
											objectKey="remark_1"
											builder={(
												value: string | undefined,
												onChange: ValueHandler<string>,
												validationError: string | undefined,
												isValidationError: boolean
											) => {
												return (
													<>
														<MDBInput
															label="REMARK 1"
															type="textarea"
															rows="2"
															outline
															value={value}
															getValue={e => onChange(e.toString())}
														/>
														<Form.Text
															style={{
																color: '#DC2E20',
																visibility: isValidationError ? 'visible' : 'hidden',
															}}
														>
															{validationError}
														</Form.Text>
													</>
												);
											}}
										/>
									</Form.Group>
									<Form.Group
										className="mb-3"
										style={{ marginTop: '1.9rem' }}
										controlId="formBasicEmail"
									>
										<FormInputField
											controller={bloc}
											objectKey="remark_2"
											builder={(
												value: string | undefined,
												onChange: ValueHandler<string>,
												validationError: string | undefined,
												isValidationError: boolean
											) => {
												return (
													<>
														<>
															<MDBInput
																label="REMARK 2"
																type="textarea"
																rows="2"
																outline
																value={value}
																getValue={e => onChange(e.toString())}
															/>
															<Form.Text
																style={{
																	color: '#DC2E20',
																	visibility: isValidationError
																		? 'visible'
																		: 'hidden',
																}}
															>
																{validationError}
															</Form.Text>
														</>
													</>
												);
											}}
										/>
									</Form.Group>
									<Form.Group
										className="mb-3"
										style={{ marginTop: '1.9rem' }}
										controlId="formBasicEmail"
									>
										<FormInputField
											controller={bloc}
											objectKey="remark_3"
											builder={(
												value: string | undefined,
												onChange: ValueHandler<string>,
												validationError: string | undefined,
												isValidationError: boolean
											) => {
												return (
													<>
														<>
															<MDBInput
																label="REMARK 3"
																type="textarea"
																rows="2"
																outline
																value={value}
																getValue={e => onChange(e.toString())}
															/>
															<Form.Text
																style={{
																	color: '#DC2E20',
																	visibility: isValidationError
																		? 'visible'
																		: 'hidden',
																}}
															>
																{validationError}
															</Form.Text>
														</>
													</>
												);
											}}
										/>
									</Form.Group>
								</Col>
							</Row>
							<ObservableBuilder
								initalVal={'disabled'}
								stream={this.props.formBloc.submitEnabled.pipe(map(e => (e ? '' : 'disabled')))}
								builder={(data: string) => {
									return (
										<a
											className={`btn button  ${data}`}
											style={{ backgroundColor: '#DB2A1D', color: 'white', padding: '12px 23px' }}
											onClick={e => {
												this.props.formBloc.onSubmit();
											}}
										>
											Save
										</a>
									);
								}}
							/>
						</Form>
					)}
				/>
			</>
		);
	}
}

