import autoBind from 'auto-bind';
import { Component } from 'react';
import Log from '../common/util/log';
import { Backend } from '../controller/backend';
import { Store } from '../controller';
import { FormBloc } from '../controller/form_bloc';
import { AppDep, BlocProvider } from '../provider';
import GuestPageLayout from './../../src/layout/GuestPageLayout';
import LoginForm from './formComponent/LoginForm';
import { Credentials } from '../controller/do';
import { GlobalContext } from '../common/modal_boundary';
import { Navigation } from '../controller';

const TAG = 'login-page';

class Bloc {
	public formBloc: FormBloc<{ email: string; password: string }>;
	private _api: Backend.Api;
	private _store: Store.Bloc;
	public navigation: Navigation.Bloc;

	constructor(appDep: AppDep) {
		autoBind(this);
		this.formBloc = new FormBloc(Credentials.Constraints, this.onValidated);
		this._api = appDep.api;
		this._store = appDep.store;
		this.navigation = appDep.navigation;
	}

	@GlobalContext.handlErrorAsModal()
	async onValidated(value: { email: string; password: string }): Promise<void> {
		Log.d(TAG, 'inside onValidated', value);
		await this._api.loginUser(value).then(e => this._store.setAuthData(e));
	}
}

export default class LoginPage extends Component {
	render() {
		return (
			<>
				<BlocProvider
					create={(appDep: AppDep) => new Bloc(appDep)}
					builder={(bloc: Bloc) => {
						return (
							<>
								<GuestPageLayout>
									<div className="login-form">
										<div>
											<h1 className="ml-2" style={{ color: '#DC2E20' }}>
												Login
											</h1>
											<LoginForm formBloc={bloc.formBloc} navigation={bloc.navigation} />
										</div>
									</div>
								</GuestPageLayout>
							</>
						);
					}}
				/>
			</>
		);
	}
}
