import { MDBContainer, MDBModal } from 'mdbreact';
import React, { Component } from 'react';
import Log from '../../common/util/log';

const TAG = 'modal-component';

export const ModalComponent = (
	props: React.PropsWithChildren<{ size?: 'fluid' | 'sm' | 'md' | 'lg'; isOpen: boolean; toggle: () => void }>
) => {
	Log.d(TAG, 'inside render');
	return (
		<>
			<MDBContainer>
				<MDBModal centered {...props} toggle={props.toggle}>
					<div style={{ margin: '20px' }}>
						<a style={{ float: 'right' }} onClick={props.toggle}>
							<img style={{ width: '22px', margin: '14px' }} src="./crossx.png" alt="close" />
						</a>
						{[props.children]}
					</div>
				</MDBModal>
			</MDBContainer>
		</>
	);
};

export default ModalComponent;
