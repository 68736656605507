import { Component } from 'react';
import TableData from '../common/TableData';
import { Row, Col } from 'react-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';

import { MDBTableBody, MDBTable, MDBCol, MDBTableHead } from 'mdbreact';
import SearchWithDropDown from './SearchWithDropDown';

import autoBind from 'auto-bind';
import { AppDep, BlocProvider, StreamBuilder } from '../../../src/provider';
import { WardInfo, Analytics as AnalyticsData, UserAnalytics, DESIGNATION, Ward } from '../../../src/controller/do';
import { Reducer } from '../../../src/common/util/helper';
import { Backend, HelperDB } from '../../../src/controller/backend';
import { Tabular } from '../../../src/controller/index';
import Log from '../../../src/common/util/log';
import { GlobalContext } from '../../common/modal_boundary';
const TAG = 'analytics-page';

type DataSummary = {
	corporateTeamMemberCount: number;
	contactCount: number;
	communityCount: number;
	messageCount: number;
};

type ZoneInfo = {
	name: string;
	leader: string;
};

const userAnalyticsColumn = [
	{
		label: 'First Name',
		field: 'first_name',
		sort: 'asc',
		width: 10,
	},
	{
		label: 'Last Name',
		field: 'last_name',
		sort: 'asc',
		width: 10,
	},
	{
		label: 'Designation',
		field: 'designationName',
	},
	{
		label: 'Community Name',
		field: 'communityName',
		width: 10,
	},
	{
		label: 'Community Type',
		field: 'communityTypeName',
	},
	{
		label: 'Ward Number',
		field: 'wardId',
	},
	{
		label: 'Houses',
		field: 'housesCount',
	},
	{
		label: 'Contacts Uploaded',
		field: 'contactCount',
	},
	{
		label: 'Total templates',
		field: 'messageTemplateCount',
	},
	{
		label: 'Messages Sent',
		field: 'messageCount',
	},
];

class Bloc {
	public readonly dataChanged: BehaviorSubject<null> = new BehaviorSubject(null) as BehaviorSubject<null>;

	private analyticsData?: AnalyticsData;
	private _wardId: number = -1;

	private _subscription?: Subscription;
	private _searchQuery: string = '';

	private _api: Backend.Api;

	constructor(appDep: AppDep) {
		autoBind(this);
		this._api = appDep.api;
		this._subscription = appDep.searchBoxQuery.searchQuery.subscribe(e => {
			this._searchQuery = e;
			this.dataChanged.next(null);
		});
		this.getData();
	}

	@GlobalContext.handlErrorAsModal()
	private async getData(): Promise<void> {
		await this._api
			.getAnalytics()
			.then(e => (this.analyticsData = e))
			.then(() => this.onWardIdChange(-1));
	}

	public onDestroy() {
		Log.d(TAG, 'inside onDestroy');
		this._subscription?.unsubscribe();
	}

	get dataSummary(): DataSummary | undefined {
		return {
			corporateTeamMemberCount: this.userAnalyticsList.filter(
				e => e.designationId === DESIGNATION.AREA_SABHA_MEMBER.ID
			).length,
			contactCount: this.userAnalyticsList.map(e => e.contactCount).reduce(Reducer.sum, 0),
			communityCount: new Set<String>(this.userAnalyticsList.map(e => e.communityName).filter(e => e !== ''))
				.size,
			messageCount: this.userAnalyticsList.map(e => e.messageCount).reduce(Reducer.sum, 0),
		};
	}

	get zoneInfoList(): ZoneInfo[] {
		if (!this.analyticsData) return [];
		return this.analyticsData.wardInfoList
			.filter(e => e.zoneLeaderName)
			.map(e => {
				return {
					name: HelperDB.getZoneNameForWardId(e.wardId) as string,
					leader: e.zoneLeaderName as string,
				};
			});
	}

	get zoneInfo(): ZoneInfo | undefined {
		if (this._wardId === -1) return undefined;
		return this.zoneInfoList.find(e => e.name === HelperDB.getZoneNameForWardId(this._wardId));
	}

	get wardInfo(): WardInfo | undefined {
		if (this._wardId === -1) return undefined;
		return this.analyticsData?.wardInfoList.find(e => e.wardId === this._wardId);
	}

	get ward(): Ward | undefined {
		if (this.wardList.length === 1) return this.wardList[0];

		if (this._wardId === -1) return { id: -1, displayName: 'All', wardName: '' };

		return HelperDB.getWardForWardId(this._wardId);
	}

	get wardList(): Ward[] {
		if (!this.analyticsData) return [];

		let wardList = this.analyticsData.userAnalyticsList
			.map(e => e.wardId)
			.map(e => HelperDB.getWardForWardId(e))
			.filter(e => e !== undefined) as Ward[];
		wardList = Array.from(new Set(wardList));
		Log.d(TAG, 'wardList', wardList);
		if (wardList.length > 1) wardList = [{ id: -1, displayName: 'All', wardName: '' }, ...wardList];
		wardList.sort((a, b) => a.id - b.id);
		return wardList;
	}

	get userAnalyticsList(): UserAnalytics[] {
		return this.analyticsData
			? this.analyticsData.userAnalyticsList
					.map(e => {
						return {
							...e,
							designationName: HelperDB.getDesignationNameForId(e.designationId),
							communityTypeName: HelperDB.getCommunityTypeNameForId(e.communityTypeId),
						} as UserAnalytics & { designationName: string; communityTypeName: string };
					})
					.filter(e =>
						this.filterForSearchQuery(e, this._searchQuery, [
							'first_name',
							'last_name',
							'designationName',
							'communityTypeName',
							'communityName',
						])
					)
					.filter(e => this.filterForWardId(e, this._wardId))
			: [];
	}

	private filterForSearchQuery(
		userAnalytics: UserAnalytics & { designationName: string; communityTypeName: string },
		searchQuery: string,
		searchableFieldList: (keyof (UserAnalytics & { designationName: string; communityTypeName: string }))[]
	): boolean {
		if (searchQuery === '') {
			return true;
		}

		let match: boolean = false;
		searchableFieldList.forEach(f => {
			if (userAnalytics[f])
				match = match || (userAnalytics[f] as string).toLowerCase().includes(searchQuery.toLowerCase());
		});
		return match;
	}

	private filterForWardId(userAnalytics: UserAnalytics, wardId: number): boolean {
		if (wardId === -1) {
			return true;
		} else if (userAnalytics.wardId === wardId) {
			return true;
		}
		return false;
	}

	public onWardIdChange(wardId: number | undefined) {
		this._wardId = wardId ?? -1;
		this.dataChanged.next(null);
	}
}

export class Analytics extends Component {
	render() {
		return (
			<div>
				<BlocProvider
					create={(appDep: AppDep) => new Bloc(appDep)}
					builder={(bloc: Bloc) => {
						return (
							<>
								<div className="ml-2 mr-2">
									<Row>
										<Col md="6">
											<div className="mb-4">
												<StreamBuilder
													stream={bloc.dataChanged}
													builder={() => {
														return (
															<>
																<div className="mt-3" style={{ width: '100%' }}>
																	<SearchWithDropDown
																		field="displayName"
																		value={bloc.ward}
																		label="SELECT WARD"
																		getValue={e => bloc.onWardIdChange(e?.id)}
																		optionList={bloc.wardList}
																	/>
																</div>
															</>
														);
													}}
												/>
											</div>
										</Col>
									</Row>

									<Row>
										<Col md={6} className="mb-4">
											<MDBTable striped>
												<StreamBuilder
													stream={bloc.dataChanged}
													builder={() => {
														return (
															<>
																<MDBTableBody>
																	<tr>
																		<td>Zone Leader</td>
																		<td>{bloc.zoneInfo?.leader}</td>
																	</tr>
																	<tr>
																		<td>Number of Corporate team Member</td>
																		<td>
																			{bloc.dataSummary?.corporateTeamMemberCount}
																		</td>
																	</tr>
																	<tr>
																		<td>Number of Contact Upload</td>
																		<td>{bloc.dataSummary?.contactCount}</td>
																	</tr>
																</MDBTableBody>
															</>
														);
													}}
												/>
											</MDBTable>
										</Col>
										<Col md={6} className="mb-4">
											<MDBTable striped>
												<StreamBuilder
													stream={bloc.dataChanged}
													builder={() => {
														return (
															<>
																<MDBTableBody>
																	<tr>
																		<td>Ward Leader</td>
																		<td>{bloc.wardInfo?.wardLeaderName}</td>
																	</tr>
																	<tr>
																		<td>Number of Communities Covered</td>
																		<td>{bloc.dataSummary?.communityCount}</td>
																	</tr>
																	<tr>
																		<td>Number of Message Sent</td>
																		<td>{bloc.dataSummary?.messageCount}</td>
																	</tr>
																</MDBTableBody>
															</>
														);
													}}
												/>
											</MDBTable>
										</Col>
									</Row>
									<StreamBuilder
										stream={bloc.dataChanged}
										builder={() => {
											return (
												<>
													<div>
														<TableData
															exportFileName="analytics.csv"
															exportFields={[
																'first_name',
																'last_name',
																'designationName',
																'communityTypeName',
																'communityName',
																'housesCount',
																'contactCount',
																'messageTemplateCount',
																'messageCount',
															]}
															searchableFields={[
																'first_name',
																'last_name',
																'designationName',
																'communityTypeName',
																'communityName',
															]}
															bordered={true}
															striped={true}
															data={{
																columns: userAnalyticsColumn,
																rows: bloc.userAnalyticsList,
															}}
														/>
													</div>
												</>
											);
										}}
									/>
								</div>
							</>
						);
					}}
				/>
			</div>
		);
	}
}

export default Analytics;
