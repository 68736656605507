import { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { MDBInput } from 'mdbreact';
import autoBind from 'auto-bind';
import { BehaviorSubject } from 'rxjs';
import { ObservableBuilder, AppDep, BlocProvider, StreamBuilder } from './../../provider';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Store } from './../../controller/index';
import { map } from 'rxjs/operators';
import { SearchWithDropDown } from '../main/SearchWithDropDown';
import {
	Backend,
	getZoneNameForWardId,
	WardList,
	CommunityType,
	CommunityTypeList,
	getDesignationNameForId,
} from '../../controller/backend';
import {
	DESIGNATION,
	DesignationType,
	designationTypeFromId,
	getDesignationList,
	User,
	Ward,
} from '../../controller/do';

import { FormBloc, FormInputField, ValueHandler } from './../../../src/controller/form_bloc';

import Log from '../../common/util/log';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const TAG = 'ProfileForm';

const theme = createTheme({
	palette: {
		primary: {
			main: '#dc2e20',
		},
	},
});

type BlocData = {
	designationName: string;
	zone?: string;
	wardName?: string;
	canSubmit: boolean;
};

class Bloc {
	public formBloc: FormBloc<User.Type>;

	constructor(formBloc: FormBloc<User.Type>) {
		autoBind(this);
		this.formBloc = formBloc;
	}

	get user(): BehaviorSubject<User.Type | undefined> {
		return this.formBloc.formValue;
	}

	get designationList(): DesignationType[] {
		return getDesignationList();
	}

	get designation(): DesignationType | undefined {
		return designationTypeFromId(this.user.value?.designation_id);
	}

	get communityTypeList(): CommunityType[] {
		return CommunityTypeList;
	}

	get wardList(): Ward[] {
		return WardList;
	}

	get communtityType(): CommunityType | undefined {
		return CommunityTypeList.find(e => e.id === this.user.value?.community_type_id);
	}

	get ward(): Ward | undefined {
		return WardList.find(e => e.id === this.user.value?.ward_id);
	}

	get zoneName(): string | null {
		if (this.user.value?.ward_id) return getZoneNameForWardId(this.user.value.ward_id as number);
		return '';
	}
}

export class ProfileForm extends Component<{ formBloc: FormBloc<User.Type>; isAdmin?: boolean }> {
	render() {
		return (
			<>
				<BlocProvider
					create={(appDep: AppDep) => new Bloc(this.props.formBloc)}
					builder={(bloc: Bloc) => (
						<Form>
							<Row>
								<Col md={6}>
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<FormInputField
											controller={bloc.formBloc}
											objectKey="first_name"
											builder={(
												value: string | undefined,
												onChange: ValueHandler<string>,
												validationError: string | undefined,
												isValidationError: boolean
											) => {
												return (
													<>
														<MDBInput
															label="FIRST NAME"
															type="text"
															outline
															value={value}
															getValue={e => onChange(e.toString())}
														/>
														<Form.Text
															style={{
																color: '#DC2E20',
																visibility: isValidationError ? 'visible' : 'hidden',
															}}
														>
															{validationError}
														</Form.Text>
													</>
												);
											}}
										/>
									</Form.Group>
									<Form.Group
										className="mb-3"
										style={{ marginTop: '1.9rem' }}
										controlId="formBasicEmail"
									>
										<FormInputField
											controller={bloc.formBloc}
											objectKey="email"
											builder={(
												value: string | undefined,
												onChange: ValueHandler<string>,
												validationError: string | undefined,
												isValidationError: boolean
											) => {
												return (
													<>
														<MDBInput
															disabled={!this.props.isAdmin}
															label="EMAIL"
															value={value}
															getValue={e => onChange(e.toString())}
															type="email"
															outline
														/>
														<Form.Text
															style={{
																color: '#DC2E20',
																visibility: isValidationError ? 'visible' : 'hidden',
															}}
														>
															{validationError}
														</Form.Text>
													</>
												);
											}}
										/>
									</Form.Group>
									<Form.Group
										className="mb-3"
										style={{ marginTop: '1.9rem' }}
										controlId="formBasicEmail"
									>
										<FormInputField
											controller={bloc.formBloc}
											objectKey="ward_id"
											builder={(_: number | undefined, onChange: ValueHandler<number>) => {
												return (
													<>
														<SearchWithDropDown
															label="WARD NUMBER"
															getValue={e => onChange(e?.id)}
															field="displayName"
															value={bloc.ward ?? ({ id: -1, name: '' } as any)}
															optionList={bloc.wardList}
														/>
													</>
												);
											}}
										/>
									</Form.Group>
									<Form.Group
										className="mb-3"
										controlId="formBasicEmail"
										style={{ marginTop: '1.9rem' }}
									>
										<FormInputField
											controller={bloc.formBloc}
											objectKey="community_name"
											builder={(
												value: string | undefined,
												onChange: ValueHandler<string>,
												validationError: string | undefined
											) => {
												return (
													<>
														<MDBInput
															label="COMMUNITY NAME"
															type="text"
															outline
															value={value}
															getValue={e => onChange(e.toString())}
														/>
														<Form.Text style={{ color: '#DC2E20', visibility: 'hidden' }}>
															{validationError}
														</Form.Text>
													</>
												);
											}}
										/>
									</Form.Group>

									<Form.Group
										className="mb-3"
										style={{ marginTop: '1.9rem' }}
										controlId="formBasicPassword"
									>
										<FormInputField
											controller={bloc.formBloc}
											objectKey="community_type_id"
											builder={(_: number | undefined, onChange: ValueHandler<number>) => {
												return (
													<>
														<SearchWithDropDown
															label="COMMUNITY TYPE"
															getValue={e => onChange(e?.id)}
															field="name"
															value={bloc.communtityType ?? { id: -1, name: '' }}
															optionList={bloc.communityTypeList}
														/>
													</>
												);
											}}
										/>
									</Form.Group>

									<Form.Group
										className="mb-3"
										style={{ marginTop: '1.9rem' }}
										controlId="formBasicEmail"
									>
										<FormInputField
											controller={bloc.formBloc}
											objectKey="bnp_whatsapp_group_link"
											builder={(
												value: string | undefined,
												onChange: ValueHandler<string>,
												validationError: string | undefined
											) => {
												return (
													<>
														<MDBInput
															label="BNP WHATSAPP GROUP LINK"
															type="text"
															outline
															value={value}
															getValue={e => onChange(e.toString())}
														/>
														<Form.Text style={{ color: '#DC2E20' }}>
															{validationError}
														</Form.Text>
													</>
												);
											}}
										/>
									</Form.Group>
								</Col>
								<Col md={6}>
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<FormInputField
											controller={bloc.formBloc}
											objectKey="last_name"
											builder={(
												value: string | undefined,
												onChange: ValueHandler<string>,
												validationError: string | undefined,
												isValidationError: boolean
											) => {
												return (
													<>
														<MDBInput
															label="LAST NAME"
															type="text"
															outline
															value={value}
															getValue={e => onChange(e.toString())}
														/>
														<Form.Text
															style={{
																color: '#DC2E20',
																visibility: isValidationError ? 'visible' : 'hidden',
															}}
														>
															{validationError}
														</Form.Text>
													</>
												);
											}}
										/>
									</Form.Group>
									<Form.Group
										className="mb-3"
										controlId="formBasicEmail"
										style={{ marginTop: '1.9rem' }}
									>
										<FormInputField
											controller={bloc.formBloc}
											objectKey="designation_id"
											builder={(
												_: number | undefined,
												onChange: ValueHandler<number>,
												validationError: string | undefined
											) => {
												return (
													<>
														<SearchWithDropDown
															disabled={!this.props.isAdmin}
															label="DESIGNATION"
															getValue={e => onChange(e?.ID)}
															field="NAME"
															value={bloc.designation ?? { ID: -1, NAME: '' }}
															optionList={bloc.designationList as DesignationType[]}
														/>
														<Form.Text style={{ color: '#DC2E20' }}>
															{validationError}
														</Form.Text>
													</>
												);
											}}
										/>
									</Form.Group>
									<Form.Group
										className="mb-3"
										controlId="formBasicEmail"
										style={{ marginTop: '1.9rem' }}
									>
										<FormInputField
											controller={bloc.formBloc}
											objectKey="mobile_number"
											builder={(
												value: string | undefined,
												onChange: ValueHandler<string>,
												validationError: string | undefined
											) => {
												return (
													<>
														<MDBInput
															label="MOBILE"
															type="number"
															outline
															value={value}
															getValue={e => onChange(e.toString())}
														/>
														<Form.Text style={{ color: '#DC2E20' }}>
															{validationError}
														</Form.Text>
													</>
												);
											}}
										/>
									</Form.Group>
									<Form.Group
										className="mb-3"
										controlId="formBasicEmail"
										style={{ marginTop: '1.9rem' }}
									>
										<FormInputField
											controller={bloc.formBloc}
											objectKey="ward_id"
											builder={() => {
												return (
													<>
														<MDBInput
															value={bloc.zoneName ?? undefined}
															disabled
															label="Zone Name"
															type="text"
															outline
														/>
													</>
												);
											}}
										/>
									</Form.Group>
									<Form.Group className="mb-3" style={{ marginTop: '1.9rem' }} controlId="house">
										<FormInputField
											controller={bloc.formBloc}
											objectKey="houses_count"
											builder={(
												value: number | undefined,
												onChange: ValueHandler<number>,
												validationError: string | undefined
											) => {
												return (
													<>
														<MDBInput
															label="NUMBER OF HOUSES IN COMMUNITY"
															type="number"
															pattern="[0-9]*"
															outline
															value={value}
															getValue={e => onChange(e as number)}
														/>
														<Form.Text style={{ color: '#DC2E20' }}>
															{validationError}
														</Form.Text>
													</>
												);
											}}
										/>
									</Form.Group>

									{this.props.isAdmin && (
										<ThemeProvider theme={theme}>
											<Form.Group
												className="mb-3"
												style={{ marginTop: '1.9rem' }}
												controlId="house"
											>
												<FormInputField
													controller={this.props.formBloc}
													objectKey="is_profile_active"
													builder={(
														value: boolean | undefined,
														onChange: ValueHandler<boolean>
													) => {
														return (
															<FormControlLabel
																control={
																	<Switch
																		checked={value ?? false}
																		onChange={e => onChange(e.target.checked)}
																		color="primary"
																		name="checkedA"
																	/>
																}
																label={`${value ? 'Active' : 'Inactive'}`}
															/>
														);
													}}
												/>
											</Form.Group>
										</ThemeProvider>
									)}
								</Col>
							</Row>
							<StreamBuilder
								stream={bloc.formBloc.submissionError}
								builder={(data: string | undefined) => {
									return (
										<>
											<Form.Text style={{ color: '#DC2E20' }}>{data}</Form.Text>
										</>
									);
								}}
							/>
							<ObservableBuilder
								initalVal={'disabled'}
								stream={bloc.formBloc.submitEnabled.pipe(map(e => (e ? '' : 'disabled')))}
								builder={(data: string) => {
									return (
										<a
											className={`btn button  ${data}`}
											style={{ backgroundColor: '#DB2A1D', color: 'white', padding: '12px 23px' }}
											onClick={e => {
												bloc.formBloc.onSubmit();
											}}
										>
											Save
										</a>
									);
								}}
							/>
						</Form>
					)}
				/>
			</>
		);
	}
}

export default ProfileForm;
