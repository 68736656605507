import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './provider';
import firebase from 'firebase';

const firebaseConfig = {
	apiKey: 'AIzaSyCsH5he-o-XkUNPO8WeGp1q0iOLvBu2ZYU',
	authDomain: 'bnp-communication-platform.firebaseapp.com',
	databaseURL: 'https://bnp-communication-platform-default-rtdb.asia-southeast1.firebasedatabase.app',
	projectId: 'bnp-communication-platform',
	storageBucket: 'bnp-communication-platform.appspot.com',
	messagingSenderId: '448264143437',
	appId: '1:448264143437:web:52ab8620c9de55462fd204',
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
