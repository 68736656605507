import { Component } from 'react';
import Catch from 'catch-decorator';
import autoBind from 'auto-bind';
import { Subject } from 'rxjs';
import Log from '../../src/common/util/log';
import { ObservableBuilder } from '../../src/provider';
import { MDBContainer, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { Decorator } from '../../src/common/util/helper';
import ModalComponent from '../components/common/ModalComponent';
import { ReadableError } from '../controller/do';
import ModalComponentV2 from '../components/common/ModalComponentV2';
import { Button } from '../../src/components/common/button';

const TAG = 'modal_boundary';

class ErrorModalBloc {
	public readonly errorText: Subject<string | undefined> = new Subject() as Subject<string | undefined>;

	constructor() {
		autoBind(this);
	}

	handleError<T extends Error, K>(error: T, finallyReturn: K): K {
		Log.d(TAG, 'Received error', error);
		this.errorText.next(new ReadableError(error).readableMessage);
		return finallyReturn;
	}

	onModalClose() {
		this.errorText.next(undefined);
	}
}

export class ErrorModalBoundary extends Component<{ bloc: ErrorModalBloc }> {
	render() {
		return (
			<ObservableBuilder
				initalVal={undefined}
				stream={this.props.bloc.errorText}
				builder={(data: string | undefined) => {
					return (
						<>
							<MDBContainer>
								<ModalComponentV2
									isOpen={data ? true : false}
									toggle={this.props.bloc.onModalClose}
									header={'Error'}
									body={<>{data}</>}
									buttonList={[
										<Button className="btn button" focused onClick={this.props.bloc.onModalClose}>
											Close
										</Button>,
									]}
								/>
							</MDBContainer>
						</>
					);
				}}
			/>
		);
	}
}

class SuccessModalBloc {
	public readonly successText: Subject<string | undefined> = new Subject() as Subject<string | undefined>;

	constructor() {
		autoBind(this);
	}

	handleSuccess(successText: string) {
		Log.d(TAG, 'Received successText', successText);
		this.successText.next(successText);
	}

	onModalClose() {
		this.successText.next(undefined);
	}
}

export class SuccessModalBoundary extends Component<{ bloc: SuccessModalBloc }> {
	render() {
		return (
			<ObservableBuilder
				initalVal={undefined}
				stream={this.props.bloc.successText}
				builder={(data: string | undefined) => {
					return (
						<>
							<MDBContainer>
								<ModalComponentV2
									isOpen={data ? true : false}
									toggle={this.props.bloc.onModalClose}
									header={'Success'}
									body={<>{data}</>}
									buttonList={[
										<Button
											focusedLarge
											className="btn button"
											onClick={this.props.bloc.onModalClose}
										>
											Ok
										</Button>,
									]}
								/>
							</MDBContainer>
						</>
					);
				}}
			/>
		);
	}
}

const errorBloc = new ErrorModalBloc();
const successBloc = new SuccessModalBloc();
const handlErrorAsModal = (finallyReturn?: any) => Decorator.CatchError(e => errorBloc.handleError(e, finallyReturn));
const handleSuccessAsModal = (returnType: any, msg: string) =>
	Decorator.AfterMethodCompletes(returnType, (_: any) => successBloc.handleSuccess(msg));
export const GlobalContext = {
	handlErrorAsModal,
	handleSuccessAsModal,
	errorModalBoundary: <ErrorModalBoundary bloc={errorBloc} />,
	successModalBoundary: <SuccessModalBoundary bloc={successBloc} />,
};
