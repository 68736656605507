import { Component } from 'react';
import autoBind from 'auto-bind';
import { MDBTooltip } from 'mdbreact';

export class Button extends Component<
	{
		className?: string;
		outlined?: boolean;
		onClick?: () => void;
		focused?: boolean;
		unFocused?: boolean;
		focusedLarge?: boolean;
	},
	{}
> {
	constructor(props: any) {
		super(props);
		autoBind(this);
	}

	render() {
		let className = this.props.className ?? '';
		className = className + (this.props.outlined ? 'btn button' : '');
		return (
			<>
				{this.props.outlined && (
					<a
						onClick={this.props.onClick}
						style={{
							color: '#DB2A1D',
							border: '1px solid #D4D4D4',
							boxShadow: 'none',
							fontSize: '16px',
						}}
						className={className}
					>
						{this.props.children}
					</a>
				)}
				{this.props.focused && (
					<a
						style={{
							backgroundColor: '#DB2A1D',
							color: 'white',
							padding: '9px',
						}}
						className={className}
						onClick={this.props.onClick}
					>
						{this.props.children}
					</a>
				)}
				{this.props.unFocused && (
					<a
						style={{
							// backgroundColor: "#DB2A1D",
							color: '#000000',
							padding: '9px 34px',
						}}
						className={className}
						onClick={this.props.onClick}
					>
						{this.props.children}
					</a>
				)}
				{this.props.focusedLarge && (
					<a
						style={{
							backgroundColor: '#DB2A1D',
							color: 'white',
							padding: '9px 34px',
						}}
						className={className}
						onClick={this.props.onClick}
					>
						{this.props.children}
					</a>
				)}
			</>
		);
	}
}

export function IconButton(props: {
	src: string;
	onClick: () => void;
	className?: string;
	tooltip?: string;
}): JSX.Element {
	return (
		<a onClick={props.onClick}>
			<MDBTooltip domElement tag="span" material placement="top">
				<img className={props.className} src={props.src} />
				<span>{props.tooltip} </span>
			</MDBTooltip>
		</a>
	);
}

export function TextButton(props: { text: string; onClick: () => void; className?: string }): JSX.Element {
	return (
		<a className={props.className} onClick={props.onClick} style={{ color: '#DC2E20', fontWeight: 'normal' }}>
			{props.text}
		</a>
	);
}
