export default class Log {
	private static createString(tag: string, argList: any[]): string {
		let stringList: String[] = argList.map(e => {
			if (typeof e === 'function') return e;
			return JSON.stringify(e);
		});
		let msg: String = stringList.reduce((p, c) => p + ' | ' + c, '');
		msg = tag + ' >> ' + msg;
		return msg.toString();
	}

	static d(tag: string, ...args: any[]): void {
		if (process.env.NODE_ENV === 'development') console.log(`DEBUG: ${Log.createString(tag, args)}`);
	}

	static e(tag: string, ...args: any[]): void {
		console.error(`ERROR: ${Log.createString(tag, args)}`);
	}

	// print-&-return
	static pr<T>(tag: string, msg: T): T {
		Log.d(tag, msg);
		return msg;
	}
}
