import Log from './log';

const TAG = 'helper.ts';

export namespace Reducer {
	export function sum(p: number, c: number): number {
		return p ? +p + +c : c;
	}
}

export namespace List {
	export function generate<T>(count: number, builder: (index: number) => T): T[] {
		let ret = [] as T[];
		for (let i = 0; i < count; i++) {
			let item = builder(i);
			ret.push(item);
		}
		return ret;
	}
}

export namespace Helper {
	export const flattenObject = function (ob: any) {
		var toReturn: any = {};

		for (var i in ob) {
			if (!ob.hasOwnProperty(i)) continue;

			if (ob[i] && !Array.isArray(ob[i]) && typeof ob[i] == 'object') {
				var flatObject = flattenObject(ob[i]);
				for (var x in flatObject) {
					if (!flatObject.hasOwnProperty(x)) continue;

					toReturn[i + '.' + x] = flatObject[x];
				}
			} else {
				toReturn[i] = ob[i];
			}
		}
		return toReturn;
	};
}

export namespace Decorator {
	type HandlerFunction<T> = (T: any) => T;
	type ErrorHandlerFunction<T> = (error: any, ctx: any) => T;

	function handleReturn<T>(handler: HandlerFunction<T>, returnObj: T) {
		handler.call(null, returnObj);
	}

	// decorator factory function
	export function AfterMethodCompletes<T>(methodReturnType: T, handler: HandlerFunction<T>): any {
		return (_: any, __: string, descriptor: PropertyDescriptor) => {
			// save a reference to the original method
			const originalMethod = descriptor.value;

			// rewrite original method with custom wrapper
			descriptor.value = function (...args: any[]) {
				const result = originalMethod.apply(this, args);

				// check if method is asynchronous
				if (result && typeof result.then === 'function' && typeof result.catch === 'function') {
					// return promise
					return result.then((e: T) => {
						handleReturn<T>(handler, e);
					});
				}

				// return actual result
				return handleReturn(handler, result);
			};

			return descriptor;
		};
	}

	function handleError<T>(ctx: any, handler: ErrorHandlerFunction<T>, error: any): T {
		// check if error is instance of passed error class
		if (typeof handler === 'function') {
			// run handler with error object
			// and class context as second argument
			return handler.call(null, error, ctx);
		} else {
			// throw error further,
			// next decorator in chain can catch it
			throw error;
		}
	}
	export function CatchError<K>(handler: ErrorHandlerFunction<K>): any {
		return (_: any, __: string, descriptor: PropertyDescriptor) => {
			// save a reference to the original method
			const originalMethod = descriptor.value;

			// rewrite original method with custom wrapper
			descriptor.value = function (...args: any[]) {
				let ret: K;
				try {
					const result = originalMethod.apply(this, args);

					// check if method is asynchronous
					if (result && typeof result.then === 'function' && typeof result.catch === 'function') {
						// return promise
						return result.catch((error: any) => {
							return handleError(this, handler, error);
						});
					}

					// return actual result
					return result;
				} catch (error) {
					ret = handleError(this, handler, error);
				}
				return ret;
			};

			return descriptor;
		};
	}
}

export namespace Util {
	export function makeCsvAvailable(fields: string[], fileName: string, dataList: any[]) {
		let csvFile = '';
		csvFile += fields.join(',');
		csvFile += '\n';

		dataList.forEach(data => {
			const row = fields.map(e => {
				let element = deepFind(data, e);
				if (element && element instanceof Array) element = element.join();
				return '"' + (element ?? '') + '"';
			});
			csvFile += row.join(',');
			csvFile += '\n';
		});
		const anchor = document.createElement('a');
		anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvFile);
		anchor.target = '_blank';
		anchor.download = fileName;
		anchor.click();
	}

	export function deepFind(obj: { [key: string]: any }, path: string): any {
		const parts = path.split('.');
		if (parts.length == 1) {
			return obj?.[parts[0]];
		}
		return deepFind(obj[parts[0]], parts.slice(1).join('.'));
	}
}
