import React, { Component } from 'react';

import { Form, Row, Col, Button } from 'react-bootstrap';
import { MDBInput } from 'mdbreact';

import autoBind from 'auto-bind';
import { BehaviorSubject } from 'rxjs';

import { AppDep, BlocProvider, MapStreamBuilder, StreamBuilder } from '../../provider';
import { FormBloc, FormInputField, ValueHandler } from '../../controller/form_bloc';

export class SecurityForm extends Component<{
	formBloc: FormBloc<{ oldPassword: string; newPassword: string; confirmationPassword: string }>;
}> {
	render() {
		return (
			<>
				<Form>
					<Form.Group className="mb-3" controlId="formBasicEmail">
						<FormInputField
							controller={this.props.formBloc}
							objectKey="oldPassword"
							builder={(
								value: string | undefined,
								onChange: ValueHandler<string>,
								validationError: string | undefined
							) => {
								return (
									<>
										<MDBInput
											label="OLD PASSWORD"
											value={value}
											getValue={e => onChange(e.toString())}
											type="password"
											outline
										/>
										<Form.Text style={{ color: '#DC2E20' }}>{validationError}</Form.Text>
									</>
								);
							}}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="formBasicPassword">
						<FormInputField
							controller={this.props.formBloc}
							objectKey="newPassword"
							builder={(
								value: string | undefined,
								onChange: ValueHandler<string>,
								validationError: string | undefined
							) => {
								return (
									<>
										<MDBInput
											label="SET NEW PASSWORD"
											value={value}
											getValue={e => onChange(e.toString())}
											type="password"
											outline
										/>
										<Form.Text style={{ color: '#DC2E20' }}>{validationError}</Form.Text>
									</>
								);
							}}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="formBasicPassword">
						<FormInputField
							controller={this.props.formBloc}
							objectKey="confirmationPassword"
							builder={(
								value: string | undefined,
								onChange: ValueHandler<string>,
								validationError: string | undefined
							) => {
								return (
									<>
										<MDBInput
											label="CONFIRM PASSWORD"
											value={value}
											getValue={e => onChange(e.toString())}
											type="password"
											outline
										/>
										<Form.Text style={{ color: '#DC2E20' }}>{validationError}</Form.Text>
									</>
								);
							}}
						/>
					</Form.Group>

					<MapStreamBuilder
						stream={this.props.formBloc.submitEnabled}
						map={(e: boolean) => (e ? '' : 'disabled')}
						builder={(cls: string) => {
							return (
								<>
									<a
										onClick={this.props.formBloc.onSubmit}
										className={`btn button ${cls}`}
										style={{ backgroundColor: '#DB2A1D', color: 'white', padding: '12px 23px' }}
									>
										Update Password
									</a>
								</>
							);
						}}
					/>
				</Form>
			</>
		);
	}
}

export default SecurityForm;
