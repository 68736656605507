import Log from './../common/util/log';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Backend } from './backend';
import validate from 'validate.js';
import autoBind from 'auto-bind';
import { CentralMessage, User } from './../controller/do';

export const centralMessageSorter =
	(pinnedMessageId: number | null | undefined) => (a: CentralMessage.Type, b: CentralMessage.Type) => {
		Log.d('controller/index', 'inside centralMessageSorter', a, b);
		if (a.id === pinnedMessageId) return -1;
		else if (b.id === pinnedMessageId) return 1;
		return b.id! - a.id!;
	};

export namespace Util {
	const TAG = 'Util';
}

const TAG = 'index';

export class SelectItem<T> {
	private _item: BehaviorSubject<T | null> = new BehaviorSubject(null) as BehaviorSubject<T | null>;

	constructor() {
		autoBind(this);
	}

	get item(): BehaviorSubject<T | null> {
		return this._item;
	}

	setItem(item: T) {
		this._item.next(item);
	}
}

export namespace Tabular {
	const TAG = 'Tabular';
}

export class SearchBoxQuery {
	private _searchQuery: BehaviorSubject<string> = new BehaviorSubject('');
	get searchQuery(): BehaviorSubject<string> {
		return this._searchQuery;
	}

	constructor() {
		autoBind(this);
	}

	onSearchQueryChanged(query: string) {
		Log.d(TAG, 'inside onSearchQueryChanged: ' + query);
		this._searchQuery.next(query);
	}
}

export namespace Navigation {
	const TAG = 'Navigation';

	export const Path = {
		ForgotPassowrd: '/forgot-password',
		Root: '/',
		SendMessage: '/home',
		Accounts: '/account',
		AboutUs: '/about_us',
		SentMessages: '/sent_messages',
		Contacts: '/contacts',
		ManageMessages: '/manage_messages',
		ManageUser: '/manage_user',
		Analytics: '/analytics',
		AcceptInvitation: '/invitation-page',
		GenerateNewPassword: '/password-reset',
	};

	export const PathList = [
		{
			name: 'Send Messages',
			path: Path.SendMessage,
		},
		{
			name: 'Sent Messages',
			path: Path.SentMessages,
		},
		{
			name: 'My Contacts',
			path: Path.Contacts,
		},
		{
			name: 'Analytics',
			path: Path.Analytics,
		},
	];

	export const AdminPathList = [
		...PathList,
		{
			name: 'Manage Messages',
			path: Path.ManageMessages,
		},
		{
			name: 'Manage Users',
			path: Path.ManageUser,
		},
	];

	export const FreePathList = [Path.ForgotPassowrd, Path.AcceptInvitation, Path.GenerateNewPassword, Path.Root];

	export class Bloc {
		private _currentPath: BehaviorSubject<string> = new BehaviorSubject('/');
		private subscription: Subscription;
		private _store: Store.Bloc;
		private history: any;

		static create(store: Store.Bloc, history: any): Bloc {
			Log.d(TAG, 'inside create');
			let bloc = new Bloc(store, history);
			return bloc;
		}

		destroy(): void {
			this.subscription.unsubscribe();
		}

		private constructor(store: Store.Bloc, history: any) {
			Log.d(TAG, `inside constructor: ${store} | ${history}`);
			autoBind(this);
			this._store = store;
			this.subscription = store.authData.subscribe(this.onAuthDataChanged.bind(this));
			this.history = history;
			this.handleInitialPath();
		}

		private handleInitialPath() {
			let currentPath = this.history.location.pathname;

			if (!FreePathList.find(e => e === currentPath)) {
				this.history.push(Path.Root);
				this.currentPath.next(Path.Root);
			} else {
				this.currentPath.next(currentPath);
			}
		}

		get currentPath(): BehaviorSubject<string> {
			return this._currentPath;
		}

		get urlSearchQuery(): string | undefined {
			return this.history.location.search;
		}

		private onAuthDataChanged(data: Store.AuthData | null) {
			Log.d(TAG, 'inside onAuthDataChanged');
			let currentPath = this.history?.location.pathname;
			let isFreePath: boolean = !!FreePathList.find(e => e === currentPath);
			if (!!data && isFreePath) {
				this.currentPath.next(Path.SendMessage);
				this.history.push(Path.SendMessage);
			} else if (!data && !isFreePath) {
				this.currentPath.next(Path.Root);
				this.history?.push(Path.Root);
			}
		}

		onPathButtonClicked(path: string) {
			Log.d(TAG, 'inside onPathButtonClicked');
			this.history.push(path);
			this._currentPath.next(this.history.location.pathname);
		}

		onForgotPasswordClicked() {
			Log.d(TAG, 'inside onForgotPasswordClicked');
			this.history.push(Path.ForgotPassowrd);
		}

		onBackToLogin() {
			Log.d(TAG, 'inside onBackToLogin');
			this.history.push(Path.Root);
		}

		onLogoutClicked() {
			Log.d(TAG, 'inside onLogoutClicked');
			this._store.setAuthData(null);
		}

		onAccountsClicked() {
			Log.d(TAG, 'inside onAccountsClicked');
			this.currentPath.next(Path.Accounts);
			this.history.push(Path.Accounts);
		}

		onBackClicked() {
			Log.d(TAG, 'inside onBackClicked');
			this.history.goBack();
			this._currentPath.next(this.history.location.pathname);
		}
	}
}

export namespace Store {
	const TAG = 'Store';

	export interface AuthData {
		token: string;
		user: User.Type;
	}

	export class Bloc {
		private _authData: BehaviorSubject<AuthData | null> = new BehaviorSubject(
			null
		) as BehaviorSubject<AuthData | null>;

		constructor() {
			autoBind(this);
		}

		get authData(): BehaviorSubject<AuthData | null> {
			return this._authData;
		}

		setAuthData(data: AuthData | null): void {
			Log.d(TAG, 'inside setAuthData: ' + data);
			this._authData.next(data);
		}

		setUser(data: User.Type): void {
			Log.d(TAG, 'inside setUser: ' + data);
			this._authData.next({
				...(this._authData.value as AuthData),
				user: data,
			});
		}
	}
}
