import { Component } from 'react';
import TabLayout from '../../layout/TabLayout';
import TableData from '../common/TableData';
import { StreamBuilder, AppDep, BlocProvider } from './../../provider';
import { Tabular } from '../../controller/index';

import Log from './../../common/util/log';
import AllContact, { ContactBloc } from './AllContact';
import GroupContact, { GroupBloc } from './GroupContact';
import autoBind from 'auto-bind';
import { Subscription } from 'rxjs';

const TAG = 'Contacts';

class Bloc {
	public contactBloc: ContactBloc;
	public groupBloc: GroupBloc;

	constructor(appDep: AppDep) {
		autoBind(this);
		this.contactBloc = new ContactBloc(appDep);
		this.groupBloc = new GroupBloc(appDep);
	}

	destroy() {
		this.groupBloc.destroy();
	}
}

export class Contacts extends Component<any, {}> {
	render() {
		return (
			<>
				<div className="m-2">
					<BlocProvider
						create={(appDep: AppDep) => new Bloc(appDep)}
						destroy={(bloc: Bloc) => bloc.destroy()}
						builder={(bloc: Bloc) => (
							<TabLayout
								children={[
									{
										name: 'Contacts',
										child: (
											<StreamBuilder
												stream={bloc.groupBloc.tableDataBloc.data}
												builder={() => {
													return (
														<>
															<AllContact
																groupBloc={bloc.groupBloc}
																contactBloc={bloc.contactBloc}
															/>
														</>
													);
												}}
											/>
										),
									},
									{
										name: 'Groups',
										child: (
											<StreamBuilder
												stream={bloc.contactBloc.dataBloc.data}
												builder={() => {
													return (
														<>
															<GroupContact
																contactBloc={bloc.contactBloc}
																groupBloc={bloc.groupBloc}
															/>
														</>
													);
												}}
											/>
										),
									},
								]}
							/>
						)}
					/>
				</div>
			</>
		);
	}
}
