import { Component } from 'react';
import { StreamBuilder, AppDep, BlocProvider, StreamModal } from './../../provider';
import Log from './../../common/util/log';
import TableData from '../common/TableData';

import { MDBContainer, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import FabButton from '../../layout/FabButton';
import { FormBloc } from '../../controller/form_bloc';
import TabLayout from '../../layout/TabLayout';
import { SingleContact } from '../common/SingleContact';
import { Contact, Group } from '../../controller/do';
import autoBind from 'auto-bind';
import { BehaviorSubject } from 'rxjs';
import { Backend } from '../../controller/backend';
import { GlobalContext } from '../../common/modal_boundary';
import { TableDataBloc } from '../../controller/table_data_bloc';
import { GroupBloc } from './GroupContact';
import { BulkInput } from '../common/BulkContact';
import ModalComponentV2 from '../common/ModalComponentV2';
import { Button } from '../common/button';

const TAG = 'all-contact';

export class ContactBloc {
	static Columns = [
		{ label: 'Name', field: 'name' },
		{ label: 'Mobile Number', field: 'mobile_number' },
		{ label: 'Salutation', field: 'salutation' },
		{ label: 'Groups', field: 'groups' },
	];

	public readonly addContactModal: BehaviorSubject<boolean> = new BehaviorSubject(false) as BehaviorSubject<boolean>;
	public readonly deleteItem: BehaviorSubject<Contact.Type | undefined> = new BehaviorSubject(
		undefined
	) as BehaviorSubject<Contact.Type | undefined>;

	private _api: Backend.Api;
	public singleContactForm: FormBloc<Contact.Type>;
	public bulkUploadForm: FormBloc<{ file: File }>;
	public dataBloc: TableDataBloc<Contact.Type>;

	constructor(appDep: AppDep) {
		autoBind(this);
		this._api = appDep.api;
		this.singleContactForm = new FormBloc(Contact.Constraints, this.onSingleContactAdded);
		this.bulkUploadForm = new FormBloc(
			{
				file: {
					presence: {
						allowEmpty: false,
					},
				},
			},
			this.onBulkContactAdded
		);
		this.dataBloc = new TableDataBloc(this.getdata, appDep.searchBoxQuery, [
			'name',
			'mobile_number',
			'salutation',
			'groups',
		]);
	}

	@GlobalContext.handlErrorAsModal([])
	async getdata(): Promise<Contact.Type[]> {
		return this._api.getContactData();
	}

	@GlobalContext.handlErrorAsModal()
	@GlobalContext.handleSuccessAsModal(Promise, 'Contact list updated')
	async onSingleContactAdded(value: Contact.Type): Promise<void> {
		await this._api
			.addContact({
				...value,
				group_ids: value.group_ids ?? [],
			})
			.finally(async () => {
				await this.dataBloc.updateData();
				this.onModalClose();
			});
	}

	@GlobalContext.handlErrorAsModal()
	@GlobalContext.handleSuccessAsModal(Promise, 'Contact list updated')
	async onBulkContactAdded(value: { file: File }): Promise<void> {
		Log.d(TAG, 'inside onBulkContactAdded', value.file.name);
		let fl = new File([await value.file.text()], 'foo.csv', { type: 'text/csv' });
		await this._api.addBulkContact(fl, value.file.name.replace(/\.[^/.]+$/, '')).finally(() => this.onModalClose());
	}

	onEditClicked(item: Contact.Type): void {
		Log.d(TAG, 'inside onItemClicked', item);
		this.onModalOpen(item);
	}

	onModalClose(): void {
		Log.d(TAG, 'inside onModalClose');
		this.addContactModal.next(false);
	}

	onModalOpen(item: Contact.Type | undefined): void {
		Log.d(TAG, 'inside onModalOpen');
		this.singleContactForm.setObjectValue(item);
		this.addContactModal.next(true);
	}

	onDeleteItem(item: Contact.Type): void {
		Log.d(TAG, 'inside onDeleteItem', item);
		this.deleteItem.next(item);
	}

	onDeleteItemModalClose(): void {
		this.deleteItem.next(undefined);
	}

	@GlobalContext.handlErrorAsModal()
	async onDeleteItemConfirmed(item: Contact.Type): Promise<void> {
		Log.d(TAG, 'inside onDeleteItemConfirmed', item);
		await this._api.deleteContact(item).finally(() => {
			this.deleteItem.next(undefined);
			this.dataBloc.updateData();
		});
	}
}

class AddContactModal extends Component<{ contactBloc: ContactBloc; groupBloc: GroupBloc }, {}> {
	constructor(props: any) {
		super(props);
		autoBind(this);
	}

	render() {
		let isEditMode = this.props.contactBloc.singleContactForm.formValue.value?.id ? true : false;
		Log.d(TAG, 'inside render', 'idEditMode', isEditMode);
		let children = [
			{
				name: isEditMode ? 'Edit' : 'Single',
				child: (
					<div>
						<StreamBuilder
							stream={this.props.groupBloc.tableDataBloc.data}
							builder={(data: Group.Type[]) => {
								Log.d(TAG, 'inside stream-builder builder for SingleContact');
								return (
									<>
										<SingleContact
											formBloc={this.props.contactBloc.singleContactForm}
											groupList={data}
										/>
									</>
								);
							}}
						/>
					</div>
				),
			},
		];
		if (!isEditMode) {
			children.push({
				name: 'Bulk',
				child: (
					<div>
						<BulkInput formBloc={this.props.contactBloc.bulkUploadForm} templatePath="/new_contacts.csv" />
					</div>
				),
			});
		}
		return (
			<>
				<div className="mt-0 mr-3 ml-3 mb-3">
					<>
						<TabLayout children={children} />
					</>
				</div>
			</>
		);
	}
}

class AllContact extends Component<{ contactBloc: ContactBloc; groupBloc: GroupBloc }> {
	render() {
		return (
			<>
				<>
					<MDBContainer>
						<DeleteModal bloc={this.props.contactBloc} />
						<StreamModal
							stream={this.props.contactBloc.addContactModal}
							onClose={this.props.contactBloc.onModalClose}
						>
							<StreamBuilder
								stream={this.props.contactBloc.singleContactForm.formValue}
								builder={() => {
									return (
										<>
											<AddContactModal
												contactBloc={this.props.contactBloc}
												groupBloc={this.props.groupBloc}
											/>
										</>
									);
								}}
							/>
						</StreamModal>
					</MDBContainer>
					<StreamBuilder
						stream={this.props.contactBloc.dataBloc.data}
						builder={(data: Contact.Type[]) => (
							<TableData
								exportFields={['name', 'mobile_number', 'salutation', 'group_ids']}
								searchableFields={['name', 'mobile_number', 'salutation', 'groups']}
								exportFileName="contacts.csv"
								onEdit={this.props.contactBloc.onEditClicked}
								onDelete={this.props.contactBloc.onDeleteItem}
								bordered={true}
								striped={true}
								data={{
									rows: data,
									columns: ContactBloc.Columns,
								}}
							/>
						)}
					/>
					<FabButton onClick={() => this.props.contactBloc.onModalOpen(undefined)} />
				</>
			</>
		);
	}
}

class DeleteModal extends Component<{ bloc: ContactBloc }, {}> {
	constructor(props: any) {
		super(props);
		autoBind(this);
	}

	render() {
		return (
			<>
				<StreamBuilder
					stream={this.props.bloc.deleteItem}
					builder={(data: Contact.Type | undefined) => {
						return (
							<>
								<ModalComponentV2
									isOpen={data ? true : false}
									toggle={this.props.bloc.onModalClose}
									header={'Delete'}
									body={<>Are You Sure you want to delete contact - {data?.name}?</>}
									buttonList={[
										<Button
											focused
											className="btn  button"
											onClick={this.props.bloc.onDeleteItemModalClose}
										>
											Cancel
										</Button>,
										<Button
											unFocused
											className="btn  button"
											onClick={() => this.props.bloc.onDeleteItemConfirmed(data!)}
										>
											Yes
										</Button>,
									]}
								/>
							</>
						);
					}}
				/>
			</>
		);
	}
}
export default AllContact;
