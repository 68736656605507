import autoBind from 'auto-bind';
import { Component } from 'react';
import Log from '../common/util/log';
import { Backend } from '../controller/backend';
import { Store } from '../controller';
import { FormBloc } from '../controller/form_bloc';
import { AppDep, BlocProvider } from '../provider';
import GuestPageLayout from './../../src/layout/GuestPageLayout';
import { Credentials } from '../controller/do';
import { GlobalContext } from '../common/modal_boundary';
import { Navigation } from '../controller';
import { InvitationData, PasswordResetForm } from './formComponent/InviteForm';

const TAG = 'login-page';

class Bloc {
	public formBloc: FormBloc<InvitationData>;
	private _api: Backend.Api;
	private _store: Store.Bloc;
	public navigation: Navigation.Bloc;

	constructor(appDep: AppDep) {
		autoBind(this);
		this.formBloc = new FormBloc(
			{
				...Credentials.Constraints,
				confirmationPassword: {
					equality: {
						attribute: 'password',
					},
				},
			},
			this.onValidated
		);
		this._api = appDep.api;
		this._store = appDep.store;
		this.navigation = appDep.navigation;
	}

	@GlobalContext.handlErrorAsModal()
	@GlobalContext.handleSuccessAsModal(
		Promise,
		'Updated successfully. You can now use the registered email-id and password to login into the BLINK platform'
	)
	async onValidated(value: InvitationData): Promise<void> {
		Log.d(TAG, 'inside onValidated', value);
		let secret = this.navigation.urlSearchQuery
			?.split('?')
			.filter(e => e !== '')
			.find(e => e.startsWith('secret='))
			?.split('=')[1];
		Log.d(TAG, 'secret', secret);
		await this._api.resetPassword({
			email_id: value.email,
			password: value.password,
			email_verify_secret: secret as string,
		});
	}
}

export class ResetPasswordPage extends Component {
	render() {
		return (
			<>
				<BlocProvider
					create={(appDep: AppDep) => new Bloc(appDep)}
					builder={(bloc: Bloc) => {
						return (
							<>
								<GuestPageLayout>
									<div className="login-form">
										<div>
											<h1 className="ml-2" style={{ color: '#DC2E20' }}>
												Reset Password
											</h1>
											<PasswordResetForm
												buttonText="Reset Password"
												formBloc={bloc.formBloc}
												navigation={bloc.navigation}
											/>
										</div>
									</div>
								</GuestPageLayout>
							</>
						);
					}}
				/>
			</>
		);
	}
}

export class AcceptInvitationPage extends Component {
	render() {
		return (
			<>
				<BlocProvider
					create={(appDep: AppDep) => new Bloc(appDep)}
					builder={(bloc: Bloc) => {
						return (
							<>
								<GuestPageLayout>
									<div className="login-form">
										<div>
											<h1 className="ml-2" style={{ color: '#DC2E20' }}>
												Accept Invitation
											</h1>
											<PasswordResetForm
												buttonText="Accept Invitation"
												formBloc={bloc.formBloc}
												navigation={bloc.navigation}
											/>
										</div>
									</div>
								</GuestPageLayout>
							</>
						);
					}}
				/>
			</>
		);
	}
}
