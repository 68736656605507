import { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import { MDBIcon } from 'mdbreact';

export class FabButton extends Component<{ onClick: () => void }> {
	render() {
		return (
			<>
				<div className="float-button">
					<Fab
						onClick={this.props.onClick}
						style={{
							float: 'right',
							backgroundColor: '#DC2E20',
							color: 'white',
						}}
						aria-label="add"
					>
						<MDBIcon icon="plus" />
					</Fab>
				</div>
			</>
		);
	}
}

export default FabButton;
