import { MDBContainer, MDBModalHeader, MDBModalBody } from 'mdbreact';
import { Component } from 'react';

export class StatusModalLayout extends Component<{ title: string; body: JSX.Element }> {
	render() {
		return (
			<div>
				<h2
					style={{
						color: '#DC2E20',
						fontSize: '1.5rem',
					}}
				>
					{this.props.title}
				</h2>
				{this.props.body}
			</div>
		);
	}
}

export class ProcessingModalLayout extends Component<{ message: string }> {
	render() {
		return (
			<>
				<StatusModalLayout
					title={'Processing'}
					body={
						<>
							<div className="text-center">
								<p>{this.props.message}</p>
							</div>
						</>
					}
				/>
			</>
		);
	}
}
