import { Component } from 'react';
import { MDBCard, MDBCardBody, MDBCardText } from 'mdbreact';
import { Group } from '../../controller/do';

//TODO make data compalsury
export class GroupCard extends Component<{ data?: Group.Type }> {
	render() {
		let data = this.props.data ?? {
			name: 'group_name',
			contacts_count: 12,
		};
		return (
			<>
				<div className="m-1">
					<MDBCard className="contact-card" style={{ width: '100%', height: '4.5rem' }}>
						<MDBCardBody>
							<MDBCardText>
								<div className="contact-card-body">
									<div className="d-flex">
										<div>
											<img
												className="img-fluid mt-1 mr-2"
												style={{
													width: '14px',
												}}
												src="/Group.png"
												alt="group"
											/>
										</div>
										<div>
											<p style={{ marginBottom: '0' }}>{data.name} </p>
											<p style={{ paddingTop: '0' }}>{data.contacts_count} Members </p>
										</div>
									</div>
								</div>
							</MDBCardText>
						</MDBCardBody>
					</MDBCard>
				</div>
			</>
		);
	}
}

export default GroupCard;
