import { MDBContainer } from 'mdbreact';
import { Component } from 'react';
import { BehaviorSubject } from 'rxjs';
import FabButton from '../../layout/FabButton';
import TabLayout from '../../layout/TabLayout';
import TableData from '../common/TableData';
import autoBind from 'auto-bind';
import {
	Analytics,
	designationTypeFromId,
	ReadableError,
	User,
	UserAnalytics,
	UserRemarksData,
} from './../../../src/controller/do';
import * as CSV from 'csv-string';

import { BlocProvider, StreamModal, AppDep, StreamBuilder } from './../../../src/provider';

import Log from './../../../src/common/util/log';
import ProfileForm from '../formComponent/ProfileForm';
import { UserRemarksForm } from '../formComponent/user-remarks-form';
import { TableDataBloc } from '../../controller/table_data_bloc';
import { FormBloc } from '../../controller/form_bloc';
import { GlobalContext } from '../../common/modal_boundary';
import { Backend, HelperDB } from '../../controller/backend';
import { BulkInput } from '../common/BulkContact';
import { Util } from '../../common/util/helper';
const TAG = 'create-user';

type StatusAndZone = {
	statusValue: 'Account created' | 'Logged In' | 'Active';
	zone: string;
	communityTypeName: string;
	designationName: string;
};
type TableDataType = User.Type & UserAnalytics & UserRemarksData.Type & StatusAndZone;

class Bloc {
	public tableDataBloc: TableDataBloc<TableDataType>;
	public readonly streamVisibility: BehaviorSubject<boolean> = new BehaviorSubject(false) as BehaviorSubject<boolean>;

	public formBloc: FormBloc<User.Type>;
	public relationFormBloc: FormBloc<UserRemarksData.Type>;
	public bulkUploadForm: FormBloc<{ file: File }>;
	private _api: Backend.Api;

	constructor(appDep: AppDep) {
		autoBind(this);
		this._api = appDep.api;
		this.tableDataBloc = new TableDataBloc(this.getAllData, appDep.searchBoxQuery, [
			'first_name',
			'last_name',
			'mobile_number',
			'email',
		]);
		this.formBloc = new FormBloc(User.Constraints, this.onSingleUserAdded);
		this.relationFormBloc = new FormBloc(UserRemarksData.Constraints, this.onRemarkChanged);
		this.bulkUploadForm = new FormBloc(
			{
				file: {
					presence: {
						allowEmpty: false,
					},
				},
			},
			this.onBulkUserAdded
		);
	}

	destroy() {
		this.tableDataBloc.destroy();
	}

	private calculateStatusValue(data: User.Type & UserAnalytics): StatusAndZone {
		let statusVal: StatusAndZone = {
			statusValue: 'Account created',
			zone: HelperDB.getZoneNameForWardId(data.wardId) ?? '',
			communityTypeName: HelperDB.getCommunityTypeNameForId(data.communityTypeId) ?? '',
			designationName: HelperDB.getDesignationNameForId(data.designationId) ?? '',
		};
		if (data.email_verified) statusVal = { ...statusVal, statusValue: 'Logged In' };
		if (data.contactCount > 20) statusVal = { ...statusVal, statusValue: 'Active' };
		return statusVal;
	}

	private mergeUser_analytics_relationship(
		userList: User.Type[],
		userAnalyticsList: UserAnalytics[],
		relationshipData: UserRemarksData.Type[]
	): (User.Type & UserAnalytics & UserRemarksData.Type)[] {
		let userAnalyticsMap = new Map(userAnalyticsList.map(e => [e.id, e]));
		let relationshipDataMap = new Map(relationshipData.map(e => [e.userId, e]));
		return userList
			.map(e =>
				userAnalyticsMap.has(e.id!)
					? { ...userAnalyticsMap.get(e.id!)!, ...e }
					: { ...({} as UserAnalytics), ...e }
			)
			.map(e =>
				relationshipDataMap.has(e.id!)
					? { ...relationshipDataMap.get(e.id!)!, ...e }
					: { ...({} as UserRemarksData.Type), ...e }
			);
	}

	@GlobalContext.handlErrorAsModal([])
	async getAllData(): Promise<TableDataType[]> {
		let data = this.mergeUser_analytics_relationship(
			await this._api.getAllUser(),
			await this._api.getAnalytics().then(e => e.userAnalyticsList),
			await this._api.getAllRemarkData()
		);
		return data.map(e => ({ ...e, ...this.calculateStatusValue(e) }));
	}

	@GlobalContext.handlErrorAsModal()
	@GlobalContext.handleSuccessAsModal(Promise, 'User data updated')
	async onRemarkChanged(item: UserRemarksData.Type): Promise<void> {
		Log.d(TAG, 'inside onRemarkChanged', item);
		this.onModalClose();
		await this._api.updateRemarkData(item).finally(() => this.tableDataBloc.updateData());
	}

	@GlobalContext.handlErrorAsModal()
	@GlobalContext.handleSuccessAsModal(Promise, 'User data updated')
	async onSingleUserAdded(item: User.Type): Promise<void> {
		Log.d(TAG, 'inside onSingleUserAdded', item);
		let ret;
		if (item.id) {
			ret = this.updateUser(item);
		} else {
			ret = this.inviteNewUser(item);
		}
		await ret.finally(() => {
			this.tableDataBloc.updateData();
			this.onModalClose();
		});
	}

	@GlobalContext.handlErrorAsModal()
	@GlobalContext.handleSuccessAsModal(Promise, 'User data updated')
	async onBulkUserAdded(item: { file: File }): Promise<void> {
		Log.d(TAG, 'insdie onBulkUserAdded', item);
		var csvString = await item.file.text();
		var lines = CSV.parse(csvString);
		var fields = lines[0] as [keyof User.Type];
		var userData = [];
		for (var i = 1; i < lines.length; i++) {
			var user: User.Type = {
				first_name: '',
				last_name: '',

				email: '',
				mobile_number: '',

				community_name: '',
				houses_count: 0,

				ward_id: 0,
				community_type_id: 0,
				designation_id: 0,
			};
			var data = lines[i];
			for (var j = 0; j < data.length; j++) {
				data[j] = data[j].trim();
				user[fields[j]] = data[j] === '' ? user[fields[j]] : data[j];
			}
			userData.push(user);
		}
		var failedInvitation = [];
		for (var k = 0; k < userData.length; k++) {
			try {
				await this.inviteNewUser(userData[k]);
			} catch (e: any) {
				failedInvitation.push({
					first_name: userData[k].first_name,
					last_name: userData[k].last_name,
					email: userData[k].email,
					mobile_number: userData[k].mobile_number,
					result: `${new ReadableError(e).readableMessage}`,
				});
			}
		}
		this.onModalClose();
		await this.tableDataBloc.updateData();
		if (failedInvitation.length > 0) {
			Util.makeCsvAvailable(
				['mobile_number', 'first_name', 'last_name', 'email', 'result'],
				'Errors.csv',
				failedInvitation
			);
			throw new ReadableError(
				`Failed for ${failedInvitation.length} users. Check the downloaded csv for more details.`
			);
		}
	}

	private async inviteNewUser(item: User.Type): Promise<void> {
		Log.d(TAG, 'inside inviteNewUser', item);
		return this._api.inviteNewUser(item);
	}

	private async updateUser(item: User.Type): Promise<void> {
		Log.d(TAG, 'inside updateUser', item);
		return this._api.updateUser(item);
	}

	onFabClicked() {
		Log.d(TAG, 'inside onFabClicked');
		this.onModalOpen(undefined);
	}

	onEditClicked(user: TableDataType) {
		Log.d(TAG, 'inside onEditClicked');
		this.onModalOpen(user);
	}

	onModalOpen(item: TableDataType | undefined) {
		Log.d(TAG, 'inside onModalOpen', item);
		this.formBloc.setObjectValue({
			designation_id: 0,
			first_name: '',
			last_name: '',
			email: '',
			mobile_number: '',
			houses_count: 0,
			community_name: '',
			community_type_id: 0,
			ward_id: 0,
			...item,
		});
		this.relationFormBloc.setObjectValue({
			userId: item?.id as number,
			remark_1: item?.remark_1 ?? '',
			remark_2: item?.remark_2 ?? '',
			remark_3: item?.remark_3 ?? '',
		});
		this.streamVisibility.next(true);
	}

	onModalClose() {
		Log.d(TAG, 'inside onModalClose');
		this.streamVisibility.next(false);
	}
}

export const UserDataTable = (props: {
	data: TableDataType[];
	onEditClicked: (value: TableDataType) => void;
}): JSX.Element => (
	<>
		<TableData
			exportFileName="user.csv"
			exportFields={[
				'ward_id',
				'zone',
				'first_name',
				'last_name',
				'email',
				'mobile_number',
				'designationName',
				'community_name',
				'communityTypeName',
				'houses_count',
				'contactCount',
				'messageTemplateCount',
				'bnp_whatsapp_group_link',
				'messageCount',
				'statusValue',
				'remark_1',
				'remark_2',
				'remark_3',
			]}
			searchableFields={['first_name', 'last_name', 'email', 'mobile_number', 'community_name', 'statusValue']}
			bordered={true}
			striped={true}
			onEdit={props.onEditClicked}
			data={{
				columns: [
					{
						label: 'First Name',
						field: 'first_name',
						sort: 'asc',
						width: 150,
					},
					{
						label: 'Last Name',
						field: 'last_name',
						sort: 'asc',
						width: 150,
					},
					{
						label: 'Email',
						field: 'email',
						sort: 'asc',
						width: 150,
					},
					{
						label: 'Mobile',
						field: 'mobile_number',
						sort: 'asc',
						width: 150,
					},
					{
						label: 'Designation',
						field: 'designationName',
						sort: 'asc',
						width: 150,
					},
					{
						label: 'Group Link',
						field: 'bnp_whatsapp_group_link',
						sort: 'asc',
						width: 150,
					},
					{
						label: 'Uploaded contacts',
						field: 'contactCount',
					},
					{
						label: 'Message Template',
						field: 'messageTemplateCount',
					},
					{
						label: 'Message Count',
						field: 'messageCount',
					},
					{
						label: 'Zone',
						field: 'zone',
						sort: 'asc',
						width: 150,
					},
					{
						label: 'Status',
						field: 'statusValue',
						sort: 'asc',
						width: 150,
					},
				],
				rows: props.data.map(e => ({
					...e,
					email_verified: e.email_verified ? 'TRUE' : 'FALSE',
					designationName: designationTypeFromId(e.designation_id)?.NAME,
				})),
			}}
		/>
	</>
);

export class CreateUser extends Component {
	render() {
		return (
			<BlocProvider
				create={(appDep: AppDep) => new Bloc(appDep)}
				updated={(bloc: Bloc) => bloc.tableDataBloc.updateData()}
				destroy={(bloc: Bloc) => bloc.destroy()}
				builder={(bloc: Bloc) => {
					return (
						<>
							<div>
								<MDBContainer>
									<StreamModal stream={bloc.streamVisibility} onClose={bloc.onModalClose}>
										<UserDataEditModal bloc={bloc} />
									</StreamModal>
								</MDBContainer>

								<div className="m-2">
									<>
										<StreamBuilder
											stream={bloc.tableDataBloc.data}
											builder={(data: TableDataType[]) => (
												<TabLayout
													children={[
														{
															name: 'Active Users',
															child: UserDataTable({
																data: data.filter(e => e.is_profile_active),
																onEditClicked: bloc.onEditClicked,
															}),
														},
														{
															name: 'All Users',
															child: UserDataTable({
																data: data,
																onEditClicked: bloc.onEditClicked,
															}),
														},
													]}
												/>
											)}
										/>
									</>
								</div>
								<FabButton onClick={bloc.onFabClicked} />
							</div>
						</>
					);
				}}
			/>
		);
	}
}

function UserDataEditModal(props: { bloc: Bloc }): JSX.Element {
	let isEditMode = props.bloc.formBloc.formValue.value?.id ? true : false;
	Log.d(TAG, 'inside render', 'idEditMode', isEditMode);
	let children = [
		{
			name: isEditMode ? 'Edit' : 'Single',
			child: <ProfileForm isAdmin formBloc={props.bloc.formBloc} />,
		},
	];
	if (isEditMode) {
		children.push({
			name: 'Remarks / Notes',
			child: <UserRemarksForm formBloc={props.bloc.relationFormBloc} />,
		});
	}
	if (!isEditMode) {
		children.push({
			name: 'Bulk',
			child: (
				<div>
					<BulkInput formBloc={props.bloc.bulkUploadForm} templatePath="/new_invitation.csv" />
				</div>
			),
		});
	}
	return (
		<>
			<div className="mt-0 mr-3 ml-3 mb-3">
				<TabLayout children={children} />
			</div>
		</>
	);
}

export default CreateUser;
