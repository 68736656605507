import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

import { MDBInput, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon } from 'mdbreact';

import { Backend, WardList } from '../../controller/backend';
import { MapStreamBuilder, withBloc } from './../../provider';
import Log from '../../common/util/log';
import validate from 'validate.js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StreamBuilder, AppContext, AppDep } from './../../provider';

import autoBind from 'auto-bind';
import { Attachment, EditableCentralMessage, LocalAttachment } from '../../controller/do';
import { FormBloc, FormInputField, ValueHandler } from '../../controller/form_bloc';
import { DropzoneDialog } from 'material-ui-dropzone';
import { ChipComponent } from '../common/ChipComponent';
import SearchWithDropDown from '../main/SearchWithDropDown';

const TAG = 'CreateMessageForm';

class CreateMessageForm extends Component<
	{ formBloc: FormBloc<EditableCentralMessage>; onClose?: () => void },
	{ fileName: undefined | File }
> {
	render() {
		return (
			<>
				<MDBCol className="mt-2">
					<MDBCard style={{ width: '100%' }}>
						<MDBCardBody>
							<div style={{ display: 'flex' }}>
								<MDBCardTitle
									className="ml-2"
									style={{
										color: '#DC2E20',
										fontSize: '1.3rem',
										width: '100%',
									}}
								>
									Create New Message
								</MDBCardTitle>
								<div
									onClick={() => {
										this.props.formBloc.clearAll();
										this.props.onClose?.();
									}}
									className="btn"
									style={{ marginBottom: '14px', marginRight: '14px', marginLeft: '14px' }}
								>
									<img style={{ width: '15px' }} src="./crossx.png" alt="close" />
								</div>
							</div>
							<Form>
								<Form.Group className="mb-3 m-2" controlId="formBasicEmail">
									<FormInputField
										controller={this.props.formBloc}
										objectKey="title"
										builder={(
											value: string | undefined,
											onChange: ValueHandler<string>,
											validationError: string | undefined
										) => {
											return (
												<>
													<MDBInput
														label="MESSAGE TITLE"
														type="text"
														value={value ?? ''}
														getValue={e => onChange(e as string)}
														outline
													/>
													<Form.Text style={{ color: '#DC2E20' }}>
														{validationError}
													</Form.Text>
												</>
											);
										}}
									/>
								</Form.Group>

								<Form.Group className="mb-3 m-2" controlId="formBasicPassword">
									<FormInputField
										controller={this.props.formBloc}
										objectKey="message"
										builder={(
											value: string | undefined,
											onChange: ValueHandler<string>,
											validationError: string | undefined
										) => {
											return (
												<>
													<MDBInput
														label="MESSAGE TO SEND"
														type="textarea"
														rows="4"
														value={value ?? ''}
														getValue={e => onChange(e as string)}
														outline
													/>
													<Form.Text style={{ color: '#DC2E20' }}>
														{validationError}
													</Form.Text>
												</>
											);
										}}
									/>
								</Form.Group>

								<Form.Group className="mb-3 m-2">
									<FormInputField
										controller={this.props.formBloc}
										objectKey="ward_id"
										builder={(value: number | undefined, onChange: ValueHandler<number>) => {
											return (
												<>
													<SearchWithDropDown
														label="WARD NUMBER"
														getValue={e => onChange(e?.id)}
														field="displayName"
														value={
															WardList.find(e => e.id === value) ??
															({ id: -1, name: '' } as any)
														}
														optionList={WardList}
													/>
												</>
											);
										}}
									/>
								</Form.Group>
								<FormInputField
									controller={this.props.formBloc}
									objectKey="attachmentList"
									builder={(
										value: Attachment[] | undefined,
										onChange: ValueHandler<Attachment[]>
									) => {
										return (
											<>
												<div className="mb-3 m-2 d-flex">
													<div className="mr-4 d-flex" style={{ flexWrap: 'wrap' }}>
														{value?.map(e => (
															<ChipComponent
																attachment={e ?? []}
																onRemove={e => onChange(value?.filter(f => f !== e))}
															/>
														))}
													</div>
												</div>
												<div
													className="mb-3 m-2 mr-2"
													style={{
														border: '1px solid grey',
													}}
												>
													{/*TODO: Accept images and videos only here*/}
													<div className="fileUpload">
														<input
															onChange={e =>
																onChange(
																	e.target.files
																		? [new LocalAttachment(e.target.files[0])]
																		: []
																)
															}
															type="file"
															accept="image/*"
															className="upload"
														/>
														<span>
															<MDBIcon icon="upload" /> Attach image
														</span>
													</div>
												</div>
											</>
										);
									}}
								/>
								<div className="d-flex m-2">
									<div className="form-button" style={{ width: '40%', marginTop: '10px' }}>
										<FormInputField
											controller={this.props.formBloc}
											objectKey="isPinned"
											builder={(value: boolean | undefined, onChange: ValueHandler<boolean>) => {
												return (
													<>
														<MDBInput
															checked={value}
															label="Pin this message"
															type="checkbox"
															id="checkbox1"
															getValue={e => onChange(e as any)}
														/>
													</>
												);
											}}
										/>
									</div>
									<div className="form-button" style={{ width: '40%', marginTop: '10px' }}>
										<FormInputField
											controller={this.props.formBloc}
											objectKey="isArchived"
											builder={(value: boolean | undefined, onChange: ValueHandler<boolean>) => {
												return (
													<>
														<MDBInput
															checked={value}
															label="Archive this message"
															type="checkbox"
															id="checkbox1"
															getValue={e => onChange(e as any)}
														/>
													</>
												);
											}}
										/>
									</div>
									<div className="mr-2" style={{ width: '60%' }}>
										<MapStreamBuilder
											stream={this.props.formBloc.submitEnabled}
											map={e => (e ? '' : 'disabled')}
											builder={(cls: string) => {
												return (
													<>
														<a
															style={{
																backgroundColor: '#DB2A1D',
																color: 'white',
																width: '100%',
															}}
															className={`btn button form-button ${cls}`}
															onClick={this.props.formBloc.onSubmit}
														>
															Save Message
														</a>
													</>
												);
											}}
										/>
									</div>
								</div>
							</Form>
						</MDBCardBody>
					</MDBCard>
				</MDBCol>
			</>
		);
	}
}

export default CreateMessageForm;
