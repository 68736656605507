import React, { Component, useState } from 'react';
import TableData from '../../components/common/TableData';
import autoBind from 'auto-bind';

import { MessageCampaign, MessageDetails } from './../../../src/controller/do';
import { BlocProvider, StreamBuilder, AppDep, StreamModal } from './../../../src/provider';
import { Backend } from './../../../src/controller/backend';
import { BehaviorSubject } from 'rxjs';
import MessageCard from '../CardComponent/MessageCard';
import Log from '../../common/util/log';
import { GlobalContext } from '../../common/modal_boundary';
import { TableDataBloc } from '../../controller/table_data_bloc';
import { Helper } from '../../common/util/helper';
import { TextButton } from '../common/button';
import { QrCodeComponent, QrCodeModalBloc } from '../common/qr_code_modal';

const TAG = 'sent-message';

class Bloc {
	public readonly detailsModal: BehaviorSubject<boolean> = new BehaviorSubject(
		false as boolean
	) as BehaviorSubject<boolean>;
	public readonly messageDetails: BehaviorSubject<MessageDetails[]> = new BehaviorSubject(
		[] as MessageDetails[]
	) as BehaviorSubject<MessageDetails[]>;

	public readonly tableDataBloc: TableDataBloc<MessageCampaign.Type>;

	private _api: Backend.Api;
	private _dataUpdateIntervalId: any;
	public qrCodeModalBloc: QrCodeModalBloc;

	public static create(appDep: AppDep): Bloc {
		Log.d(TAG, 'inside create');
		return new Bloc(appDep);
	}

	private constructor(appDep: AppDep) {
		autoBind(this);
		Log.d(TAG, 'inside constructor');
		this._api = appDep.api;
		this.tableDataBloc = new TableDataBloc(this.getData, appDep.searchBoxQuery, [
			'metadata.message',
			'metadata.group_names',
		]);
		this.tableDataBloc.data.subscribe(e => Log.d(TAG, 'updated-data', e));
		this._dataUpdateIntervalId = setInterval(this.tableDataBloc.updateData, 7000);
		this.qrCodeModalBloc = new QrCodeModalBloc(this._api);
	}

	destroy() {
		Log.d(TAG, 'inside onDestroy');
		this._dataUpdateIntervalId && clearInterval(this._dataUpdateIntervalId);
		this._dataUpdateIntervalId = undefined;
	}

	@GlobalContext.handlErrorAsModal([])
	async restartSession(id: number): Promise<void> {
		Log.d(TAG, 'restarting message session', id);
		this.onModalClose();
		try {
			this.qrCodeModalBloc.doQrCodeValidation(id);
			await this._api.restartCampaignSession(id);
		} finally {
			this.qrCodeModalBloc.onModalClose();
		}
	}

	// TODO: Add an intelligent way to handle error, such that it does not pop-up if it hasn't been triggered for 'N' number of times in
	// 'M' minutes. This is to prevent the error message when the system is under load at small peak moments.
	async getData(): Promise<any> {
		let data = await this._api.getSentMessages();
		return data.map(e => Helper.flattenObject(e));
	}

	@GlobalContext.handlErrorAsModal()
	async onViewDetails(item: MessageCampaign.Type): Promise<void> {
		Log.d(TAG, 'inside onViewDetails', item);
		this.onModalOpen();
		let list: MessageDetails[] = await this._api.getMessageCampaignDetails(item);
		this.messageDetails.next(list);
	}

	onModalOpen() {
		Log.d(TAG, 'inside onModalOpen');
		this.detailsModal.next(true);
	}

	onModalClose() {
		Log.d(TAG, 'inside onModalClose');
		this.messageDetails.next([]);
		this.detailsModal.next(false);
	}
}

class SentMessage extends Component {
	state = {
		modal: true,
	};

	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};

	render() {
		return (
			<>
				<BlocProvider
					id="sent-message-page-bloc"
					create={(appDep: AppDep) => Bloc.create(appDep)}
					destroy={(bloc: Bloc) => bloc.destroy()}
					builder={(bloc: Bloc) => {
						return (
							<>
								<QrCodeComponent bloc={bloc.qrCodeModalBloc} />
								<StreamModal size={'lg'} stream={bloc.detailsModal} onClose={bloc.onModalClose}>
									<h2
										className="ml-4"
										style={{
											color: '#DC2E20',
											fontSize: '1.8rem',
										}}
									>
										Details
									</h2>
									<div className="m-4 mt-0">
										<div style={{ width: '100%', height: '100%' }}>
											<StreamBuilder
												stream={bloc.messageDetails}
												builder={(details: MessageDetails[]) => {
													return (
														<>
															<TableData
																flatten
																data={{
																	rows: details.map(e => {
																		return {
																			data: {
																				...e,
																				delivered_at: e.delivered_at
																					? new Date(
																							e.delivered_at
																					  ).toLocaleString()
																					: '-',
																				created_at: e.created_at
																					? new Date(
																							e.created_at
																					  ).toLocaleString()
																					: '-',
																				discarded_at: e.discarded_at
																					? new Date(
																							e.discarded_at
																					  ).toLocaleString()
																					: '-',
																			},
																		};
																	}),
																	columns: [
																		{
																			label: 'Receiver Name',
																			field: 'data.receiver_name',
																		},
																		{
																			label: 'Created At',
																			field: 'data.created_at',
																			width: 100,
																		},
																		{
																			label: 'Delivered At',
																			field: 'data.delivered_at',
																			width: 100,
																		},
																		{
																			label: 'Discarded At',
																			field: 'data.discarded_at',
																			width: 100,
																		},
																		{
																			label: 'Discarded Reason',
																			field: 'data.discard_reason',
																			width: 100,
																		},
																	],
																}}
																searchableFields={[
																	'data.receiver_name',
																	'data.discard_reason',
																]}
															/>
														</>
													);
												}}
											/>
										</div>
									</div>
								</StreamModal>
								<StreamBuilder
									stream={bloc.tableDataBloc.data}
									builder={(data: MessageCampaign.Type[]) => {
										return (
											<>
												<div className="m-2">
													<TableData
														onViewDetails={bloc.onViewDetails}
														actionList={[
															{
																name: 'Restart',
																onClick: e => bloc.restartSession(e.id),
															},
														]}
														bordered
														data={{
															rows: data.map(e => {
																return {
																	...e,
																	created_at: new Date(e.created_at).toLocaleString(),
																	successSent: `${e.processed_count}/${e.total_count}`,
																};
															}),
															columns: [
																{
																	label: 'Message',
																	field: 'metadata.message',
																	sort: 'disabled',
																},
																{
																	label: 'Send To',
																	field: 'metadata.group_names',
																	sort: 'disabled',
																	width: 100,
																},
																{
																	label: 'Message Initiated',
																	field: 'created_at',
																	width: 100,
																},
																{
																	label: 'Successfully Sent To',
																	field: 'successSent',
																	width: 100,
																},
															],
														}}
														searchableFields={['metadata.message', 'metadata.group_names']}
														firstColumnBuilder={e => (
															<div>
																<MessageCard
																	border={'none'}
																	data={{
																		id: e.id,
																		message: e['metadata.message'],
																		created_at: e.created_at,
																		title: e.name,
																		attachmentList: [],
																		isArchived: false,
																	}}
																/>
															</div>
														)}
													/>
												</div>
											</>
										);
									}}
								/>
							</>
						);
					}}
				/>
			</>
		);
	}
}

export default SentMessage;
