import { MDBContainer, MDBModal } from 'mdbreact';
import React, { Component } from 'react';
import Log from '../../common/util/log';

const TAG = 'modal-component';

export const ModalComponentV2 = (
	props: React.PropsWithChildren<{
		size?: 'fluid' | 'sm' | 'md' | 'lg';
		isOpen?: boolean;
		toggle?: () => void;
		header?: string;
		body: JSX.Element;
		buttonList?: JSX.Element[];
	}>
) => {
	Log.d(TAG, 'inside render');
	return (
		<>
			<MDBContainer>
				<MDBModal centered {...props} toggle={props.toggle}>
					<div style={{ margin: '24px' }}>
						<div style={{ margin: '0 6px' }}>
							<h2
								style={{
									color: '#DC2E20',
									fontSize: '1.5rem',
								}}
							>
								{props.header}
							</h2>
							<div>{props.body}</div>
						</div>
						<div style={{ marginTop: '12px' }}>
							{props.buttonList ? (
								<>
									{props.buttonList.map(e => {
										return e;
									})}
								</>
							) : (
								<></>
							)}
						</div>
					</div>
				</MDBModal>
			</MDBContainer>
		</>
	);
};

export default ModalComponentV2;
