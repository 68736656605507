import autoBind from 'auto-bind';
import { ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import { BehaviorSubject } from 'rxjs';
import Log from '../../common/util/log';
import { BlocProvider, MapStreamBuilder } from '../../provider';

const TAG = 'have-data';
export class DualLayoutBloc {
	constructor() {
		autoBind(this);
	}

	public readonly layout: BehaviorSubject<'L' | 'R'> = new BehaviorSubject('L') as BehaviorSubject<'L' | 'R'>;
	onHaveData() {
		Log.d(TAG, 'inside onHaveData');
		this.layout.next('R');
	}

	onDataRemoved() {
		Log.d(TAG, 'inside onDataRemove');
		this.layout.next('L');
	}
}

export type Builder = (bloc: DualLayoutBloc) => JSX.Element;
export default function DualLayout(props: { left: Builder; right: Builder }): ReactElement {
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 995 });
	const isTabletOrMobile = useMediaQuery({ maxWidth: 1100 });

	return (
		<BlocProvider
			create={() => new DualLayoutBloc()}
			builder={(bloc: DualLayoutBloc) => {
				return (
					<>
						{isDesktopOrLaptop && (
							<div className="row">
								<div className="col-md-6">{props.left(bloc)}</div>

								<div className="col-md-6">{props.right(bloc)}</div>
							</div>
						)}
						{isTabletOrMobile && (
							<>
								<MapStreamBuilder
									stream={bloc.layout}
									map={(data: 'L' | 'R') => data === 'L'}
									builder={(showLeft: boolean) => {
										return showLeft ? (
											<div className="row">
												<div className="col-md-12">{props.left(bloc)}</div>
											</div>
										) : (
											<div className="row">
												<>{props.right(bloc)}</>
											</div>
										);
									}}
								/>
							</>
						)}
					</>
				);
			}}
		/>
	);
}
