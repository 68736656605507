import React, { FC, ReactElement, Component } from 'react';
import { Router } from 'react-router-dom';
import { SelectItem, Navigation, SearchBoxQuery, Store } from './controller';
import { createBrowserHistory } from 'history';
import { Backend } from './controller/backend';
import Log from './common/util/log';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import autoBind from 'auto-bind';
import { MDBModal } from 'mdbreact';
import ModalComponent from '../src/components/common/ModalComponent';

export type AppDep = {
	store: Store.Bloc;
	navigation: Navigation.Bloc;
	searchBoxQuery: SearchBoxQuery;
	api: Backend.Api;
	history: any;
};
export const AppContext = React.createContext({} as AppDep);

const TAG = 'provider';

export class AppProvider extends React.Component {
	private store?: Store.Bloc;
	private routerHistory?: any;
	private navigation?: Navigation.Bloc;
	private api?: Backend.Api;

	componentWillMount() {
		Log.d(TAG, 'inside componentWillMount');
		this.store = new Store.Bloc();
		this.routerHistory = createBrowserHistory({});
		this.navigation = Navigation.Bloc.create(this.store, this.routerHistory);
		let serverUrl = process.env.REACT_APP_BASE_URL;
		this.api = new Backend.ServerApi(serverUrl!, this.store);
	}

	componentWillUnmount() {
		Log.d(TAG, 'inside componentWillUnmount');
		this.navigation?.destroy();
	}

	render() {
		return (
			<Router history={this.routerHistory}>
				<AppContext.Provider
					value={{
						store: this.store as Store.Bloc,
						navigation: this.navigation as Navigation.Bloc,
						searchBoxQuery: new SearchBoxQuery(),
						api: this.api as Backend.Api,
						history: this.routerHistory,
					}}
				>
					{this.props.children}
				</AppContext.Provider>
			</Router>
		);
	}
}

export const withContext = (Component: any) => {
	const Comp: FC<any> = (props: any): ReactElement => {
		return (
			<>
				<AppContext.Consumer>
					{context => {
						return <Component {...props} appDep={context} />;
					}}
				</AppContext.Consumer>
			</>
		);
	};
	return Comp;
};

export const withApi = (Component: any) => {
	const Comp: FC<any> = (props: any): ReactElement => {
		return (
			<>
				<AppContext.Consumer>
					{context => {
						return <Component {...props} api={context.api} />;
					}}
				</AppContext.Consumer>
			</>
		);
	};
	return Comp;
};

export const withBloc = (blocGenerator: (appDep: AppDep) => any, Component: any) => {
	const Comp: FC<any> = (props: any): ReactElement => {
		return (
			<>
				<AppContext.Consumer>
					{context => {
						return <Component {...props} bloc={blocGenerator(context)} />;
					}}
				</AppContext.Consumer>
			</>
		);
	};
	return Comp;
};

export class ObservableBuilder<T> extends Component<
	{ initalVal: T; stream: Observable<T>; builder: (snap: T) => JSX.Element },
	{ data: T }
> {
	_subscription?: Subscription;

	constructor(props: any) {
		super(props);
		autoBind(this);
		this.state = { data: this.props.initalVal };
	}

	componentDidMount() {
		Log.d(TAG, 'inside didMount');
		this._subscription = this.props.stream.subscribe((e: T) => this.setState({ data: e }));
	}

	componentWillUnmount() {
		Log.d(TAG, 'inside didUnmount');
		this._subscription?.unsubscribe();
	}

	render() {
		Log.d(TAG, 'inside render');
		return this.props.builder(this.state?.data);
	}
}

export class MapStreamBuilder<T, K> extends Component<
	{ stream: BehaviorSubject<T>; map: (value: T) => K; builder: (snap: K) => JSX.Element },
	{ data: K }
> {
	_subscription?: Subscription;

	constructor(props: any) {
		super(props);
		autoBind(this);
		this.state = {
			data: this.props.map(this.props.stream.value),
		};
	}

	componentDidMount() {
		Log.d(TAG, 'inside didMount');
		this._subscription = this.props.stream.subscribe((e: T) => this.setState({ data: this.props.map(e) }));
	}

	componentWillUnmount() {
		Log.d(TAG, 'inside didUnmount');
		this._subscription?.unsubscribe();
	}

	componentDidUpdate(prevProps: any) {
		if (prevProps !== this.props) this.setState({ data: this.props.map(this.props.stream.value) });
	}

	render() {
		Log.d(TAG, 'inside render');
		return this.props.builder(this.state?.data);
	}
}

export class StreamBuilder<T> extends Component<
	{ stream: BehaviorSubject<T>; builder: (snap: T) => JSX.Element },
	{ data: T }
> {
	_subscription?: Subscription;

	static TAG = 'StreamBuilder';

	constructor(props: any) {
		super(props);
		autoBind(this);
		this.state = { data: this.props.stream.value };
	}

	componentDidMount() {
		Log.d(StreamBuilder.TAG, 'inside didMount');
		this._subscription = this.props.stream.subscribe(this.onNewData);
	}

	private onNewData(data: T) {
		Log.d(StreamBuilder.TAG, 'inside onNewData', data);
		this.setState({ data });
	}

	componentWillUnmount() {
		Log.d(StreamBuilder.TAG, 'inside didUnmount');
		this._subscription?.unsubscribe();
	}

	componentDidUpdate(prevProps: any) {
		if (prevProps !== this.props) this.setState({ data: this.props.stream.value });
	}

	render() {
		Log.d(StreamBuilder.TAG, 'inside render');
		return this.props.builder(this.state?.data);
	}
}

export class VisibilityBuilder<T> extends Component<{
	map: (value: T) => boolean;
	stream: BehaviorSubject<T>;
	builder: () => JSX.Element;
}> {
	render() {
		return (
			<MapStreamBuilder
				stream={this.props.stream}
				map={this.props.map}
				builder={(snap: boolean) => {
					if (snap) return this.props.builder();
					return <></>;
				}}
			/>
		);
	}
}

export class LifecycleProvider<T> extends Component<
	{
		id?: string;
		appDep: AppDep;
		create: (appDep: AppDep) => T;
		destroy?: (bloc: T) => void;
		update?: (bloc: T) => void;
		builder: (bloc: T) => JSX.Element;
	},
	{
		bloc: T;
	}
> {
	TAG: string = 'lifecycle-bloc-provider' + (!!this.props.id ? ` id: ${this.props.id}` : '');

	constructor(props: any) {
		super(props);
		Log.d(this.TAG, 'inside constructor');
	}

	componentDidUpdate() {
		Log.d(this.TAG, 'inside componentWillUpdate');
		this.props.update?.(this.state.bloc);
	}

	componentWillReceiveProps() {
		Log.d(this.TAG, 'inside componentWillReceiveProps');
	}

	componentWillMount() {
		Log.d(this.TAG, 'inside componentWillMount');
		this.setState({
			bloc: this.props.create(this.props.appDep),
		});
	}

	componentWillUnmount() {
		Log.d(this.TAG, 'inside componentWillUnmount');
		this.props.destroy?.call(this, this.state.bloc as T);
	}

	render() {
		Log.d(this.TAG, 'inside render');
		this.props.update?.(this.state.bloc);
		return this.props.builder(this.state.bloc as T);
	}
}

export class StreamModal extends Component<
	{ stream: BehaviorSubject<boolean>; onClose: () => void; [key: string]: any },
	{ size: any }
> {
	constructor(props: any) {
		super(props);
		autoBind(this);
	}
	render() {
		return (
			<>
				<StreamBuilder
					stream={this.props.stream}
					builder={(data: boolean) => {
						return (
							<>
								<div>
									<ModalComponent
										size={this.props.size}
										isOpen={data}
										toggle={() => (data ? this.props.onClose() : () => {})}
										{...this.props}
									>
										{this.props.children}
									</ModalComponent>
								</div>
							</>
						);
					}}
				/>
			</>
		);
	}
}

export abstract class StatelessComponent<T> extends Component<T, {}> {
	constructor(props: any) {
		super(props);
		autoBind(this);
	}

	componentDidUpdate(prevProps: T) {
		if (prevProps !== this.props) this.setState({});
	}

	abstract render(): JSX.Element;
}

export function Visible(props: React.PropsWithChildren<{ visible: boolean }>): JSX.Element {
	if (props.visible && props.children) return <>{props.children}</>;
	return <></>;
}

export const BlocProvider = withContext(LifecycleProvider);
export const SelectedItemProvider = React.createContext({} as SelectItem<any>);
