import React, { Component, ReactEventHandler } from 'react';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Chip from '@material-ui/core/Chip';
import { MDBContainer, MDBModalBody, MDBModalHeader, MDBTooltip } from 'mdbreact';
import { OpenImageModal } from './OpenImageModal';
import ModalComponent from './ModalComponent';
import { Backend } from '../../controller/backend';
import Log from '../../common/util/log';
import { AppDep, BlocProvider } from '../../provider';
import { Attachment } from '../../controller/do';
import { ModalComponentV2 } from './ModalComponentV2';

const TAG = 'chip-component';

export class ChipComponent extends Component<{ attachment: Attachment; onRemove?: (attachment: Attachment) => void }> {
	state = {
		modal: false,
	};
	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};

	componentDidUpdate(prevProps: any) {
		if (prevProps !== this.props) this.setState(this.state);
	}

	render() {
		Log.d(TAG, 'rendering with', this.props);
		return (
			<>
				<div onClick={e => e.stopPropagation()}>
					<ModalComponentV2
						size="lg"
						isOpen={this.state.modal}
						toggle={this.toggle}
						header={'Preview'}
						body={
							<div>
								<BlocProvider
									create={(appDep: AppDep) => appDep}
									builder={(appDep: AppDep) => {
										return <AttachmentImage attachment={this.props.attachment} api={appDep.api} />;
									}}
								/>
							</div>
						}
						buttonList={[
							<a
								style={{
									backgroundColor: '#DB2A1D',
									color: 'white',
									padding: '9px 34px',
									marginLeft: '2px',
								}}
								className="btn  button"
								onClick={this.toggle}
							>
								Ok
							</a>,
						]}
					/>
					<Chip
						icon={<AttachmentIcon />}
						label={`${this.props.attachment.name}`}
						onClick={this.toggle}
						onDelete={this.props.onRemove ? () => this.props.onRemove?.(this.props.attachment) : undefined}
						variant="outlined"
					/>
				</div>
			</>
		);
	}
}

export class AttachmentImage extends Component<
	{ api: Backend.Api; attachment: Attachment },
	{ blobUrl: string | undefined }
> {
	constructor(props: any) {
		super(props);
		this.state = { blobUrl: undefined };
	}

	componentWillMount() {
		this.props.api
			.fetchAttachmentImage(this.props.attachment)
			.then(e => URL.createObjectURL(e))
			.then(e => this.setState({ blobUrl: e }));
	}

	componentWillUnmount() {
		if (this.state.blobUrl) URL.revokeObjectURL(this.state.blobUrl);
	}

	render() {
		if (this.state.blobUrl === undefined) return <>Loading Image. Please wait.</>;
		return (
			<img
				style={{ maxHeight: '70vh', width: '100%' }}
				src={this.state.blobUrl}
				alt="Loading Preview. Please wait"
			/>
		);
	}
}
