import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import { MDBInput } from 'mdbreact';
import { Link } from 'react-router-dom';
import { FormBloc, FormInputField, ValueHandler } from '../../controller/form_bloc';
import { MapStreamBuilder, withContext } from '../../provider';
import { Navigation } from '../../controller';

export type InvitationData = { email: string; password: string; secret: string; confirmationPassword: string };

export class PasswordResetForm extends Component<{
	buttonText: string;
	formBloc: FormBloc<InvitationData>;
	navigation: Navigation.Bloc;
}> {
	render() {
		return (
			<>
				<Form>
					<Form.Group className="mb-3 m-2" controlId="formBasicEmail">
						<FormInputField
							controller={this.props.formBloc}
							objectKey="email"
							builder={(
								value: string | undefined,
								onChange: ValueHandler<string>,
								validationError: string | undefined
							) => {
								return (
									<>
										<MDBInput
											label="EMAIL ID"
											type="email"
											outline
											getValue={e => onChange(e as string)}
											value={value}
										/>
										<Form.Text style={{ color: '#DC2E20' }} className="pb-3">
											{validationError}
										</Form.Text>
									</>
								);
							}}
						/>
					</Form.Group>
					<Form.Group className="mb-3 m-2" controlId="formBasicPassword">
						<FormInputField
							controller={this.props.formBloc}
							objectKey="password"
							builder={(
								value: string | undefined,
								onChange: ValueHandler<string>,
								validationError: string | undefined
							) => {
								return (
									<>
										<MDBInput
											label="PASSWORD"
											type="password"
											outline
											getValue={e => onChange(e as string)}
											value={value}
										/>
										<Form.Text style={{ color: '#DC2E20' }}>{validationError}</Form.Text>
									</>
								);
							}}
						/>
					</Form.Group>
					<Form.Group className="mb-3 m-2" controlId="formBasicPassword">
						<FormInputField
							controller={this.props.formBloc}
							objectKey="confirmationPassword"
							builder={(
								value: string | undefined,
								onChange: ValueHandler<string>,
								validationError: string | undefined
							) => {
								return (
									<>
										<MDBInput
											label="CONFIRMATION PASSWORD"
											type="password"
											outline
											getValue={e => onChange(e as string)}
											value={value}
										/>
										<Form.Text style={{ color: '#DC2E20' }}>{validationError}</Form.Text>
									</>
								);
							}}
						/>
					</Form.Group>

					<div className="d-grid gap-2">
						<MapStreamBuilder
							stream={this.props.formBloc.submitEnabled}
							map={(e: boolean) => (e ? '' : 'disabled')}
							builder={(cls: string) => {
								return (
									<>
										<a
											style={{
												backgroundColor: '#DB2A1D',
												color: 'white',
											}}
											className={`btn button ${cls}`}
											onClick={this.props.formBloc.onSubmit}
										>
											{this.props.buttonText}
										</a>
									</>
								);
							}}
						/>
						<>
							<a
								style={{
									placeSelf: 'center',
									color: '#DB2A1D',
								}}
								onClick={this.props.navigation.onBackToLogin}
							>
								Back To Login
							</a>
						</>
					</div>
				</Form>
			</>
		);
	}
}
