import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { MDBIcon } from 'mdbreact';
import NavBrand from '../components/common/NavBrand';

const useStyles = makeStyles({
	list: {
		width: 250,
	},
	fullList: {
		width: 'auto',
	},
});

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function TemporaryDrawer(props: any) {
	const [alignment, setAlignment] = React.useState('left');
	const classes = useStyles();
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const list = (anchor: Anchor) => (
		<div
			className={clsx(classes.list, {
				[classes.fullList]: anchor === 'top' || anchor === 'bottom',
			})}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List>
				<NavBrand />
			</List>
			<Divider />
			<div className="ml-3 mt-4" style={{ borderBottom: 'none' }}>
				{props.paginationTab}
			</div>
			<div className="ml-3 mt-3">{props.mobileAccount}</div>
			<div className="ml-3 mt-3">{props.mobileLogout}</div>
			<div className="ml-3 mt-4">
				<a style={{ textDecoration: 'none', color: 'grey' }} href="/contact">
					Contact Us
				</a>
			</div>
		</div>
	);

	return (
		<div>
			{(['left'] as Anchor[]).map(anchor => (
				<React.Fragment key={anchor}>
					<Button className="toggle-button mt-3" onClick={toggleDrawer(anchor, true)}>
						<MDBIcon icon="bars" size="lg" />
					</Button>
					<Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
						{list(anchor)}
					</Drawer>
				</React.Fragment>
			))}
		</div>
	);
}
