import { Component } from 'react';
import { MDBDataTable, MDBIcon, MDBTooltip } from 'mdbreact';
import { SearchBoxQuery } from '../../controller';
import { AppDep, StreamBuilder, Visible } from './../../provider';
import Log from './../../common/util/log';
import autoBind from 'auto-bind';
import { BlocProvider } from './../../../src/provider';
import { Helper, Util } from './../../../src/common/util/helper';

import { Button, IconButton, TextButton } from './button';
import { InputGroup, FormControl } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';

const TAG = 'TableData';

export class SearchBox extends Component<{ onValueChange: (value: string) => void }, { currentText: string }> {
	constructor(props: any) {
		super(props);
		autoBind(this);
		this.state = { currentText: '' };
	}

	private _onValueChange(value: string) {
		this.setState({ currentText: value });
		this.props.onValueChange(value);
	}

	onCrossClicked() {
		this._onValueChange('');
	}

	render() {
		return (
			<>
				<div className=" search-form position-relative">
					<InputGroup>
						<FormControl
							style={{ border: 'none' }}
							placeholder="Search"
							aria-label=""
							aria-describedby="basic-addon2"
							onChange={e => this._onValueChange.bind(this)(e.target.value)}
							value={this.state.currentText}
						/>
						<InputGroup.Text id="basic-addon2" style={{ backgroundColor: 'white', border: 'none' }}>
							<div className="mb-3 pr-3" id="basic-addon2">
								{this.state.currentText ? (
									<MDBIcon
										style={{ cursor: 'pointer' }}
										onClick={this.onCrossClicked}
										className="fa fa-search fa-lg position-absolute"
										icon="times"
									/>
								) : (
									<MDBIcon className="fa fa-search fa-lg position-absolute" icon="search" />
								)}
							</div>
						</InputGroup.Text>
					</InputGroup>
				</div>
			</>
		);
	}
}

export default class TableData extends Component<{
	firstColumnBuilder?: (item: any) => JSX.Element;
	data: { columns: any[]; rows: any[] };
	flatten?: boolean;
	striped?: boolean;
	bordered?: boolean;
	exportFields?: string[];
	exportFileName?: string;
	deepFindSearch?: boolean;
	onEdit?: (item: any) => void;
	onDelete?: (item: any) => void;
	onViewDetails?: (item: any) => void;
	actionList?: { name: string; onClick: (item: any) => void }[];
	searchableFields?: string[];
}> {
	private getFlattenRow(data: any[]): any[] {
		return data.map(Helper.flattenObject);
	}

	private buildComponentInFirstColumn(firstColumnBuilder: any, data: any): any {
		let firstColumnField: string = data.columns[0].field;
		let rows = data.rows.map((e: any) => {
			let ret: any = {};
			ret[firstColumnField] = firstColumnBuilder(e);
			return {
				...e,
				...ret,
			};
		});
		return {
			...data,
			rows,
		};
	}

	private addActionsColumn(data: any, onEdit?: any, onDelete?: any, onViewDetails?: any) {
		let columns = [
			...data.columns,
			{
				label: 'Action',
				field: 'action',
				sort: 'disabled',
			},
		];
		let rows = data.rows.map((e: any, i: number) => {
			return {
				...e,
				action: [
					<div style={{ display: 'flex', gap: '10px' }}>
						<Visible visible={onEdit}>
							<IconButton
								className="mr-2"
								tooltip="Edit"
								onClick={() => this.props.onEdit?.(data.rows[i])}
								src="/Path.png"
							/>
						</Visible>
						<Visible visible={onDelete}>
							<IconButton
								tooltip="Delete"
								onClick={() => this.props.onDelete?.(e)}
								src="/delete_black.png"
							/>
						</Visible>
						<Visible visible={onViewDetails}>
							<TextButton
								onClick={() => this.props.onViewDetails?.(this.props.data.rows[i])}
								text="View Details"
							/>
						</Visible>
						{this.props.actionList?.map(f => (
							<TextButton onClick={() => f.onClick(e)} text={f.name} />
						))}
					</div>,
				],
			};
		});
		return {
			columns,
			rows,
		};
	}

	applySearch<T extends { [key: string]: string }>(
		query: string,
		data: T[],
		bSubject: BehaviorSubject<T[]>,
		searchableFields: (keyof T)[]
	) {
		let find = (obj: any, field: string) => {
			if (this.props.deepFindSearch) return Util.deepFind(obj, field);
			return obj[field] as string;
		};

		if (query === '') {
			return bSubject.next(data);
		}

		let list = data.filter((e: T) => {
			let match = false;
			searchableFields.forEach(f => {
				let objData = find(e, f as string);
				if (objData) {
					if (objData instanceof Array) objData = objData.join();

					match = match || objData.toLowerCase().includes(query.toLowerCase());
				}
			});
			return match;
		});

		return bSubject.next(list);
	}

	preProcessTheTable(rowData: any[]) {
		let data = {
			rows: rowData,
			columns: this.props.data.columns,
		};
		if (this.props.firstColumnBuilder) data = this.buildComponentInFirstColumn(this.props.firstColumnBuilder, data);

		if (this.props.onEdit || this.props.onDelete || this.props.onViewDetails)
			data = this.addActionsColumn(data, this.props.onEdit, this.props.onDelete, this.props.onViewDetails);

		return data;
	}

	private rowSubject = new BehaviorSubject([] as any[]);
	render() {
		let inputRowData = this.props.data.rows;

		if (this.props.flatten) inputRowData = this.getFlattenRow(inputRowData);

		this.rowSubject.next(inputRowData);

		return (
			<>
				{/* TODO fix overlay scroll bar hide when  modal component is open */}
				<div style={{ overflow: 'auto' }} className="TableData">
					<div className="export-button" style={{ display: 'flex' }}>
						<div className="search-button" style={{ marginTop: '7px' }}>
							<SearchBox
								onValueChange={(value: string) =>
									this.applySearch(
										value,
										inputRowData,
										this.rowSubject,
										this.props.searchableFields ?? []
									)
								}
							/>
						</div>
						<div>
							<Visible visible={this.props.exportFields ? true : false}>
								<div className="button-export">
									<Button
										outlined
										onClick={() =>
											Util.makeCsvAvailable(
												this.props.exportFields!,
												this.props.exportFileName ?? 'data',
												inputRowData
											)
										}
									>
										Export
									</Button>
								</div>
							</Visible>
						</div>
					</div>
					<StreamBuilder
						stream={this.rowSubject}
						builder={(rows: any) => {
							let data = this.preProcessTheTable(rows);
							return (
								<>
									<DefaultDataTable
										data={data}
										bordered={this.props.bordered}
										striped={this.props.striped}
									/>
								</>
							);
						}}
					/>
				</div>
			</>
		);
	}
}

// TODO: By using any we basically throw all the good things provided by Typescript. Can we remove any here ?
class DefaultDataTable extends Component<{
	striped?: boolean;
	bordered?: boolean;
	data: { rows: any[]; columns: any[] };
}> {
	render() {
		return (
			<>
				<MDBDataTable
					striped={this.props.striped}
					bordered={this.props.bordered}
					small
					data={this.props.data}
					paginationLabel={[<MDBIcon icon="angle-left" />, <MDBIcon icon="angle-right" />]}
					info={true}
					entriesLabel={'Entries per page: '}
					noBottomColumns={true}
					searching={false}
					displayEntries={true}
					exportToCSV={true}
					entriesOptions={[5, 10, 20, 50, 100, 500]}
					autoWidth={false}
					scrollX={true}
					entries={5}
				/>
			</>
		);
	}
}
