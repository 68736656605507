import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import Log from './../common/util/log';
import { Store } from './index';
import {
	User,
	MessageCampaign,
	PinnedMessage,
	Analytics,
	Ward,
	Contact,
	Group,
	MessageDetails,
	SendMessageCampaign,
	CentralMessage,
	AttachmentVisitor,
	LocalAttachment,
	OnCloudAttachment,
	Attachment,
	ReadableError,
	UserRemarksData,
} from './../controller/do';
import autoBind from 'auto-bind';
import { BehaviorSubject } from 'rxjs';
import firebase from 'firebase';

class Not200Response extends ReadableError {
	constructor(response: AxiosResponse<any>, url: string) {
		super(`Expected 200 response from ${url} but got ${response.status}. Response data - ${response.data}`);
	}
}

class AttachmentToOnCloudAttachmentVisitor implements AttachmentVisitor<Promise<OnCloudAttachment>> {
	private readonly axios: AxiosInstance;
	private readonly baseUrlForAttachment: string;
	private readonly authHeader: { [key: string]: string };

	constructor(axios: AxiosInstance, baseUrlForAttachment: string, authHeader: { [key: string]: string }) {
		autoBind(this);
		Log.d('AttachmentToOnCloudAttachmentVisitor', 'inside constructor', baseUrlForAttachment, authHeader);
		this.baseUrlForAttachment = baseUrlForAttachment;
		this.authHeader = authHeader;
		this.axios = axios;
	}

	async visitOnCloudAttachment(attachment: OnCloudAttachment): Promise<OnCloudAttachment> {
		return attachment;
	}

	async visitLocalAttachment(attachment: LocalAttachment): Promise<OnCloudAttachment> {
		let formData = new FormData();
		formData.append('attachment', attachment.file);
		let url = this.baseUrlForAttachment;
		const r: AxiosResponse<any> = await this.axios.post(url, formData, { headers: this.authHeader });
		if (r.status === 200) return new OnCloudAttachment(r.data.data.path, attachment.name, attachment.contentType);

		throw new Not200Response(r, url);
	}
}

class AttachmentToFileVisitor implements AttachmentVisitor<Promise<File>> {
	private readonly axios: AxiosInstance;
	private readonly baseUrlForAttachment: string;
	private readonly authHeader: { [key: string]: string };

	constructor(axios: AxiosInstance, baseUrlForAttachment: string, authHeader: { [key: string]: string }) {
		autoBind(this);
		Log.d('AttachmentToFileVisitor', 'inside constructor', baseUrlForAttachment, authHeader);
		this.baseUrlForAttachment = baseUrlForAttachment;
		this.authHeader = authHeader;
		this.axios = axios;
	}

	async visitOnCloudAttachment(attachment: OnCloudAttachment): Promise<File> {
		let url = `${this.baseUrlForAttachment}/${attachment.key}`;
		const r: AxiosResponse<any> = await this.axios.get(url, {
			headers: this.authHeader,
			responseType: 'blob',
		});
		if (r.status === 200) {
			return new File([r.data], attachment.name);
		}
		throw new Not200Response(r, url);
	}

	async visitLocalAttachment(attachment: LocalAttachment): Promise<File> {
		return attachment.file;
	}
}

export namespace Backend {
	const TAG = 'Backend';

	export interface Api {
		getAllRemarkData(): Promise<UserRemarksData.Type[]>;
		updateRemarkData(item: UserRemarksData.Type): Promise<void>;
		loginUser(data: { email: string; password: string }): Promise<Store.AuthData>;

		getUser(): Promise<User.Type>;
		getAllUser(): Promise<User.Type[]>;
		getContactData(): Promise<Contact.Type[]>;
		getGroupData(): Promise<Group.Type[]>;

		getSentMessages(): Promise<MessageCampaign.Type[]>;
		getCentralMessages(): Promise<CentralMessage.Type[]>;
		getPinnedMessage(): Promise<PinnedMessage>;

		getAnalytics(): Promise<Analytics>;

		addContact(data: Contact.Type): Promise<void>;
		addBulkContact(csv_file: File, optional_group_name: string): Promise<void>;
		addGroup(data: Group.Type): Promise<void>;
		updateGroup(data: Group.Type): Promise<void>;
		deleteGroup(data: Group.Type): Promise<void>;
		deleteContact(data: Contact.Type): Promise<void>;

		inviteNewUser(data: User.Type): Promise<void>;
		updateUser(data: User.Type): Promise<void>;

		getMessageCampaignDetails(data: MessageCampaign.Type): Promise<MessageDetails[]>;
		sendSelfTestMessage(value: SendMessageCampaign.Type): Promise<number>;
		subscribeToQrCodeData(sessionId: number): BehaviorSubject<any>;
		sendCampaign(value: SendMessageCampaign.Type): Promise<number>;
		restartCampaignSession(id: number): Promise<void>;

		updateProfile(data: User.Type): Promise<void>;
		requestPasswordReset(email: string): Promise<void>;

		updateUserPassword(password: string, new_password: string): Promise<void>;
		resetPassword(data: { email_id: string; password: string; email_verify_secret: string }): Promise<void>;

		updateCentralMessage(data: CentralMessage.Type): Promise<void>;
		updateCentralMessagePinnedId(data: number): Promise<void>;
		createNewCentralMessage(data: CentralMessage.Type): Promise<number>;

		fetchAttachmentImage(attachment: Attachment): Promise<File>;
	}

	export class ServerApi implements Api {
		private readonly _baseUrl: string;
		private readonly _store: Store.Bloc;
		private readonly _axios = Axios.create();

		constructor(baseUrl: string, store: Store.Bloc) {
			Log.d(TAG, 'inside constructor');
			autoBind(this);
			this._baseUrl = baseUrl;
			this._store = store;
			this._axios = Axios.create();
			this._axios.defaults.timeout = 100 * 1000;
		}

		async updateRemarkData(data: UserRemarksData.Type): Promise<void> {
			Log.d(TAG, 'inside updateRemarkData', data);
			const url = '/admin/user-remark/';
			const r: AxiosResponse<any> = await this._axios.put(this._baseUrl + url + data.userId, data, {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200) return;
			throw new Not200Response(r, url);
		}

		async getAllRemarkData(): Promise<UserRemarksData.Type[]> {
			Log.d(TAG, 'inside getAllRemarksData');
			const url = '/admin/user-remark-all/';
			const r: AxiosResponse<any> = await this._axios.get(this._baseUrl + url, {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200) {
				return r.data.data;
			}
			throw new Not200Response(r, url);
		}

		async fetchAttachmentImage(attachment: Attachment): Promise<File> {
			let visitor = new AttachmentToFileVisitor(this._axios, `${this._baseUrl}/whatsapp/attachment`, {
				Authorization: `Bearer ${this._store.authData.value?.token}`,
			});
			return attachment.visit(visitor);
		}

		async updateCentralMessage(data: CentralMessage.Type): Promise<void> {
			Log.d(TAG, 'inside updateCentralMessage', data);
			let uploadedList = await this.uploadAttachment(data.attachmentList);
			data = {
				...data,
				attachmentList: uploadedList,
			};
			const r: AxiosResponse<any> = await this._axios.put(
				this._baseUrl + '/whatsapp/message/central/' + data.id,
				data,
				{ headers: { Authorization: `Bearer ${this._store.authData.value?.token}` } }
			);
			if (r.status === 200) return;
			throw new Not200Response(r, '/whatsapp/message/central');
		}

		async updateCentralMessagePinnedId(data: number): Promise<void> {
			Log.d(TAG, 'inside updateCentralMessagePinnedId', data);
			const r: AxiosResponse<any> = await this._axios.put(
				this._baseUrl + '/whatsapp/pinnedMessage/',
				{ pin_message_id: data },
				{ headers: { Authorization: `Bearer ${this._store.authData.value?.token}` } }
			);
			if (r.status === 200) return;
			throw new Not200Response(r, 'whatsapp/pinnedMessage');
		}

		private async uploadAttachment(data: Attachment[]): Promise<OnCloudAttachment[]> {
			Log.d(TAG, 'inside uploadAttachment', data);
			let ret = [] as OnCloudAttachment[];
			let visitor = new AttachmentToOnCloudAttachmentVisitor(
				this._axios,
				`${this._baseUrl}/whatsapp/attachment`,
				{ Authorization: `Bearer ${this._store.authData.value?.token}` }
			);
			for (let attachment of data) {
				ret.push(await attachment.visit(visitor));
			}
			return ret;
		}

		async createNewCentralMessage(data: CentralMessage.Type): Promise<number> {
			Log.d(TAG, 'inside createNewCentralMessage', data);
			let attachmentList = await this.uploadAttachment(data.attachmentList);
			data = {
				...data,
				attachmentList: attachmentList,
			};
			const r: AxiosResponse<any> = await this._axios.post(this._baseUrl + '/whatsapp/message/central', data, {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200) return r.data.data.id;
			throw new Not200Response(r, '/whatsapp/message/central');
		}

		async addBulkContact(csv_file: File, optional_group_name: string): Promise<void> {
			Log.d(TAG, 'inside addBulkContact', csv_file, optional_group_name);

			const formData = new FormData();
			formData.append('contacts_csv', csv_file);
			formData.append('optional_group_name', optional_group_name);

			const r: AxiosResponse<any> = await this._axios.post(this._baseUrl + '/user/contacts/bulk', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${this._store.authData.value?.token}`,
				},
			});
			if (r.status === 200) return;
			throw new Not200Response(r, '/user/contacts/bulk');
		}

		async requestPasswordReset(email: string): Promise<void> {
			Log.d(TAG, 'inside requestPasswordReset', email);
			const r: AxiosResponse<any> = await this._axios.post(this._baseUrl + '/auth/request-password-reset/', {
				email_id: email,
			});
			if (r.status === 200) return;
			throw new Not200Response(r, '/auth/request-passowrd-reset');
		}

		async getAnalytics(): Promise<Analytics> {
			Log.d(TAG, 'inside getAnalytics');
			const r: AxiosResponse<any> = await this._axios.get(this._baseUrl + '/analytics', {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200) {
				return r.data.data;
			}
			throw new Not200Response(r, '/analytics');
		}

		async getPinnedMessage(): Promise<PinnedMessage> {
			Log.d(TAG, 'inside getPinnedMessage');
			const r: AxiosResponse<any> = await this._axios.get(this._baseUrl + '/whatsapp/pinnedMessage/', {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200) {
				return r.data.data;
			}
			throw new Not200Response(r, '/whatsapp/pinnedMessage');
		}

		async getCentralMessages(): Promise<CentralMessage.Type[]> {
			let authData: Store.AuthData = this._store.authData.value!;
			Log.d(TAG, 'inside getCentralMessages', authData);
			const r: AxiosResponse<any> = await this._axios.get(
				this._baseUrl +
					`/whatsapp/message/central?includeWardGeneric=true${
						!!authData.user.ward_id ? `&ward_id=${authData.user.ward_id}` : ''
					}`,
				{
					headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
				}
			);
			if (r.status === 200) {
				let data = r.data.data;
				return data.map((e: any) => {
					return {
						created_at: e.created_at,
						id: e.id,
						message: e.message,
						title: e.title,
						isArchived: !!e.isArchived,
						ward_id: e.ward_id,
						attachmentList: e.attachmentList.map(
							(f: any) => new OnCloudAttachment(f.key, f.name, f.contentType)
						),
					};
				});
			}
			throw new Not200Response(r, '/whatsapp/message/central');
		}

		async getSentMessages(): Promise<MessageCampaign.Type[]> {
			Log.d(TAG, 'inside getSentMessages');
			const r: AxiosResponse<any> = await this._axios.get(this._baseUrl + '/whatsapp/campaign', {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200) {
				return r.data.data;
			}
			throw new Not200Response(r, '/whatsapp/campaign');
		}

		async getAllUser(): Promise<User.Type[]> {
			Log.d(TAG, 'inside getAllUser');
			const r: AxiosResponse<any> = await this._axios.get(this._baseUrl + '/admin/all-user', {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200) {
				return r.data.data;
			}
			throw new Not200Response(r, '/admin/all-user');
		}

		async getUser(): Promise<User.Type> {
			Log.d(TAG, 'inside getUser');
			const r: AxiosResponse<any> = await this._axios.get(this._baseUrl + '/user', {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200) return r.data;
			throw new Not200Response(r, '/user');
		}

		async loginUser(data: { email: string; password: string }): Promise<Store.AuthData> {
			Log.d(TAG, 'inside loginUser');
			const r: AxiosResponse<any> = await this._axios.post(this._baseUrl + '/auth/login/', {
				email_id: data.email,
				password: data.password,
			});
			if (r.status === 200)
				return {
					token: r.data.token,
					user: r.data.user,
				};
			throw new Not200Response(r, '/auth/login');
		}

		async updateProfile(data: User.Type): Promise<void> {
			Log.d(TAG, 'inside updateProfile', data);
			const r: AxiosResponse<any> = await this._axios.post(this._baseUrl + '/user', data, {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200) {
				this._store.authData.next({
					...this._store.authData.value!,
					user: data,
				});
				return;
			}
			throw new Not200Response(r, '/user');
		}

		async getContactData(): Promise<Contact.Type[]> {
			Log.d(TAG, 'inside getContactData');
			const r: AxiosResponse<any> = await this._axios.get(this._baseUrl + '/user/contacts', {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200 && r.data.statusCode == 0) {
				return r.data.data;
			}
			throw new Not200Response(r, '/user/contacts');
		}

		async getGroupData(): Promise<Group.Type[]> {
			Log.d(TAG, 'inside getGroupData');
			const r: AxiosResponse<any> = await this._axios.get(this._baseUrl + '/user/group', {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200 && r.data.statusCode == 0) {
				return r.data.data;
			}
			throw new Not200Response(r, '/user/group');
		}

		async addContact(data: Contact.Type): Promise<void> {
			Log.d(TAG, 'inside addContact', data);
			const r: AxiosResponse<any> = await this._axios.post(
				this._baseUrl + '/user/contacts',
				{
					name: data.name,
					mobile_number: data.mobile_number,
					salutation: data.salutation,
					groups: data.group_ids,
				},
				{ headers: { Authorization: `Bearer ${this._store.authData.value?.token}` } }
			);
			if (r.status === 200 && r.data.statusCode == 0) {
				return;
			}
			throw new Not200Response(r, '/user/contacts');
		}

		async addGroup(data: Group.Type): Promise<void> {
			Log.d(TAG, 'inside addContact', data);
			let url = this._baseUrl + '/user/group';
			const r: AxiosResponse<any> = await this._axios.post(
				url,
				{
					group_name: data.name,
				},
				{ headers: { Authorization: `Bearer ${this._store.authData.value?.token}` } }
			);
			if (r.status === 200 && r.data.statusCode === 0) {
				return;
			}
			throw new Not200Response(r, url);
		}

		async updateGroup(data: Group.Type): Promise<void> {
			Log.d(TAG, 'inside addContact', data);
			let url = this._baseUrl + '/user/group/' + data.id;
			const r: AxiosResponse<any> = await this._axios.put(
				url,
				{
					group_name: data.name,
				},
				{ headers: { Authorization: `Bearer ${this._store.authData.value?.token}` } }
			);
			if (r.status === 200 && r.data.statusCode === 0) {
				return;
			}
			throw new Not200Response(r, url);
		}

		async deleteContact(data: Contact.Type): Promise<void> {
			Log.d(TAG, 'inside deleteContact', data);
			await this._axios.delete(this._baseUrl + `/user/contacts/${data.id}`, {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
		}

		async deleteGroup(data: Group.Type): Promise<void> {
			Log.d(TAG, 'inside deleteGroup', data);
			await this._axios.delete(this._baseUrl + `/user/group/${data.id}`, {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
		}

		async inviteNewUser(data: User.Type): Promise<void> {
			Log.d(TAG, 'inside inviteNewUser', data);
			let url = this._baseUrl + '/func/invite-user';
			const r: AxiosResponse<any> = await this._axios.post(url, data, {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200 && r.data.statusCode === 0) {
				return;
			}
			throw new Not200Response(r, url);
		}

		async updateUser(data: User.Type): Promise<void> {
			Log.d(TAG, 'inside updateUser', data);
			let url = `${this._baseUrl}/admin/user/${data?.id}`;
			const r: AxiosResponse<any> = await this._axios.put(url, data, {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200 && r.data.statusCode === 0) {
				return;
			}
			throw new Not200Response(r, url);
		}

		async getMessageCampaignDetails(data: MessageCampaign.Type): Promise<MessageDetails[]> {
			Log.d(TAG, 'inside getMessageCampaignDetails', data);
			let url = `${this._baseUrl}/whatsapp/campaign/${data?.id}`;
			const r: AxiosResponse<any> = await this._axios.get(url, {
				headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
			});
			if (r.status === 200 && r.data.statusCode === 0) {
				return r.data.data;
			}
			throw new Not200Response(r, url);
		}

		async sendSelfTestMessage(data: SendMessageCampaign.Type): Promise<number> {
			Log.d(TAG, 'inside sendSelfTestMessage', data);
			let url = `${this._baseUrl}/whatsapp/campaign/test`;
			let onCloudAttachmentList: OnCloudAttachment[] = [];
			for (let attachment of data.attachmentList)
				onCloudAttachmentList.push(
					await attachment.visit(
						new AttachmentToOnCloudAttachmentVisitor(this._axios, `${this._baseUrl}/whatsapp/attachment`, {
							Authorization: `Bearer ${this._store.authData.value?.token}`,
						})
					)
				);

			Log.d(TAG, 'attachmentList to be used', onCloudAttachmentList);
			return this._axios
				.post(
					url,
					{ message: data.message, attachmentList: onCloudAttachmentList },
					{ headers: { Authorization: `Bearer ${this._store.authData.value?.token}` } }
				)
				.then((r: AxiosResponse<any>) => {
					if (r.status === 200 && r.data.statusCode === 0) {
						return r.data.data.qrCodeStreamId;
					}
					throw new Not200Response(r, url);
				})
				.catch(e => {
					Log.e(TAG, 'Error inside axios method in sendSelfTestMessage', e);
					throw e;
				});
		}

		async restartCampaignSession(id: number): Promise<void> {
			Log.d(TAG, 'inside restartCampaignSession', id);
			const url = '/whatsapp/campaign/restart/' + id;

			const r: AxiosResponse<any> = await this._axios.put(
				this._baseUrl + url,
				{},
				{
					headers: { Authorization: `Bearer ${this._store.authData.value?.token}` },
				}
			);
			if (r.status === 200) return;
			throw new Not200Response(r, url);
		}

		async sendCampaign(data: SendMessageCampaign.Type): Promise<number> {
			Log.d(TAG, 'inside sendSelfTestMessage', data);
			let url = `${this._baseUrl}/whatsapp/campaign/`;
			let onCloudAttachmentList: OnCloudAttachment[] = [];
			for (let attachment of data.attachmentList)
				onCloudAttachmentList.push(
					await attachment.visit(
						new AttachmentToOnCloudAttachmentVisitor(this._axios, `${this._baseUrl}/whatsapp/attachment`, {
							Authorization: `Bearer ${this._store.authData.value?.token}`,
						})
					)
				);
			Log.d(TAG, 'attachmentList to be used', onCloudAttachmentList);
			return this._axios
				.post(
					url,
					{ message: data.message, groups: data.groups, attachmentList: onCloudAttachmentList },
					{ headers: { Authorization: `Bearer ${this._store.authData.value?.token}` } }
				)
				.then((r: AxiosResponse<any>) => {
					if (r.status === 200 && r.data.statusCode === 0) {
						return r.data.data.qrCodeStreamId;
					}
					throw new Not200Response(r, url);
				})
				.catch(e => {
					Log.e(TAG, 'Error inside axios method in sendCampaign', e);
					throw e;
				});
		}

		subscribeToQrCodeData(sessionId: number): BehaviorSubject<any> {
			Log.d(TAG, 'inside subscribeToQrCodeData', sessionId);
			let bs = new BehaviorSubject(undefined);
			var userImageRef = firebase.database().ref('qrCodes/' + sessionId);
			userImageRef.on('value', snapshot => {
				const data = snapshot.val();
				if (data?.data !== 'DEFAULT') {
					bs.next(data);
				} else {
					bs.next(undefined);
				}
			});
			return bs;
		}

		async updateUserPassword(password: string, new_password: string): Promise<void> {
			Log.d(TAG, 'inside updateUserPassword');
			const url = this._baseUrl + '/user/reset-pass/';
			const r: AxiosResponse<any> = await this._axios.post(
				url,
				{ password, new_password },
				{ headers: { Authorization: `Bearer ${this._store.authData.value?.token}` } }
			);
			if (r.status === 200 && r.data.statusCode === 0) {
				return;
			}
			throw new Not200Response(r, url);
		}

		async resetPassword(data: { email_id: string; password: string; email_verify_secret: string }): Promise<void> {
			Log.d(TAG, 'inside resetPassword');
			const url = this._baseUrl + '/auth/reset-pass/';
			const r: AxiosResponse<any> = await this._axios.post(url, data);
			if (r.status === 200 && r.data.statusCode === 0) {
				return;
			}
			throw new Not200Response(r, url);
		}
	}
}

export enum DesignationType {
	VOLUNTEER,
	ZONAL_LEADER,
	WARD_LEADER,
	AREA_SABHA_MEMBER,
	AREA_SABHA_LEADER,
	SUPER_ADMIN,
}

export type Designation = {
	type: DesignationType;
	id: number;
	name: string;
};

export const DesignationList: Designation[] = [
	{
		type: DesignationType.VOLUNTEER,
		id: 0,
		name: 'Volunteer',
	},
	{
		type: DesignationType.ZONAL_LEADER,
		id: 1,
		name: 'Zonal Leader',
	},
	{
		type: DesignationType.WARD_LEADER,
		id: 2,
		name: 'Ward Leader',
	},
	{
		type: DesignationType.AREA_SABHA_MEMBER,
		id: 3,
		name: 'Area Sabha Member',
	},
	{
		type: DesignationType.SUPER_ADMIN,
		id: 4,
		name: 'Super Admin',
	},
	{
		type: DesignationType.AREA_SABHA_LEADER,
		id: 5,
		name: 'Area Sabha Leader',
	},
];

export class HelperDB {
	static getWardForWardId(wardId: number): Ward | undefined {
		return WardList.find(e => e.id === wardId);
	}

	static getZoneNameForWardId(wardId: number): string | undefined {
		return ZoneList.find(e => e.wardId === wardId)?.name;
	}

	static getCommunityTypeNameForId(communityTypeId: number): string | undefined {
		return CommunityTypeList.find(e => e.id === communityTypeId)?.name;
	}

	static getDesignationNameForId(designationId: number): string | undefined {
		return DesignationList.find(e => e.id === designationId)?.name;
	}
}

export const WardList: Ward[] = [
	{ id: 1, displayName: '1 - Kempegowda Ward', wardName: 'Kempegowda Ward' },
	{ id: 2, displayName: '2 - Chowdeswari Ward', wardName: 'Chowdeswari Ward' },
	{ id: 3, displayName: '3 - Someshwara Ward', wardName: 'Someshwara Ward' },
	{ id: 4, displayName: '4 - Atturu Layout', wardName: 'Atturu Layout' },
	{ id: 5, displayName: '5 - Yelahanka Satellite Town', wardName: 'Yelahanka Satellite Town' },
	{ id: 6, displayName: '6 - Kogilu', wardName: 'Kogilu' },
	{ id: 7, displayName: '7 - Thanisandra', wardName: 'Thanisandra' },
	{ id: 8, displayName: '8 - Jakkuru', wardName: 'Jakkuru' },
	{ id: 9, displayName: '9 - Amrutahalli', wardName: 'Amrutahalli' },
	{ id: 10, displayName: '10 - Kempapura', wardName: 'Kempapura' },
	{ id: 11, displayName: '11 - Byatarayanapura', wardName: 'Byatarayanapura' },
	{ id: 12, displayName: '12 - Kodigehalli', wardName: 'Kodigehalli' },
	{ id: 13, displayName: '13 - Dodda Bommasandra', wardName: 'Dodda Bommasandra' },
	{ id: 14, displayName: '14 - Vidyaranyapura', wardName: 'Vidyaranyapura' },
	{ id: 15, displayName: '15 - Kuvempunagar', wardName: 'Kuvempunagar' },
	{ id: 16, displayName: '16 - Kammagondanahalli', wardName: 'Kammagondanahalli' },
	{ id: 17, displayName: '17 - Shettihalli', wardName: 'Shettihalli' },
	{ id: 18, displayName: '18 - Bagalakunte', wardName: 'Bagalakunte' },
	{ id: 19, displayName: '19 - Defence Colony', wardName: 'Defence Colony' },
	{ id: 20, displayName: '20 - Mallasandra', wardName: 'Mallasandra' },
	{ id: 21, displayName: '21 - T Dasarahalli', wardName: 'T Dasarahalli' },
	{ id: 22, displayName: '22 - Chokkasandra', wardName: 'Chokkasandra' },
	{ id: 23, displayName: '23 - Nelagaderanahalli', wardName: 'Nelagaderanahalli' },
	{ id: 24, displayName: '24 - Rajagopal Nagar', wardName: 'Rajagopal Nagar' },
	{ id: 25, displayName: '25 - Rajeshwari Nagar', wardName: 'Rajeshwari Nagar' },
	{ id: 26, displayName: '26 - Hegganahalli', wardName: 'Hegganahalli' },
	{ id: 27, displayName: '27 - Sunkadakatte', wardName: 'Sunkadakatte' },
	{ id: 28, displayName: '28 - DoddaBidarakallu', wardName: 'DoddaBidarakallu' },
	{ id: 29, displayName: '29 - Vidyamanyanagar', wardName: 'Vidyamanyanagar' },
	{ id: 30, displayName: '30 - Herohalli', wardName: 'Herohalli' },
	{ id: 31, displayName: '31 - Doddagollarahatti', wardName: 'Doddagollarahatti' },
	{ id: 32, displayName: '32 - Ullal', wardName: 'Ullal' },
	{ id: 33, displayName: '33 - Kengeri', wardName: 'Kengeri' },
	{ id: 34, displayName: '34 - Bande Mutt', wardName: 'Bande Mutt' },
	{ id: 35, displayName: '35 - Hemmigepura', wardName: 'Hemmigepura' },
	{ id: 36, displayName: '36 - Chatrapati Shivaji', wardName: 'Chatrapati Shivaji' },
	{ id: 37, displayName: '37 - Jnana Bharathi Nagara', wardName: 'Jnana Bharathi Nagara' },
	{ id: 38, displayName: '38 - J P Park', wardName: 'J P Park' },
	{ id: 39, displayName: '39 - Kanneshwara Rama', wardName: 'Kanneshwara Rama' },
	{ id: 40, displayName: '40 - Veeramadakari', wardName: 'Veeramadakari' },
	{ id: 41, displayName: '41 - Peenya', wardName: 'Peenya' },
	{ id: 42, displayName: '42 - Lakshmi Devi Nagar', wardName: 'Lakshmi Devi Nagar' },
	{ id: 43, displayName: '43 - Ranadheera Kanteerava', wardName: 'Ranadheera Kanteerava' },
	{ id: 44, displayName: '44 - Veera Sindhura Lakshamana', wardName: 'Veera Sindhura Lakshamana' },
	{ id: 45, displayName: '45 - Vijayanagara Krishnadevaraya', wardName: 'Vijayanagara Krishnadevaraya' },
	{ id: 46, displayName: '46 - Sir M. Vishweshwaraiah', wardName: 'Sir M. Vishweshwaraiah' },
	{ id: 47, displayName: '47 - Nalvadi Krishnaraja Wadior Park', wardName: 'Nalvadi Krishnaraja Wadior Park' },
	{ id: 48, displayName: '48 - Jnana Bharathi', wardName: 'Jnana Bharathi' },
	{ id: 49, displayName: '49 - Rajarajeshwari Nagar', wardName: 'Rajarajeshwari Nagar' },
	{ id: 50, displayName: '50 - Marappana Palya', wardName: 'Marappana Palya' },
	{ id: 51, displayName: '51 - Nagapura', wardName: 'Nagapura' },
	{ id: 52, displayName: '52 - Mahalakshmipuram', wardName: 'Mahalakshmipuram' },
	{ id: 53, displayName: '53 - Nandini Layout', wardName: 'Nandini Layout' },
	{ id: 54, displayName: '54 - Jai Maruthinagara', wardName: 'Jai Maruthinagara' },
	{ id: 55, displayName: '55 - Puneet Rajkumar', wardName: 'Puneet Rajkumar' },
	{ id: 56, displayName: '56 - Shankar Matt', wardName: 'Shankar Matt' },
	{ id: 57, displayName: '57 - Shakthi Ganapathi Nagar', wardName: 'Shakthi Ganapathi Nagar' },
	{ id: 58, displayName: '58 - Vrisabhavathi Nagar', wardName: 'Vrisabhavathi Nagar' },
	{ id: 59, displayName: '59 - Mattikere', wardName: 'Mattikere' },
	{ id: 60, displayName: '60 - Aramane Nagara', wardName: 'Aramane Nagara' },
	{ id: 61, displayName: '61 - Malleshwaram', wardName: 'Malleshwaram' },
	{ id: 62, displayName: '62 - Subramanya Nagar', wardName: 'Subramanya Nagar' },
	{ id: 63, displayName: '63 - Gayithri Nagar', wardName: 'Gayithri Nagar' },
	{ id: 64, displayName: '64 - Kadu Malleshwara', wardName: 'Kadu Malleshwara' },
	{ id: 65, displayName: '65 - Rajamahal Guttahalli', wardName: 'Rajamahal Guttahalli' },
	{ id: 66, displayName: '66 - Radhakrishna Temple Ward', wardName: 'Radhakrishna Temple Ward' },
	{ id: 67, displayName: '67 - Sanjaya Nagar', wardName: 'Sanjaya Nagar' },
	{ id: 68, displayName: '68 - Vishwanath Nagenahalli', wardName: 'Vishwanath Nagenahalli' },
	{ id: 69, displayName: '69 - Manorayanapalya', wardName: 'Manorayanapalya' },
	{ id: 70, displayName: '70 - Hebbala', wardName: 'Hebbala' },
	{ id: 71, displayName: '71 - Chamundi Nagara', wardName: 'Chamundi Nagara' },
	{ id: 72, displayName: '72 - Ganga Nagar', wardName: 'Ganga Nagar' },
	{ id: 73, displayName: '73 - Jayachamarajendra Nagar', wardName: 'Jayachamarajendra Nagar' },
	{ id: 74, displayName: '74 - Kaval Bairasandra', wardName: 'Kaval Bairasandra' },
	{ id: 75, displayName: '75 - Kushal Nagar', wardName: 'Kushal Nagar' },
	{ id: 76, displayName: '76 - Muneshwara Nagar', wardName: 'Muneshwara Nagar' },
	{ id: 77, displayName: '77 - DevaraJeevanahalli', wardName: 'DevaraJeevanahalli' },
	{ id: 78, displayName: '78 - S K Garden', wardName: 'S K Garden' },
	{ id: 79, displayName: '79 - Sagayapuram', wardName: 'Sagayapuram' },
	{ id: 80, displayName: '80 - Pulikeshinagar', wardName: 'Pulikeshinagar' },
	{ id: 81, displayName: '81 - Horamavu', wardName: 'Horamavu' },
	{ id: 82, displayName: '82 - Babusab Palya', wardName: 'Babusab Palya' },
	{ id: 83, displayName: '83 - Kalkere', wardName: 'Kalkere' },
	{ id: 84, displayName: '84 - Ramamurthy Nagara', wardName: 'Ramamurthy Nagara' },
	{ id: 85, displayName: '85 - Vijinapura', wardName: 'Vijinapura' },
	{ id: 86, displayName: '86 - K R Puram', wardName: 'K R Puram' },
	{ id: 87, displayName: '87 - Medahalli', wardName: 'Medahalli' },
	{ id: 88, displayName: '88 - Basavanapura', wardName: 'Basavanapura' },
	{ id: 89, displayName: '89 - Devasandra', wardName: 'Devasandra' },
	{ id: 90, displayName: '90 - Mahadevapura', wardName: 'Mahadevapura' },
	{ id: 91, displayName: '91 - A Narayanapura', wardName: 'A Narayanapura' },
	{ id: 92, displayName: '92 - Vijnana Nagar', wardName: 'Vijnana Nagar' },
	{ id: 93, displayName: '93 - HAL Airport', wardName: 'HAL Airport' },
	{ id: 94, displayName: '94 - Hennur ', wardName: 'Hennur ' },
	{ id: 95, displayName: '95 - Nagavara', wardName: 'Nagavara' },
	{ id: 96, displayName: '96 - Kadugondanahalli', wardName: 'Kadugondanahalli' },
	{ id: 97, displayName: '97 - Venkateshpura', wardName: 'Venkateshpura' },
	{ id: 98, displayName: '98 - Kacharkanahalli', wardName: 'Kacharkanahalli' },
	{ id: 99, displayName: '99 - HRBR Layout', wardName: 'HRBR Layout' },
	{ id: 100, displayName: '100 - Banasavadi', wardName: 'Banasavadi' },
	{ id: 101, displayName: '101 - Kammanahalli', wardName: 'Kammanahalli' },
	{ id: 102, displayName: '102 - Lingarajapura', wardName: 'Lingarajapura' },
	{ id: 103, displayName: '103 - MaruthiSeva Nagar', wardName: 'MaruthiSeva Nagar' },
	{ id: 104, displayName: '104 - Kadugodi', wardName: 'Kadugodi' },
	{ id: 105, displayName: '105 - Belathur', wardName: 'Belathur' },
	{ id: 106, displayName: '106 - Hudi', wardName: 'Hudi' },
	{ id: 107, displayName: '107 - Garudachar Palya', wardName: 'Garudachar Palya' },
	{ id: 108, displayName: '108 - DoddaNekkundi', wardName: 'DoddaNekkundi' },
	{ id: 109, displayName: '109 - AECS Layout', wardName: 'AECS Layout' },
	{ id: 110, displayName: '110 - Whitefield', wardName: 'Whitefield' },
	{ id: 111, displayName: '111 - Hagadur', wardName: 'Hagadur' },
	{ id: 112, displayName: '112 - Varthuru', wardName: 'Varthuru' },
	{ id: 113, displayName: '113 - Munnekollala', wardName: 'Munnekollala' },
	{ id: 114, displayName: '114 - Marathahalli', wardName: 'Marathahalli' },
	{ id: 115, displayName: '115 - Bellanduru', wardName: 'Bellanduru' },
	{ id: 116, displayName: '116 - Doddakanahalli', wardName: 'Doddakanahalli' },
	{ id: 117, displayName: '117 - C V Raman Nagar', wardName: 'C V Raman Nagar' },
	{ id: 118, displayName: '118 - Lal Bahadur Nagar', wardName: 'Lal Bahadur Nagar' },
	{
		id: 119,
		displayName: '119 - New Bayappanahalli (Benniganahalli)',
		wardName: 'New Bayappanahalli (Benniganahalli)',
	},
	{ id: 120, displayName: '120 - Hoysala Nagar', wardName: 'Hoysala Nagar' },
	{ id: 121, displayName: '121 - Old Thippasandra', wardName: 'Old Thippasandra' },
	{ id: 122, displayName: '122 - New Thippasandra', wardName: 'New Thippasandra' },
	{ id: 123, displayName: '123 - Jalakanteshwara Nagara', wardName: 'Jalakanteshwara Nagara' },
	{ id: 124, displayName: '124 - Jeevanbhima Nagar', wardName: 'Jeevanbhima Nagar' },
	{ id: 125, displayName: '125 - Konena Agrahara', wardName: 'Konena Agrahara' },
	{ id: 126, displayName: '126 - Ramaswamy Palya', wardName: 'Ramaswamy Palya' },
	{ id: 127, displayName: '127 - Jayamahal', wardName: 'Jayamahal' },
	{ id: 128, displayName: '128 - Vasanth Nagar', wardName: 'Vasanth Nagar' },
	{ id: 129, displayName: '129 - Sampangiram Nagar', wardName: 'Sampangiram Nagar' },
	{ id: 130, displayName: '130 - Bharathi Nagar', wardName: 'Bharathi Nagar' },
	{ id: 131, displayName: '131 - Ulsoor', wardName: 'Ulsoor' },
	{ id: 132, displayName: '132 - Dattatreya Temple', wardName: 'Dattatreya Temple' },
	{ id: 133, displayName: '133 - Gandhinagara', wardName: 'Gandhinagara' },
	{ id: 134, displayName: '134 - Subhash Nagar', wardName: 'Subhash Nagar' },
	{ id: 135, displayName: '135 - Okalipuram', wardName: 'Okalipuram' },
	{ id: 136, displayName: '136 - Binnipete', wardName: 'Binnipete' },
	{ id: 137, displayName: '137 - Cottonpete', wardName: 'Cottonpete' },
	{ id: 138, displayName: '138 - Chickpete', wardName: 'Chickpete' },
	{ id: 139, displayName: '139 - Dayananda Nagar', wardName: 'Dayananda Nagar' },
	{ id: 140, displayName: '140 - Prakash Nagar', wardName: 'Prakash Nagar' },
	{ id: 141, displayName: '141 - Rajaji Nagar', wardName: 'Rajaji Nagar' },
	{ id: 142, displayName: '142 - Sriramamandir', wardName: 'Sriramamandir' },
	{ id: 143, displayName: '143 - Shivanagara', wardName: 'Shivanagara' },
	{ id: 144, displayName: '144 - Basaveshwara Nagar', wardName: 'Basaveshwara Nagar' },
	{ id: 145, displayName: '145 - Kamakshipalya', wardName: 'Kamakshipalya' },
	{ id: 146, displayName: '146 - Dr. Raj Kumar Ward', wardName: 'Dr. Raj Kumar Ward' },
	{ id: 147, displayName: '147 - Agrahara Dasarahalli', wardName: 'Agrahara Dasarahalli' },
	{ id: 148, displayName: '148 - Govindaraja Nagar', wardName: 'Govindaraja Nagar' },
	{ id: 149, displayName: '149 - Kaveripura', wardName: 'Kaveripura' },
	{ id: 150, displayName: '150 - Marenahalli', wardName: 'Marenahalli' },
	{ id: 151, displayName: '151 - Maruthi Mandir Ward', wardName: 'Maruthi Mandir Ward' },
	{ id: 152, displayName: '152 - Mudalapalya', wardName: 'Mudalapalya' },
	{ id: 153, displayName: '153 - Nagarabhavi', wardName: 'Nagarabhavi' },
	{ id: 154, displayName: '154 - Chandra Layout', wardName: 'Chandra Layout' },
	{ id: 155, displayName: '155 - Nayandahalli', wardName: 'Nayandahalli' },
	{ id: 156, displayName: '156 - Kempapura Agrahara', wardName: 'Kempapura Agrahara' },
	{ id: 157, displayName: '157 - Vijayanagar', wardName: 'Vijayanagar' },
	{ id: 158, displayName: '158 - Hosahalli', wardName: 'Hosahalli' },
	{ id: 159, displayName: '159 - Hampi Nagar', wardName: 'Hampi Nagar' },
	{ id: 160, displayName: '160 - Bapuji Nagar', wardName: 'Bapuji Nagar' },
	{ id: 161, displayName: '161 - Attiguppe', wardName: 'Attiguppe' },
	{ id: 162, displayName: '162 - Gali Anjenaya Temple Ward', wardName: 'Gali Anjenaya Temple Ward' },
	{ id: 163, displayName: '163 - Veerabhadranagar', wardName: 'Veerabhadranagar' },
	{ id: 164, displayName: '164 - Avalahalli', wardName: 'Avalahalli' },
	{ id: 165, displayName: '165 - Chamrajpet', wardName: 'Chamrajpet' },
	{ id: 166, displayName: '166 - Cheluvadipalya', wardName: 'Cheluvadipalya' },
	{ id: 167, displayName: '167 - Jagajivanaram Nagar', wardName: 'Jagajivanaram Nagar' },
	{ id: 168, displayName: '168 - Padarayanapura', wardName: 'Padarayanapura' },
	{ id: 169, displayName: '169 - Devaraj Urs Nagar (SKR Market ()', wardName: 'Devaraj Urs Nagar (SKR Market ()' },
	{ id: 170, displayName: '170 - Azad Nagar', wardName: 'Azad Nagar' },
	{ id: 171, displayName: '171 - Sudham Nagara', wardName: 'Sudham Nagara' },
	{ id: 172, displayName: '172 - Dharmarayaswamy Temple', wardName: 'Dharmarayaswamy Temple' },
	{ id: 173, displayName: '173 - Sunkenahalli', wardName: 'Sunkenahalli' },
	{ id: 174, displayName: '174 - V.V. Puram', wardName: 'V.V. Puram' },
	{ id: 175, displayName: '175 - Ashoka Pillar (Jayanagar)', wardName: 'Ashoka Pillar (Jayanagar)' },
	{ id: 176, displayName: '176 - Siddapura', wardName: 'Siddapura' },
	{ id: 177, displayName: '177 - Hombegowda Nagara', wardName: 'Hombegowda Nagara' },
	{ id: 178, displayName: '178 - Domlur', wardName: 'Domlur' },
	{ id: 179, displayName: '179 - Jogupalya', wardName: 'Jogupalya' },
	{ id: 180, displayName: '180 - Agaram', wardName: 'Agaram' },
	{ id: 181, displayName: '181 - Shantala Nagar', wardName: 'Shantala Nagar' },
	{ id: 182, displayName: '182 - Shanthi Nagar', wardName: 'Shanthi Nagar' },
	{ id: 183, displayName: '183 - Neelasandra', wardName: 'Neelasandra' },
	{ id: 184, displayName: '184 - Vannarapete', wardName: 'Vannarapete' },
	{ id: 185, displayName: '185 - Ejipura', wardName: 'Ejipura' },
	{ id: 186, displayName: '186 - Koramangala', wardName: 'Koramangala' },
	{ id: 187, displayName: '187 - Adugodi', wardName: 'Adugodi' },
	{ id: 188, displayName: '188 - Lakkasandra', wardName: 'Lakkasandra' },
	{ id: 189, displayName: '189 - Suddagunte Palya', wardName: 'Suddagunte Palya' },
	{ id: 190, displayName: '190 - Madivala', wardName: 'Madivala' },
	{ id: 191, displayName: '191 - Jakkasandra', wardName: 'Jakkasandra' },
	{ id: 192, displayName: '192 - BTM Layout', wardName: 'BTM Layout' },
	{ id: 193, displayName: '193 - N S Palya', wardName: 'N S Palya' },
	{ id: 194, displayName: '194 - Gurappanapalya', wardName: 'Gurappanapalya' },
	{ id: 195, displayName: '195 - Tilak Nagar(Jayanagar East)', wardName: 'Tilak Nagar(Jayanagar East)' },
	{ id: 196, displayName: '196 - Byrasandra', wardName: 'Byrasandra' },
	{ id: 197, displayName: '197 - Shakambari Nagar', wardName: 'Shakambari Nagar' },
	{ id: 198, displayName: '198 - J P Nagar', wardName: 'J P Nagar' },
	{ id: 199, displayName: '199 - Sarakki', wardName: 'Sarakki' },
	{ id: 200, displayName: '200 - Yediyur', wardName: 'Yediyur' },
	{ id: 201, displayName: '201 - Umamaheshwari Ward', wardName: 'Umamaheshwari Ward' },
	{ id: 202, displayName: '202 - Ganesh Mandir Ward', wardName: 'Ganesh Mandir Ward' },
	{ id: 203, displayName: '203 - Banashankari Temple Ward', wardName: 'Banashankari Temple Ward' },
	{ id: 204, displayName: '204 - Kumaraswamy Layout', wardName: 'Kumaraswamy Layout' },
	{ id: 205, displayName: '205 - Vikran Nagar (Karisandra)', wardName: 'Vikran Nagar (Karisandra)' },
	{ id: 206, displayName: '206 - Padmanabha Nagar', wardName: 'Padmanabha Nagar' },
	{ id: 207, displayName: '207 - Kamakya Nagar (Chikkalasandra)', wardName: 'Kamakya Nagar (Chikkalasandra)' },
	{ id: 209, displayName: '209 - Hosakerehalli', wardName: 'Hosakerehalli' },
	{ id: 210, displayName: '210 - Basavanagudi', wardName: 'Basavanagudi' },
	{ id: 211, displayName: '211 - Hanumanth Nagar', wardName: 'Hanumanth Nagar' },
	{ id: 212, displayName: '212 - Srinivasa Nagar', wardName: 'Srinivasa Nagar' },
	{ id: 213, displayName: '213 - Srinagar', wardName: 'Srinagar' },
	{ id: 214, displayName: '214 - Girinagar', wardName: 'Girinagar' },
	{ id: 215, displayName: '215 - Kathriguppe', wardName: 'Kathriguppe' },
	{ id: 216, displayName: '216 - Vidyapeeta Ward', wardName: 'Vidyapeeta Ward' },
	{ id: 217, displayName: '217 - Uttarahalli', wardName: 'Uttarahalli' },
	{ id: 218, displayName: '218 - Subramanyapura', wardName: 'Subramanyapura' },
	{ id: 219, displayName: '219 - Vasanthpura', wardName: 'Vasanthpura' },
	{ id: 220, displayName: '220 - Yelachenahalli', wardName: 'Yelachenahalli' },
	{ id: 221, displayName: '221 - Konanakunte', wardName: 'Konanakunte' },
	{ id: 222, displayName: '222 - Puttenahalli', wardName: 'Puttenahalli' },
	{ id: 223, displayName: '223 - Chunchaghatta', wardName: 'Chunchaghatta' },
	{ id: 224, displayName: '224 - Anjanapura', wardName: 'Anjanapura' },
	{ id: 225, displayName: '225 - Gottigere', wardName: 'Gottigere' },
	{ id: 226, displayName: '226 - Kalena Agrahara', wardName: 'Kalena Agrahara' },
	{ id: 227, displayName: '227 - Begur', wardName: 'Begur' },
	{ id: 228, displayName: '228 - Naganathapura', wardName: 'Naganathapura' },
	{ id: 229, displayName: '229 - Ibluru', wardName: 'Ibluru' },
	{ id: 230, displayName: '230 - Agara', wardName: 'Agara' },
	{ id: 231, displayName: '231 - Mangammanapalya', wardName: 'Mangammanapalya' },
	{ id: 232, displayName: '232 - HSR-Singasandra', wardName: 'HSR-Singasandra' },
	{ id: 233, displayName: '233 - Rupena Agrahara', wardName: 'Rupena Agrahara' },
	{ id: 234, displayName: '234 - Hongasandra', wardName: 'Hongasandra' },
	{ id: 235, displayName: '235 - Bommanahalli', wardName: 'Bommanahalli' },
	{ id: 236, displayName: '236 - Devarachikkanahalli', wardName: 'Devarachikkanahalli' },
	{ id: 237, displayName: '237 - Bilekhalli', wardName: 'Bilekhalli' },
	{ id: 238, displayName: '238 - Arakere', wardName: 'Arakere' },
	{ id: 239, displayName: '239 - Hulimavu', wardName: 'Hulimavu' },
	{ id: 240, displayName: '240 - Vinayakanagar', wardName: 'Vinayakanagar' },
	{ id: 241, displayName: '241 - Puttenahalli-Sarakki Lake', wardName: 'Puttenahalli-Sarakki Lake' },
	{ id: 242, displayName: '242 - Jaraganahalli', wardName: 'Jaraganahalli' },
	{ id: 243, displayName: '243 - Kudlu', wardName: 'Kudlu' },
	{ id: 0, displayName: "0 - Don't Know", wardName: "Don't Know" },
	{ id: 999, displayName: '999 - Others', wardName: 'Others' },
];

export type Zone = {
	wardId: number;
	name: string;
};

export const ZoneList: Zone[] = [
	{ wardId: 1, name: 'Yelahanka' },
	{ wardId: 2, name: 'Yelahanka' },
	{ wardId: 3, name: 'Yelahanka' },
	{ wardId: 4, name: 'Yelahanka' },
	{ wardId: 5, name: 'Yelahanka' },
	{ wardId: 6, name: 'Yelahanka' },
	{ wardId: 7, name: 'Yelahanka' },
	{ wardId: 8, name: 'Byatarayanapura' },
	{ wardId: 9, name: 'Byatarayanapura' },
	{ wardId: 10, name: 'Byatarayanapura' },
	{ wardId: 11, name: 'Byatarayanapura' },
	{ wardId: 12, name: 'Byatarayanapura' },
	{ wardId: 13, name: 'Byatarayanapura' },
	{ wardId: 14, name: 'Byatarayanapura' },
	{ wardId: 15, name: 'Byatarayanapura' },
	{ wardId: 16, name: 'Byatarayanapura' },
	{ wardId: 17, name: 'Dasarahalli' },
	{ wardId: 18, name: 'Dasarahalli' },
	{ wardId: 19, name: 'Dasarahalli' },
	{ wardId: 20, name: 'Dasarahalli' },
	{ wardId: 21, name: 'Dasarahalli' },
	{ wardId: 22, name: 'Dasarahalli' },
	{ wardId: 23, name: 'Dasarahalli' },
	{ wardId: 24, name: 'Dasarahalli' },
	{ wardId: 25, name: 'Dasarahalli' },
	{ wardId: 26, name: 'Dasarahalli' },
	{ wardId: 27, name: 'Dasarahalli' },
	{ wardId: 28, name: 'RRNagar' },
	{ wardId: 29, name: 'RRNagar' },
	{ wardId: 30, name: 'RRNagar' },
	{ wardId: 31, name: 'RRNagar' },
	{ wardId: 32, name: 'RRNagar' },
	{ wardId: 33, name: 'RRNagar' },
	{ wardId: 34, name: 'RRNagar' },
	{ wardId: 35, name: 'RRNagar' },
	{ wardId: 36, name: 'Yeshwantpur' },
	{ wardId: 37, name: 'Yeshwantpur' },
	{ wardId: 38, name: 'Yeshwantpur' },
	{ wardId: 39, name: 'Yeshwantpur' },
	{ wardId: 40, name: 'Yeshwantpur' },
	{ wardId: 41, name: 'Yeshwantpur' },
	{ wardId: 42, name: 'Yeshwantpur' },
	{ wardId: 43, name: 'Yeshwantpur' },
	{ wardId: 44, name: 'Yeshwantpur' },
	{ wardId: 45, name: 'Yeshwantpur' },
	{ wardId: 46, name: 'Yeshwantpur' },
	{ wardId: 47, name: 'Yeshwantpur' },
	{ wardId: 48, name: 'Yeshwantpur' },
	{ wardId: 49, name: 'Yeshwantpur' },
	{ wardId: 50, name: 'Yeshwantpur' },
	{ wardId: 51, name: 'Yeshwantpur' },
	{ wardId: 52, name: 'Yeshwantpur' },
	{ wardId: 53, name: 'MahalakshmiLayout' },
	{ wardId: 54, name: 'MahalakshmiLayout' },
	{ wardId: 55, name: 'MahalakshmiLayout' },
	{ wardId: 56, name: 'MahalakshmiLayout' },
	{ wardId: 57, name: 'MahalakshmiLayout' },
	{ wardId: 58, name: 'MahalakshmiLayout' },
	{ wardId: 59, name: 'MahalakshmiLayout' },
	{ wardId: 60, name: 'Malleshwaram' },
	{ wardId: 61, name: 'Malleshwaram' },
	{ wardId: 62, name: 'Malleshwaram' },
	{ wardId: 63, name: 'Malleshwaram' },
	{ wardId: 64, name: 'Malleshwaram' },
	{ wardId: 65, name: 'Malleshwaram' },
	{ wardId: 66, name: 'Hebbal' },
	{ wardId: 67, name: 'Hebbal' },
	{ wardId: 68, name: 'Hebbal' },
	{ wardId: 69, name: 'Hebbal' },
	{ wardId: 70, name: 'Hebbal' },
	{ wardId: 71, name: 'Hebbal' },
	{ wardId: 72, name: 'Hebbal' },
	{ wardId: 73, name: 'Hebbal' },
	{ wardId: 74, name: 'Hebbal' },
	{ wardId: 75, name: 'PulakeshiNagar' },
	{ wardId: 76, name: 'PulakeshiNagar' },
	{ wardId: 77, name: 'PulakeshiNagar' },
	{ wardId: 78, name: 'PulakeshiNagar' },
	{ wardId: 79, name: 'PulakeshiNagar' },
	{ wardId: 80, name: 'PulakeshiNagar' },
	{ wardId: 81, name: 'KRPuram' },
	{ wardId: 82, name: 'KRPuram' },
	{ wardId: 83, name: 'KRPuram' },
	{ wardId: 84, name: 'KRPuram' },
	{ wardId: 85, name: 'KRPuram' },
	{ wardId: 86, name: 'KRPuram' },
	{ wardId: 87, name: 'KRPuram' },
	{ wardId: 88, name: 'KRPuram' },
	{ wardId: 89, name: 'KRPuram' },
	{ wardId: 90, name: 'KRPuram' },
	{ wardId: 91, name: 'KRPuram' },
	{ wardId: 92, name: 'KRPuram' },
	{ wardId: 93, name: 'KRPuram' },
	{ wardId: 94, name: 'KRPuram' },
	{ wardId: 95, name: 'Sarvagna Nagar' },
	{ wardId: 96, name: 'Sarvagna Nagar' },
	{ wardId: 97, name: 'Sarvagna Nagar' },
	{ wardId: 98, name: 'Sarvagna Nagar' },
	{ wardId: 99, name: 'Sarvagna Nagar' },
	{ wardId: 100, name: 'Sarvagna Nagar' },
	{ wardId: 101, name: 'Sarvagna Nagar' },
	{ wardId: 102, name: 'Sarvagna Nagar' },
	{ wardId: 103, name: 'Sarvagna Nagar' },
	{ wardId: 104, name: 'Sarvagna Nagar' },
	{ wardId: 105, name: 'Sarvagna Nagar' },
	{ wardId: 106, name: 'Mahadevapura' },
	{ wardId: 107, name: 'Mahadevapura' },
	{ wardId: 108, name: 'Mahadevapura' },
	{ wardId: 109, name: 'Mahadevapura' },
	{ wardId: 110, name: 'Mahadevapura' },
	{ wardId: 111, name: 'Mahadevapura' },
	{ wardId: 112, name: 'Mahadevapura' },
	{ wardId: 113, name: 'Mahadevapura' },
	{ wardId: 114, name: 'Mahadevapura' },
	{ wardId: 115, name: 'Mahadevapura' },
	{ wardId: 116, name: 'Mahadevapura' },
	{ wardId: 117, name: 'Mahadevapura' },
	{ wardId: 118, name: 'Mahadevapura' },
	{ wardId: 119, name: 'CV Raman Nagar' },
	{ wardId: 120, name: 'CV Raman Nagar' },
	{ wardId: 121, name: 'CV Raman Nagar' },
	{ wardId: 122, name: 'CV Raman Nagar' },
	{ wardId: 123, name: 'CV Raman Nagar' },
	{ wardId: 124, name: 'CV Raman Nagar' },
	{ wardId: 125, name: 'CV Raman Nagar' },
	{ wardId: 126, name: 'ShivajiNagar' },
	{ wardId: 127, name: 'ShivajiNagar' },
	{ wardId: 128, name: 'ShivajiNagar' },
	{ wardId: 129, name: 'ShivajiNagar' },
	{ wardId: 130, name: 'ShivajiNagar' },
	{ wardId: 131, name: 'ShivajiNagar' },
	{ wardId: 132, name: 'GandhiNagar' },
	{ wardId: 133, name: 'GandhiNagar' },
	{ wardId: 134, name: 'GandhiNagar' },
	{ wardId: 135, name: 'GandhiNagar' },
	{ wardId: 136, name: 'GandhiNagar' },
	{ wardId: 137, name: 'GandhiNagar' },
	{ wardId: 138, name: 'GandhiNagar' },
	{ wardId: 139, name: 'RajajiNagar' },
	{ wardId: 140, name: 'RajajiNagar' },
	{ wardId: 141, name: 'RajajiNagar' },
	{ wardId: 142, name: 'RajajiNagar' },
	{ wardId: 143, name: 'RajajiNagar' },
	{ wardId: 144, name: 'RajajiNagar' },
	{ wardId: 145, name: 'RajajiNagar' },
	{ wardId: 146, name: 'RajajiNagar' },
	{ wardId: 147, name: 'RajajiNagar' },
	{ wardId: 148, name: 'RajajiNagar' },
	{ wardId: 149, name: 'GovindarajNagar' },
	{ wardId: 150, name: 'GovindarajNagar' },
	{ wardId: 151, name: 'GovindarajNagar' },
	{ wardId: 152, name: 'GovindarajNagar' },
	{ wardId: 153, name: 'GovindarajNagar' },
	{ wardId: 154, name: 'GovindarajNagar' },
	{ wardId: 155, name: 'GovindarajNagar' },
	{ wardId: 156, name: 'VijayaNagar' },
	{ wardId: 157, name: 'VijayaNagar' },
	{ wardId: 158, name: 'VijayaNagar' },
	{ wardId: 159, name: 'VijayaNagar' },
	{ wardId: 160, name: 'VijayaNagar' },
	{ wardId: 161, name: 'VijayaNagar' },
	{ wardId: 162, name: 'VijayaNagar' },
	{ wardId: 163, name: 'VijayaNagar' },
	{ wardId: 164, name: 'VijayaNagar' },
	{ wardId: 165, name: 'VijayaNagar' },
	{ wardId: 166, name: 'VijayaNagar' },
	{ wardId: 167, name: 'VijayaNagar' },
	{ wardId: 168, name: 'Chamarajpet' },
	{ wardId: 169, name: 'Chamarajpet' },
	{ wardId: 170, name: 'Chamarajpet' },
	{ wardId: 171, name: 'Chickpet' },
	{ wardId: 172, name: 'Chickpet' },
	{ wardId: 173, name: 'Chickpet' },
	{ wardId: 174, name: 'Chickpet' },
	{ wardId: 175, name: 'Chickpet' },
	{ wardId: 176, name: 'Chickpet' },
	{ wardId: 177, name: 'Chickpet' },
	{ wardId: 178, name: 'Chickpet' },
	{ wardId: 179, name: 'ShanthiNagar' },
	{ wardId: 180, name: 'ShanthiNagar' },
	{ wardId: 181, name: 'ShanthiNagar' },
	{ wardId: 182, name: 'ShanthiNagar' },
	{ wardId: 183, name: 'ShanthiNagar' },
	{ wardId: 184, name: 'ShanthiNagar' },
	{ wardId: 185, name: 'ShanthiNagar' },
	{ wardId: 186, name: 'ShanthiNagar' },
	{ wardId: 187, name: 'ShanthiNagar' },
	{ wardId: 188, name: 'BTM Layout' },
	{ wardId: 189, name: 'BTM Layout' },
	{ wardId: 190, name: 'BTM Layout' },
	{ wardId: 191, name: 'BTM Layout' },
	{ wardId: 192, name: 'BTM Layout' },
	{ wardId: 193, name: 'BTM Layout' },
	{ wardId: 194, name: 'BTM Layout' },
	{ wardId: 195, name: 'BTM Layout' },
	{ wardId: 196, name: 'BTM Layout' },
	{ wardId: 197, name: 'BTM Layout' },
	{ wardId: 198, name: 'BTM Layout' },
	{ wardId: 199, name: 'BTM Layout' },
	{ wardId: 200, name: 'BTM Layout' },
	{ wardId: 201, name: 'BTM Layout' },
	{ wardId: 202, name: 'BTM Layout' },
	{ wardId: 203, name: 'BTM Layout' },
	{ wardId: 204, name: 'BTM Layout' },
	{ wardId: 205, name: 'BTM Layout' },
	{ wardId: 206, name: 'BTM Layout' },
	{ wardId: 207, name: 'BTM Layout' },
	{ wardId: 209, name: 'Banashankari' },
	{ wardId: 210, name: 'Basavanagudi' },
	{ wardId: 211, name: 'Basavanagudi' },
	{ wardId: 212, name: 'Basavanagudi' },
	{ wardId: 213, name: 'Basavanagudi' },
	{ wardId: 214, name: 'Basavanagudi' },
	{ wardId: 215, name: 'Basavanagudi' },
	{ wardId: 216, name: 'Basavanagudi' },
	{ wardId: 217, name: 'KanakapuraRoad' },
	{ wardId: 218, name: 'KanakapuraRoad' },
	{ wardId: 219, name: 'KanakapuraRoad' },
	{ wardId: 220, name: 'KanakapuraRoad' },
	{ wardId: 221, name: 'KanakapuraRoad' },
	{ wardId: 222, name: 'KanakapuraRoad' },
	{ wardId: 223, name: 'KanakapuraRoad' },
	{ wardId: 224, name: 'KanakapuraRoad' },
	{ wardId: 225, name: 'KanakapuraRoad' },
	{ wardId: 226, name: 'KanakapuraRoad' },
	{ wardId: 227, name: 'KanakapuraRoad' },
	{ wardId: 228, name: 'KanakapuraRoad' },
	{ wardId: 229, name: 'HSR Zone' },
	{ wardId: 230, name: 'HSR Zone' },
	{ wardId: 231, name: 'HSR Zone' },
	{ wardId: 232, name: 'HSR Zone' },
	{ wardId: 233, name: 'HSR Zone' },
	{ wardId: 234, name: 'HSR Zone' },
	{ wardId: 235, name: 'HSR Zone' },
	{ wardId: 236, name: 'HSR Zone' },
	{ wardId: 237, name: 'HSR Zone' },
	{ wardId: 238, name: 'HSR Zone' },
	{ wardId: 239, name: 'HSR Zone' },
	{ wardId: 240, name: 'HSR Zone' },
	{ wardId: 241, name: 'Bannerghatta Road' },
	{ wardId: 242, name: 'Bannerghatta Road' },
	{ wardId: 243, name: '' },

	{ wardId: 0, name: 'Unknown' },
	{ wardId: 999, name: 'Others' },
];

export type CommunityType = {
	id: number;
	name: string;
};

export const CommunityTypeList = [
	{ id: 1, name: 'Villa' },
	{ id: 2, name: 'Independent House' },
	{ id: 3, name: 'Apartment' },
	{ id: 4, name: 'Layout' },
];

export function getZoneNameForWardId(id: number): string | null {
	var match = ZoneList.findIndex(e => e.wardId === id);
	if (match > -1) return ZoneList[match].name;
	return null;
}

export function getDisplayNameForWardId(id: number): string | null {
	var match = WardList.findIndex(e => e.id === id);
	if (match > -1) return WardList[match].displayName;
	return null;
}

export function getWardNameForWardId(id: number): string | null {
	var match = WardList.findIndex(e => e.id === id);
	if (match > -1) return WardList[match].wardName;
	return null;
}

export function getDesignationNameForId(id: number): string | null {
	var match = DesignationList.findIndex(e => e.id === id);
	if (match > -1) return DesignationList[match].name;
	return null;
}
