import { MDBInput } from 'mdbreact';
import { Component } from 'react';
import { Form } from 'react-bootstrap';
import Log from '../../common/util/log';
import { Group } from '../../controller/do';
import { FormBloc, FormInputField, ValueHandler } from '../../controller/form_bloc';
import { MapStreamBuilder } from '../../provider';

const TAG = 'add-group';

export class AddGroup extends Component<{ formBloc: FormBloc<Group.Type> }> {
	render() {
		return (
			<>
				<Form>
					<h2
						style={{
							color: '#DC2E20',
							fontSize: '1.5rem',
							margin: '12px',
						}}
					>
						Add Group
					</h2>
					<Form.Group className="mb-3" controlId="formBasicEmail">
						<FormInputField
							controller={this.props.formBloc}
							objectKey="name"
							builder={(
								value: string | undefined,
								onChange: ValueHandler<string>,
								validationError: string | undefined
							) => {
								return (
									<>
										<MDBInput
											label="GROUP NAME"
											type="text"
											value={value}
											getValue={e => onChange(e as string)}
											outline
										/>
										<Form.Text style={{ color: '#DC2E20' }}>{validationError}</Form.Text>
									</>
								);
							}}
						/>
					</Form.Group>
					<MapStreamBuilder
						stream={this.props.formBloc.submitEnabled}
						map={(data: boolean) => (data ? '' : 'disabled')}
						builder={(data: string) => {
							return (
								<>
									<div className="d-grid mt-3">
										<a
											style={{
												backgroundColor: '#DB2A1D',
												color: 'white',
												padding: '6px',
											}}
											className={`btn button ${data}`}
											onClick={this.props.formBloc.onSubmit}
										>
											Save
										</a>
									</div>
								</>
							);
						}}
					/>
				</Form>
			</>
		);
	}
}

export default AddGroup;
