import React, { Component } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { MDBInput } from 'mdbreact';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { Form } from 'react-bootstrap';
import Log from '../../common/util/log';
import { Contact, Group, User } from '../../controller/do';
import { FormBloc, FormInputField, ValueHandler } from '../../controller/form_bloc';
import { BlocProvider, MapStreamBuilder } from '../../provider';
import { Tabular } from '../../controller';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
const TAG = 'single contact';

export class SingleContact extends Component<{ formBloc: FormBloc<Contact.Type>; groupList: Group.Type[] }> {
	get bloc(): FormBloc<Contact.Type> {
		return this.props.formBloc;
	}
	render() {
		return (
			<>
				<Form>
					<FormInputField
						controller={this.bloc}
						objectKey="name"
						builder={(
							value: string | undefined,
							onChange: ValueHandler<string>,
							validationError: string | undefined,
							isValidationError: boolean
						) => {
							return (
								<>
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<MDBInput
											label="CONTACT NAME"
											type="email"
											outline
											value={value}
											getValue={e => onChange(e.toString())}
										/>
										<Form.Text
											style={{
												color: '#DC2E20',
												visibility: isValidationError ? 'visible' : 'hidden',
											}}
										>
											{validationError}
										</Form.Text>
									</Form.Group>
								</>
							);
						}}
					/>

					<Form.Group className="mb-3" controlId="formBasicEmail">
						<FormInputField
							controller={this.bloc}
							objectKey="mobile_number"
							builder={(
								value: string | undefined,
								onChange: ValueHandler<string>,
								validationError: string | undefined
							) => {
								return (
									<>
										<MDBInput
											label="MOBILE NUMBER"
											type="number"
											outline
											disabled={this.bloc.formValue.value?.id ? true : false}
											value={value}
											getValue={e => onChange(e.toString())}
										/>
										<Form.Text style={{ color: '#DC2E20' }}>{validationError}</Form.Text>
									</>
								);
							}}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="formBasicEmail">
						<FormInputField
							controller={this.bloc}
							objectKey="salutation"
							builder={(
								value: string | undefined,
								onChange: ValueHandler<string>,
								validationError: string | undefined,
								isValidationError: boolean
							) => {
								return (
									<>
										<Form.Group className="mb-3" controlId="formBasicEmail">
											<MDBInput
												label="Salutaion"
												outline
												value={value}
												getValue={e => onChange(e.toString())}
											/>
											<Form.Text>Ex: Mr. Stan, Mrs. Lee</Form.Text>
											<Form.Text
												style={{
													color: '#DC2E20',
													visibility: isValidationError ? 'visible' : 'hidden',
												}}
											>
												{validationError}
											</Form.Text>
										</Form.Group>
									</>
								);
							}}
						/>
					</Form.Group>

					{/* TODO autocomplete is overflowing */}

					<FormInputField
						controller={this.bloc}
						objectKey="group_ids"
						builder={(value: number[] | undefined, onChange: ValueHandler<[]>) => {
							return (
								<>
									<div>
										<Autocomplete
											onChange={(_: any, value: any) => onChange(value.map((e: any) => e.id))}
											multiple
											id="tags-outlined"
											value={this.props.groupList.filter(e => value?.find(f => f === e.id))}
											options={this.props.groupList}
											getOptionLabel={option => option.name}
											filterSelectedOptions
											renderTags={(tagValue, getTagProps) => (
												<>
													{tagValue.map((option, index) => (
														<Chip label={option.name} {...getTagProps({ index })} />
													))}
												</>
											)}
											renderInput={params => (
												<TextField
													{...params}
													variant="outlined"
													label="Select Group"
													placeholder="Select Group"
												/>
											)}
										/>
									</div>
								</>
							);
						}}
					/>

					<div style={{ width: '100%' }} className="d-grid text-center mt-3">
						<MapStreamBuilder
							stream={this.props.formBloc.submitEnabled}
							map={e => (e ? '' : 'disabled')}
							builder={(cls: string) => {
								return (
									<>
										<a
											onClick={this.props.formBloc.onSubmit}
											style={{
												backgroundColor: '#DB2A1D',
												color: 'white',
												padding: '9px',
											}}
											className={`btn button ${cls}`}
										>
											Save
										</a>
									</>
								);
							}}
						/>
					</div>
				</Form>
			</>
		);
	}
}
