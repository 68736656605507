import { Component } from 'react';

import { MDBInput } from 'mdbreact';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormBloc, FormInputField, ValueHandler } from '../../controller/form_bloc';
import { MapStreamBuilder, StreamBuilder, withContext } from '../../provider';
import { Navigation } from '../../controller';

export class ForgetPassForm extends Component<{ formBloc: FormBloc<{ email: string }>; navigation: Navigation.Bloc }> {
	render() {
		return (
			<>
				<Form>
					<Form.Group className="m-2" controlId="formBasicEmail">
						<FormInputField
							controller={this.props.formBloc}
							objectKey="email"
							builder={(
								value: string | undefined,
								onChange: ValueHandler<string>,
								validationError: string | undefined
							) => {
								return (
									<>
										<MDBInput
											label="EMAIL"
											type="email"
											outline
											getValue={e => onChange(e as string)}
											value={value}
										/>

										<Form.Text style={{ color: '#DC2E20' }} className="pb-3">
											{validationError}
										</Form.Text>
									</>
								);
							}}
						/>
					</Form.Group>

					<div className="d-grid gap-2">
						<MapStreamBuilder
							stream={this.props.formBloc.submitEnabled}
							map={e => (e ? '' : 'disabled')}
							builder={(cls: string) => {
								return (
									<>
										<a
											onClick={this.props.formBloc.onSubmit}
											style={{
												backgroundColor: '#DB2A1D',
												color: 'white',
											}}
											className={`btn button ${cls}`}
										>
											Request
										</a>
									</>
								);
							}}
						/>
						<a
							className="button"
							style={{
								color: '#DB2A1D',
								placeSelf: 'center',
							}}
							onClick={this.props.navigation.onBackToLogin}
						>
							Back To Login
						</a>
					</div>
				</Form>
			</>
		);
	}
}
