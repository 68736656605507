import React, { Component } from 'react';
import { MDBCard, MDBCardBody, MDBCardText, MDBTooltip } from 'mdbreact';
import autoBind from 'auto-bind';
import { CentralMessage } from '../../controller/do';
import Tooltip from '@material-ui/core/Tooltip';
import { ChipComponent } from '../common/ChipComponent';
export class TruncatedText extends Component<{ text: string }, { currentText: string; isTruncated: boolean }> {
	private canTruncate: boolean;
	constructor(props: any) {
		super(props);
		autoBind(this);

		this.canTruncate = this.props.text.length > 200;
		this.state = {
			isTruncated: this.canTruncate,
			currentText: this.canTruncate ? this.props.text.substring(0, 200) : this.props.text,
		};
	}

	onTruncClicked(e: React.SyntheticEvent) {
		this.setState({
			isTruncated: !this.state.isTruncated,
			currentText: this.state.isTruncated ? this.props.text : this.props.text.substring(0, 200),
		});
		e.stopPropagation();
	}

	componentDidUpdate(prevProps: any) {
		if (prevProps !== this.props) {
			this.canTruncate = this.props.text.length > 200;
			this.setState({
				isTruncated: this.canTruncate,
				currentText: this.canTruncate ? this.props.text.substring(0, 200) : this.props.text,
			});
		}
	}

	render() {
		return (
			<>
				<span>{this.state.currentText}</span>
				{this.canTruncate ? (
					<span style={{ color: '#dc2e20', cursor: 'pointer' }} onClick={this.onTruncClicked}>
						{this.state.isTruncated ? ' ... read more' : ' - read less'}
					</span>
				) : (
					<></>
				)}
			</>
		);
	}
}

export class MessageCard extends Component<{
	onEdit?: () => void;
	onDelete?: () => void;
	data: CentralMessage.Type;
	border: string;
	className?: string;
	title?: boolean;
}> {
	render() {
		let deleteBtn = this.props.onDelete ? (
			<div onClick={this.props.onDelete}>
				{' '}
				<img src="/delete_black.png" />{' '}
			</div>
		) : (
			<div />
		);
		let editBtn = this.props.onEdit ? (
			<div onClick={this.props.onEdit}>
				{' '}
				<img className="ml-2" src="/Path.png" />{' '}
			</div>
		) : (
			<div />
		);
		let attachmentChip = (
			<>
				{' '}
				{this.props.data.attachmentList.map(e => (
					<ChipComponent attachment={e} />
				))}{' '}
			</>
		);
		return (
			<>
				<MDBCard className={this.props.className} style={{ width: '100%', border: `${this.props.border}` }}>
					<MDBCardBody>
						<div className="d-flex">
							<div>
								<div className="message-date">
									<p style={{ margin: '0', padding: '0' }}>{this.props.data.created_at}</p>{' '}
								</div>
								<div className="message-title">
									{this.props.title && (
										<span style={{ marginLeft: '0', paddingTop: '0' }}>
											{' '}
											{this.props.data.title}{' '}
										</span>
									)}
								</div>
							</div>
							<div style={{ marginLeft: 'auto' }}>
								{deleteBtn}
								{editBtn}
								{attachmentChip}
							</div>
						</div>
						<MDBCardText>
							<div className="message-text">
								<TruncatedText text={this.props.data.message} />
							</div>
						</MDBCardText>
					</MDBCardBody>
				</MDBCard>
			</>
		);
	}
}

export default MessageCard;
