import { Component } from 'react';
import { Route } from 'react-router-dom';
import { Container, FormControl, InputGroup } from 'react-bootstrap';
import { AppContext, AppDep, StreamBuilder } from './../provider';
import { Store, Navigation } from './../controller';
import { Subscription, Observable } from 'rxjs';
import Log from './../common/util/log';

import SentMessage from '../components/main/SentMessage';
import SendMessage from '../components/main/SendMessage';
import { Contacts } from '../components/main/Contacts';
import { ManageMessage } from '../components/main/ManageMessage';
import CreateUser from '../components/main/CreateUser';
import Analytics from '../components/main/Analytics';
import FabButton from './FabButton';
import { SearchBoxQuery } from '../controller';

import { MDBIcon, MDBTooltip } from 'mdbreact';

import '../css/style.css';
import '../css/form.css';

import ProfilePage from '../components/ProfilePage';

import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import NavBrand from '../components/common/NavBrand';
import TemporaryDrawer from './SideNav';
import autoBind from 'auto-bind';
import LoginPage from '../components/LoginPage';
import ForgotPassowrdPage from './../../src/components/ForgotPassPage';
import { AcceptInvitationPage, ResetPasswordPage } from '../components/AcceptInvitationPage';
import { SearchBox } from '../components/common/TableData';
import { AutoLogout } from './AutoLogout';
import { DesignCredits } from '../components/common/DesignCredits';
import { AboutUs } from '../components/main/AboutUs';
const TAG = 'HomeLayout';

class ConditionedWidget extends Component<{ observableSubject: Observable<any>; init: boolean }, { show: boolean }> {
	private _subscription?: Subscription;

	constructor(props: any) {
		super(props);
		this.state = { show: this.props.init };
	}

	componentDidMount() {
		this._subscription = this.props.observableSubject.subscribe(e => {
			Log.d(TAG, 'something');
			this.setState({ show: e !== null });
		});
	}

	componentWillUnmount() {
		this._subscription?.unsubscribe();
	}

	render() {
		if (this.state?.show) return <>{this.props.children}</>;
		return <></>;
	}
}

export class PageNavigationTab extends Component<{
	navigation: Navigation.Bloc;
	store: Store.Bloc;
}> {
	private _subscription?: Subscription;

	constructor(props: any) {
		super(props);
		autoBind(this);
	}

	componentDidMount() {
		this._subscription = this.props.navigation.currentPath.subscribe(() => this.setState({}));
	}

	componentWillUnmount() {
		this._subscription?.unsubscribe();
	}

	render() {
		return (
			<>
				<StreamBuilder
					stream={this.props.store.authData}
					builder={(data: Store.AuthData | null) => {
						let pathList = data?.user.permissions?.is_admin
							? Navigation.AdminPathList
							: Navigation.PathList;
						return (
							<>
								{pathList.map((e: any) => {
									return (
										<>
											<div
												className={`${
													this.props.navigation.currentPath.value === e.path
														? 'pagination-tab-link-active'
														: 'pagination-tab-link'
												}  `}
												onClick={() => this.props.navigation.onPathButtonClicked(e.path)}
											>
												<p>{e.name}</p>
											</div>
										</>
									);
								})}
							</>
						);
					}}
				/>
			</>
		);
	}
}

export class HomeLayout extends Component {
	render() {
		return (
			<>
				<AppContext.Consumer>
					{(context: AppDep) => {
						return (
							<div className="d-flex flex-column min-vh-100">
								<AppLayout
									webLogout={
										<ConditionedWidget observableSubject={context.store.authData} init={false}>
											<AutoLogout timeout={10 * 60 * 1000} />
											<MDBTooltip domElement tag="span" placement="bottom">
												<div
													className="m-2 logout-button"
													style={{ cursor: 'pointer' }}
													onClick={context.navigation.onLogoutClicked}
												>
													<img src="/logout.png" alt="logout" />
												</div>
												<span>Logout </span>
											</MDBTooltip>
										</ConditionedWidget>
									}
									webAccount={
										<ConditionedWidget observableSubject={context.store.authData} init={false}>
											<MDBTooltip domElement tag="span" placement="top">
												<div onClick={context.navigation.onAccountsClicked}>
													<Nav.Link
														className="profile-button mr-3"
														style={{
															border: '1px solid #d4d4d4',
															borderRadius: '23px',
															paddingRight: '16px',
															paddingLeft: '16px',
														}}
													>
														<span className="m-3">
															<img src="/person.png" alt="image" />
														</span>
													</Nav.Link>
												</div>
												<span>Account </span>
											</MDBTooltip>
										</ConditionedWidget>
									}
									mobileLogout={
										<ConditionedWidget observableSubject={context.store.authData} init={false}>
											<a
												style={{ textDecoration: 'none', color: 'grey' }}
												onClick={context.navigation.onLogoutClicked}
											>
												Logout
											</a>
										</ConditionedWidget>
									}
									mobileAccount={
										<ConditionedWidget observableSubject={context.store.authData} init={false}>
											<a
												style={{ textDecoration: 'none', color: 'grey' }}
												onClick={context.navigation.onAccountsClicked}
											>
												Account
											</a>
										</ConditionedWidget>
									}
									paginationTabWeb={
										<ConditionedWidget observableSubject={context.store.authData} init={false}>
											<div
												className="routes-content mt-4"
												style={{
													display: 'flex',
													alignItems: 'flex-start',
												}}
											>
												<PageNavigationTab
													store={context.store}
													navigation={context.navigation}
												/>
											</div>
										</ConditionedWidget>
									}
									paginationTabMobile={
										<ConditionedWidget observableSubject={context.store.authData} init={false}>
											<PageNavigationTab store={context.store} navigation={context.navigation} />
										</ConditionedWidget>
									}
									routes={
										<>
											<Route exact path={Navigation.Path.Root} component={LoginPage} />
											<Route
												exact
												path={Navigation.Path.ForgotPassowrd}
												component={ForgotPassowrdPage}
											/>
											<Route exact path={Navigation.Path.SendMessage} component={SendMessage} />
											<Route exact path={Navigation.Path.SentMessages} component={SentMessage} />
											<Route exact path={Navigation.Path.Contacts} component={Contacts} />
											<Route
												exact
												path={Navigation.Path.ManageMessages}
												component={ManageMessage}
											/>
											<Route exact path={Navigation.Path.ManageUser} component={CreateUser} />
											<Route exact path={Navigation.Path.Analytics} component={Analytics} />
											<Route exact path={Navigation.Path.Accounts} component={ProfilePage} />
											<Route
												exact
												path={Navigation.Path.AcceptInvitation}
												component={AcceptInvitationPage}
											/>
											<Route
												exact
												path={Navigation.Path.GenerateNewPassword}
												component={ResetPasswordPage}
											/>
										</>
									}
								/>
								<div className="mt-auto">
									<DesignCredits />
								</div>
							</div>
						);
					}}
				</AppContext.Consumer>
			</>
		);
	}
}

class AppLayout extends Component<
	{
		webAccount: JSX.Element;
		mobileAccount: JSX.Element;
		webLogout: JSX.Element;
		mobileLogout: JSX.Element;
		paginationTabMobile: JSX.Element;
		paginationTabWeb: JSX.Element;
		routes: JSX.Element;
	},
	{}
> {
	render() {
		return (
			<>
				<Navbar className="header" expand="lg">
					<Container>
						<div className="navbar-header-brand">
							<TemporaryDrawer
								paginationTab={this.props.paginationTabMobile}
								mobileLogout={this.props.mobileLogout}
								mobileAccount={this.props.mobileAccount}
							/>
							<NavBrand />
						</div>
						<Navbar.Collapse id="basic-navbar-nav " className="justify-content-end ">
							<Nav className="ml-auto">
								{this.props.webLogout}
								{this.props.webAccount}
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
				<div className="container">{this.props.paginationTabWeb}</div>
				<main>
					<Container>{this.props.routes}</Container>
				</main>
			</>
		);
	}
}

export default HomeLayout;
