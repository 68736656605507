import { Component } from 'react';
import { StreamBuilder } from '../../src/provider';
import { BehaviorSubject, map } from 'rxjs';
import autoBind from 'auto-bind';
import validate from 'validate.js';

import Log from './../../src/common/util/log';
const TAG = 'form-controller';

export type ValueHandler<T> = (value: T | undefined | null) => void;
export class FormBloc<T> {
	public readonly formValue: BehaviorSubject<T | undefined> = new BehaviorSubject(undefined) as BehaviorSubject<
		T | undefined
	>;
	public readonly validationError: BehaviorSubject<{ [key in keyof T]?: [string] } | undefined> = new BehaviorSubject(
		{}
	) as BehaviorSubject<{ [key in keyof T]?: [string] } | undefined>;
	public readonly submissionError: BehaviorSubject<string | undefined> = new BehaviorSubject(
		undefined
	) as BehaviorSubject<string | undefined>;
	public readonly submitEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false) as BehaviorSubject<boolean>;

	// TODO: Ideally, the type of _validationConstraints should be something like {[key in keyof T]: any}
	private _validationConstraints: any;

	private _onValidatedListener: (value: T) => Promise<void>;

	private readonly _defaultValue: T | undefined;
	constructor(validationConstraints: any, onValidatedListener: (value: T) => Promise<void>, defaultValue?: T) {
		autoBind(this);
		this._validationConstraints = validationConstraints;
		this._onValidatedListener = onValidatedListener;
		this._defaultValue = defaultValue;
		this.formValue.next(this._defaultValue);
		this.formValue.pipe(map(e => !!e)).subscribe(this.submitEnabled);
	}

	public setInputValue<K extends keyof T>(value: T[K] | undefined | null, key: keyof T) {
		Log.d(TAG, 'inside setInputValue', value, key);
		let newVal: T = {
			...(this.formValue.value as T),
		};
		if (value !== null && value !== undefined) newVal[key] = value;
		else delete newVal[key];
		this.formValue.next(newVal);
		this.submitEnabled.next(true);
	}

	public setObjectValue(value: T | undefined) {
		Log.d(TAG, 'inside setObjectValue', value);
		this.validationError.next(undefined);
		this.submissionError.next(undefined);
		this.submitEnabled.next(value ? true : false);
		this.formValue.next(value);
	}

	public clearAll(): void {
		Log.d(TAG, 'inside clearAll');
		this.setObjectValue(this._defaultValue);
	}

	public onSubmit(): void {
		Log.d(TAG, 'inside onSubmit', this.formValue.value);
		let valResult;
		this.submitEnabled.next(false);
		if (this._validationConstraints) {
			valResult = validate(this.formValue.value, this._validationConstraints);
			Log.d(TAG, 'validation-check', valResult);
		}
		this.validationError.next(valResult);
		if (!valResult)
			this._onValidatedListener(this.formValue.value as T)
				.catch(e => this.submissionError.next(JSON.stringify(e)))
				.finally(() => {
					this.submitEnabled.next(!!this.formValue.value);
				});
		else {
			this.submitEnabled.next(!!this.formValue.value);
		}
	}
}

type FormInputFieldBuilder<T, K extends keyof T> = (
	value: T[K] | undefined,
	onChange: ValueHandler<T[K]>,
	validationError: string | undefined,
	isValidationError: boolean
) => JSX.Element;

export class FormInputField<T, K extends keyof T> extends Component<
	{
		controller: FormBloc<T>;
		objectKey: K;
		builder: FormInputFieldBuilder<T, K>;
	},
	{}
> {
	constructor(props: any) {
		super(props);
		autoBind(this);
	}

	render() {
		return (
			<>
				<StreamBuilder
					stream={this.props.controller.validationError}
					builder={(error: { [key in keyof T]?: [string] } | undefined) => {
						return (
							<>
								<StreamBuilder
									stream={this.props.controller.formValue}
									builder={(data: T | undefined) => {
										return (
											<>
												{this.props.builder(
													data?.[this.props.objectKey],
													e => this.props.controller.setInputValue(e, this.props.objectKey),
													error?.[this.props.objectKey]?.join(' / '),
													error?.[this.props.objectKey] ? true : false
												)}
											</>
										);
									}}
								/>
							</>
						);
					}}
				/>
			</>
		);
	}
}
