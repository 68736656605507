import React from 'react';

export function HintComponent(props: { hint: string }) {
	return (
		<div style={{ width: '100%', backgroundColor: '#FBE3E3' }} className="p-3 mb-3">
			<div
				style={{
					color: '#DC2E20',
					fontSize: '.9rem',
					textAlign: 'center',
				}}
			>
				{props.hint}
			</div>
		</div>
	);
}
