import { MDBIcon } from 'mdbreact';
import { Component } from 'react';
import { Form } from 'react-bootstrap';
import Log from '../../common/util/log';
import { FormBloc, FormInputField, ValueHandler } from '../../controller/form_bloc';
import { MapStreamBuilder } from '../../provider';

const TAG = 'bulk-contact';

export class BulkInput extends Component<{ formBloc: FormBloc<{ file: File }>; templatePath: string }> {
	render() {
		return (
			<>
				<Form>
					<div
						style={{
							width: '100%',
							height: '100%',
							border: '1px solid grey',
						}}
					>
						<div className="fileUpload">
							<FormInputField
								controller={this.props.formBloc}
								objectKey="file"
								builder={(value: File | undefined, onChange: ValueHandler<File>) => {
									return (
										<>
											<input
												type="file"
												onChange={e => onChange(e.target.files?.[0])}
												className="upload"
												accept=".csv"
											/>
										</>
									);
								}}
							/>
							<span>
								<MDBIcon icon="upload" /> Upload
							</span>
						</div>
					</div>
					<div className="text-center m-3" style={{ textDecoration: 'underline' }}>
						{/* TODO: This anchor appears blue in the UI and looks very bad. Fix it */}
						<a href={this.props.templatePath}>Download template</a>
					</div>
					<div className="mt-3 d-grid text-center">
						<MapStreamBuilder
							stream={this.props.formBloc.submitEnabled}
							map={e => (e ? '' : 'disabled')}
							builder={(cls: string) => {
								return (
									<>
										<a
											onClick={this.props.formBloc.onSubmit}
											style={{
												backgroundColor: '#DB2A1D',
												color: 'white',
												padding: '6px',
											}}
											className={`btn button ${cls}`}
										>
											Upload
										</a>
									</>
								);
							}}
						/>
					</div>
				</Form>
			</>
		);
	}
}
