import { Component } from 'react';
import ProfileForm from './formComponent/ProfileForm';
import { Col } from 'react-bootstrap';
import TabLayout, { TabPage } from '../layout/TabLayout';
import SecurityForm from './formComponent/SecurityForm';
import { FormBloc } from '../controller/form_bloc';
import { Credentials, User } from '../controller/do';
import { GlobalContext } from '../common/modal_boundary';
import Log from '../common/util/log';
import { AppDep, BlocProvider } from '../provider';
import autoBind from 'auto-bind';
import { Store } from '../controller';
import { Backend } from '../controller/backend';

const TAG = 'profile-page';

class ProfileBloc {
	public profileFormBloc: FormBloc<User.Type>;

	private _api: Backend.Api;

	constructor(appDep: AppDep) {
		autoBind(this);
		this.profileFormBloc = new FormBloc(User.Constraints, this.onProfileDataUpdate);
		this._api = appDep.api;
		this.updateData();
	}

	@GlobalContext.handlErrorAsModal()
	async updateData(): Promise<void> {
		Log.d(TAG, 'inside updateData');
		let user = await this._api.getUser();
		this.profileFormBloc.setObjectValue(user);
	}

	@GlobalContext.handlErrorAsModal()
	@GlobalContext.handleSuccessAsModal(Promise, 'Profile details updated')
	async onProfileDataUpdate(data: User.Type): Promise<void> {
		Log.d(TAG, 'inside onProfileDataUpdate');
		await this._api.updateProfile(data);
		await this.updateData();
	}
}

type PasswordUpdateData = { oldPassword: string; newPassword: string; confirmationPassword: string };

class SecurityBloc {
	private _api: Backend.Api;
	public formBloc: FormBloc<PasswordUpdateData>;

	constructor(appDep: AppDep) {
		autoBind(this);
		this.formBloc = new FormBloc(
			{
				oldPassword: Credentials.Constraints.password,
				newPassword: Credentials.Constraints.password,
				confirmationPassword: {
					presence: {
						allowEmpty: false,
					},
					type: 'string',
					equality: {
						attribute: 'newPassword',
					},
				},
			},
			this.onPasswordUpdate
		);
		this._api = appDep.api;
	}

	@GlobalContext.handlErrorAsModal()
	@GlobalContext.handleSuccessAsModal(Promise, 'Password updated successfully')
	async onPasswordUpdate(value: PasswordUpdateData): Promise<void> {
		Log.d(TAG, 'inside onPasswordUpdate', value);
		await this._api.updateUserPassword(value.oldPassword, value.newPassword);
		this.formBloc.clearAll();
	}
}

export class AccountsPage extends Component {
	render() {
		return (
			<>
				<>
					<div className="m-3">
						<TabLayout
							children={[
								{
									name: 'Profile',
									child: (
										<Col md={9} style={{ margin: '0', padding: '0' }}>
											<BlocProvider
												create={(appDep: AppDep) => new ProfileBloc(appDep)}
												builder={(bloc: ProfileBloc) => {
													return <ProfileForm formBloc={bloc.profileFormBloc} />;
												}}
											/>
										</Col>
									),
								},
								{
									name: 'Security',
									child: (
										<Col md={4} style={{ margin: '0', padding: '0' }}>
											{' '}
											<BlocProvider
												create={(appDep: AppDep) => new SecurityBloc(appDep)}
												builder={(bloc: SecurityBloc) => {
													return (
														<>
															<SecurityForm formBloc={bloc.formBloc} />
														</>
													);
												}}
											/>
										</Col>
									),
								},
							]}
						/>
					</div>
				</>
			</>
		);
	}
}

export default AccountsPage;
