import { Component } from 'react';
import Log from '../../common/util/log';
import { BlocProvider, StreamBuilder, AppDep, StreamModal } from '../../../src/provider';
import { FormBloc } from '../../../src/controller/form_bloc';
import {
	SendMessageCampaign,
	CentralMessage,
	Group,
	PinnedMessage,
	Attachment,
	User,
} from '../../../src/controller/do';
import { Backend, getDisplayNameForWardId, getWardNameForWardId } from '../../../src/controller/backend';
import { MessageCard } from '../../../src/components/CardComponent/MessageCard';
import { BehaviorSubject, Subscription } from 'rxjs';

import SendMessageForm from '../formComponent/SendMessageForm';
import TableData from '../../../src/components/common/TableData';
import DualLayout, { DualLayoutBloc } from '../../layout/MobileLayout/DualLayout';
import autoBind from 'auto-bind';
import { MDBContainer, MDBTooltip } from 'mdbreact';
import { GlobalContext } from '../../common/modal_boundary';
import { TableDataBloc } from '../../controller/table_data_bloc';
import ModalComponent from '../common/ModalComponent';
import { ProcessingModalLayout, StatusModalLayout } from '../common/ProcessingModal';
import { centralMessageSorter, Store } from '../../controller';
import ModalComponentV2 from '../common/ModalComponentV2';
import { Button } from '../common/button';
import { QrCodeComponent, QrCodeModalBloc } from '../common/qr_code_modal';
import Mustache from 'mustache';

const TAG = 'SendMessage';

class Bloc {
	public formBloc: FormBloc<SendMessageCampaign.Type>;
	public readonly groupList: BehaviorSubject<Group.Type[]> = new BehaviorSubject(
		[] as Group.Type[]
	) as BehaviorSubject<Group.Type[]>;

	private _api: Backend.Api;
	public tableDataBloc: TableDataBloc<CentralMessage.Type>;
	public _qrCodeModalBloc: QrCodeModalBloc;
	private storeBloc: Store.Bloc;

	constructor(appDep: AppDep) {
		autoBind(this);
		this.formBloc = new FormBloc(SendMessageCampaign.Constraints, this.onValidated, {
			message: '',
			attachmentList: [] as Attachment[],
			groups: [] as number[],
		});
		this._api = appDep.api;
		this.tableDataBloc = new TableDataBloc(this.getCentralMessageList, appDep.searchBoxQuery, ['message', 'title']);
		this._api.getGroupData().then(e => this.groupList.next(e));
		this._qrCodeModalBloc = new QrCodeModalBloc(this._api);
		this.storeBloc = appDep.store;
	}

	@GlobalContext.handlErrorAsModal([])
	async getCentralMessageList(): Promise<CentralMessage.Type[]> {
		Log.d(TAG, 'inside getCentralMessageList');
		let centralMessageList = await this._api.getCentralMessages();
		let pinnedMessage = await this._api.getPinnedMessage();
		centralMessageList = centralMessageList
			.filter(e => !e.isArchived)
			.sort(centralMessageSorter(pinnedMessage.pin_message_id));
		return centralMessageList;
	}

	onCentralMessageClicked(item: CentralMessage.Type) {
		Log.d(TAG, 'inside onCentralMessageClicked', item);

		let user: User.Type = this.storeBloc.authData.value!.user;
		let ward_name: string = getWardNameForWardId(user.ward_id ?? -1) ?? '';
		let message = Mustache.render(item.message, { ...user, ward_name });

		this.formBloc.setObjectValue({
			groups: [],
			message: `\n${message}`,
			attachmentList: item.attachmentList,
		});
	}

	onStatusModalClose() {
		Log.d(TAG, 'inside onStatusModalClose');
		this._qrCodeModalBloc.modalVisibility.next(false);
	}

	@GlobalContext.handlErrorAsModal()
	@GlobalContext.handleSuccessAsModal(Promise, 'Message(s) sent successfully')
	async onValidated(value: SendMessageCampaign.Type): Promise<void> {
		this._qrCodeModalBloc.onModalOpen(undefined);
		this.formBloc.clearAll();
		try {
			if (value.groups.length === 1 && value.groups[0] === -1) await this.sendSelfTestMessage(value);
			else await this.sendCampaign(value);
		} catch (e) {
			this._qrCodeModalBloc.onModalClose();
			throw e;
		}
	}

	async sendCampaign(value: SendMessageCampaign.Type): Promise<void> {
		Log.d(TAG, 'inside sendCampaign', value);
		let valueWithFormateText = { ...value, message: value.message };
		let sessionId = await this._api.sendCampaign(valueWithFormateText);
		await this._qrCodeModalBloc.doQrCodeValidation(sessionId);
	}

	async sendSelfTestMessage(value: SendMessageCampaign.Type): Promise<void> {
		Log.d(TAG, 'inside sendSelfTestMessage', value);
		let valueWithFormateText = { ...value, message: value.message };
		let sessionId = await this._api.sendSelfTestMessage(valueWithFormateText);
		await this._qrCodeModalBloc.doQrCodeValidation(sessionId);
	}
}

const SendMessage = () => {
	return (
		<div className="m-2">
			<BlocProvider
				create={(appDep: AppDep) => new Bloc(appDep)}
				builder={(bloc: Bloc) => (
					<>
						<QrCodeComponent bloc={bloc._qrCodeModalBloc} />
						<DualLayout
							left={(dualLayoutBloc: DualLayoutBloc) => (
								<StreamBuilder
									stream={bloc.tableDataBloc.data}
									builder={(data: CentralMessage.Type[]) => {
										return (
											<div className="col-selector mt-2">
												<TableData
													deepFindSearch
													searchableFields={['data.message', 'data.title']}
													data={{
														columns: [{ label: 'Messages', field: 'message' }],
														rows: data.map(e => {
															return {
																data: {
																	...e,
																	created_at: e.created_at
																		? new Date(e.created_at).toLocaleString()
																		: '-',
																},
															};
														}),
													}}
													firstColumnBuilder={e => (
														<div
															onClick={() => {
																bloc.onCentralMessageClicked(e.data);
																dualLayoutBloc.onHaveData();
															}}
														>
															<>
																<MDBTooltip
																	domElement
																	tag="span"
																	material
																	placement="top"
																>
																	<div className="send-message-card">
																		<MessageCard
																			className={'MessageCard'}
																			data={e.data}
																			border={'2px solid  rgba(0,0,0,.125)'}
																			title
																		/>
																	</div>
																	<span>Click to select</span>
																</MDBTooltip>
															</>
														</div>
													)}
												/>
											</div>
										);
									}}
								/>
							)}
							right={(dualLayoutBloc: DualLayoutBloc) => (
								<StreamBuilder
									stream={bloc.groupList}
									builder={(data: Group.Type[]) => {
										return (
											<>
												<SendMessageForm
													formBloc={bloc.formBloc}
													groupList={data}
													onClose={dualLayoutBloc.onDataRemoved}
												/>
											</>
										);
									}}
								/>
							)}
						/>
					</>
				)}
			/>
		</div>
	);
};

export default SendMessage;
