import React from 'react';

export function DesignCredits() {
	return (
		<div
			className="mt-auto p-2"
			style={{
				textAlign: 'center',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			Maintained &amp; Designed By &nbsp;
			<a target="_blank" href="https://aimedlabs.com/?ref=blink">
				<img style={{ width: '90px' }} src="/Aimed-Labs.png" />
			</a>
		</div>
	);
}
