import autoBind from 'auto-bind';
import Log from '../common/util/log';
import { AxiosError } from 'axios';

export namespace User {
	export type Type = {
		id?: number;
		first_name: string;
		last_name?: string;
		email: string;
		mobile_number: string;
		community_name?: string;
		ward_id?: number;
		community_type_id?: number;
		// TODO: the designation_id & houses_count should not be optional should not be optional
		houses_count?: number;
		designation_id?: number;
		// TODO: Fix the actual type of permissions here
		permissions?: any;
		email_verified?: boolean;
		is_profile_active?: boolean;
		created_at?: string;
		updated_at?: string;
		bnp_whatsapp_group_link?: string;
	};

	export const Constraints = {
		first_name: {
			presence: {
				allowEmpty: false,
			},
			length: {
				minimum: 1,
				maximum: 200,
			},
		},
		last_name: {
			presence: {
				allowEmpty: false,
			},
			length: {
				minimum: 1,
				maximum: 200,
			},
		},
		email: {
			presence: {
				allowEmpty: false,
			},
			email: true,
		},
		designation_id: {
			presence: {
				allowEmpty: false,
			},
		},
		mobile_number: {
			presence: {
				allowEmpty: false,
			},
			length: {
				minimum: 10,
				maximum: 10,
			},
		},
		community_name: {
			presence: false,
			length: {
				maximum: 200,
			},
		},
		houses_count: {
			numericality: {
				onlyInteger: true,
				greaterThanOrEqualTo: 0,
			},
		},
		bnp_whatsapp_group_link: {
			presence: {
				allowEmpty: true,
			},
			url: true,
		},
	};
}

// TODO : Add following types
export type UserAnalytics = {
	id: number;
	first_name: string;
	last_name?: string;
	wardId: number;
	designationId: number;
	communityTypeId: number;
	communityName: string;
	housesCount: number;
	contactCount: number;
	messageTemplateCount: number;
	messageCount: number;
};

export type WardInfo = {
	wardId: number;
	zoneLeaderName?: string;
	wardLeaderName?: string;
};

export type Analytics = {
	wardInfoList: WardInfo[];
	userAnalyticsList: UserAnalytics[];
};

export namespace SendMessageCampaign {
	export type Type = {
		message: string;
		attachmentList: Attachment[];
		groups: number[];
	};

	export const Constraints = {
		// TODO: Is there a length constraint to messages ?
		message: {
			presence: {
				allowEmpty: false,
			},
		},
		groups: {
			type: 'array',
			presence: {
				allowEmpty: false,
			},
		},
	};
}

export namespace MessageCampaign {
	export type Type = {
		id: number;
		name: string;
		metadata: {
			message: string;
			type: string;
			group_ids?: number[];
			group_names?: string[];
		};
		total_count: number;
		processed_count: number;
		created_at: string;
	};
}

export type PinnedMessage = {
	pin_message_id?: number | null;
};

export type DesignationType = {
	ID: number;
	NAME: string;
};

export const DESIGNATION = {
	VOLUNTEER: {
		ID: 0,
		NAME: 'Volunteer',
	},

	ZONE_LEADER: {
		ID: 1,
		NAME: 'Zonal Leader',
	},

	WARD_LEADER: {
		ID: 2,
		NAME: 'Ward Leader',
	},

	AREA_SABHA_MEMBER: {
		ID: 3,
		NAME: 'Area Sabha Member',
	},

	AREA_SABHA_LEADER: {
		ID: 5,
		NAME: 'Area Sabha Leader',
	},

	SUPER_ADMIN: {
		ID: 4,
		NAME: 'Super Admin',
	},
};

export type Ward = {
	id: number;
	displayName: string;
	wardName: string;
};

export namespace Group {
	export const Constraints = {
		name: {
			presence: {
				allowEmpty: false,
			},
			length: {
				minimum: 3,
				maximum: 100,
			},
		},
	};

	export type Type = {
		id?: number;
		name: string;
		contacts_count?: number;
	};
}

export namespace Contact {
	export const Constraints = {
		salutation: {
			presence: {
				allowEmpty: false,
			},
			length: {
				minimum: 3,
				maximum: 200,
			},
		},
		mobile_number: {
			presence: {
				allowEmpty: false,
			},
			length: {
				is: 10,
			},
		},
		name: {
			presence: {
				allowEmpty: false,
			},
			length: {
				minimum: 3,
				maximum: 200,
			},
		},
	};

	export type Type = {
		id?: number;
		name: string;
		mobile_number: string;
		salutation: string;
		groups: string;
		group_ids: number[];
	};
}

export namespace CentralMessage {
	export type Type = {
		id?: number;
		created_at?: string;
		message: string;
		title: string;
		attachmentList: Attachment[];
		isArchived: boolean;
		ward_id?: number;
	};

	export const Constraints = {
		message: {
			presence: true,
			length: {
				minimum: 1,
				maximum: 10000,
			},
		},
		title: {
			presence: true,
			length: {
				minimum: 1,
				maximum: 100,
			},
		},
	};
}

export namespace Credentials {
	export const Constraints = {
		password: {
			presence: {
				allowEmpty: false,
			},
			length: {
				minimum: 8,
				maximum: 24,
			},
		},
		email: {
			presence: {
				allowEmpty: false,
			},
			email: true,
		},
	};
}

export namespace UserRemarksData {
	export type Type = {
		userId: number;
		remark_1: string;
		remark_2: string;
		remark_3: string;
		created_at?: string;
		updated_at?: string;
	};

	export const Constraints = {
		remark_1: {
			presence: true,
			length: {
				minimum: 0,
				maximum: 500,
			},
		},
		remark_2: {
			presence: true,
			length: {
				minimum: 0,
				maximum: 500,
			},
		},
		remark_3: {
			presence: true,
			length: {
				minimum: 0,
				maximum: 500,
			},
		},
	};
}

export type MessageDetails = {
	id: number;
	receiver_name: string;
	message: string;
	status: string;
	created_at: string;
	discard_reason?: string | null;
	discarded_at: string;
	delivered_at?: string;
};

export type EditableCentralMessage = CentralMessage.Type & { isPinned: boolean };

const TAG = 'do.ts';

export function getDesignationList(): DesignationType[] {
	return Object.values(DESIGNATION);
}

export function designationTypeFromId(id: number | undefined): DesignationType | undefined {
	Log.d(TAG, 'inside designationTypeFromId');
	return getDesignationList().find(e => e.ID === id);
}

export class ReadableError extends Error {
	public readonly readableMessage: string;

	constructor(error: any) {
		super();
		if (error instanceof ReadableError) {
			this.readableMessage = error.readableMessage;
		} else if (error.isAxiosError === true) {
			let err = error as AxiosError;
			if (err.response) {
				this.readableMessage = `Server responded with following error: ${err.response?.data?.statusMessage}`;
				let dataError =
					err.response?.data?.data ?? (err.response?.data?.errors as { msg: string }[]).map(c => c.msg);
				if (!!dataError)
					this.readableMessage = `${this.readableMessage}. The relevant error data is: ${JSON.stringify(
						dataError
					)}`;
			} else if (err.request) {
				this.readableMessage = `Failed to communicate with the server. Details: ${err} | ${err.toString()}`;
			} else {
				this.readableMessage = `Frontend config failed. Stack ${err} | ${err.toString} | ${err.stack}`;
			}
		} else if (error instanceof Error) {
			this.readableMessage = `Some error occurred. Error details are: ${error} | ${error.toString()} | ${JSON.stringify(
				error
			)}`;
		} else if (typeof error === 'string') {
			this.readableMessage = error as string;
		} else {
			this.readableMessage = 'Some unknown error occurred.';
		}
	}
}

export interface AttachmentVisitor<T> {
	visitOnCloudAttachment(attachment: OnCloudAttachment): T;
	visitLocalAttachment(attachment: LocalAttachment): T;
}

export interface Attachment {
	readonly name: string;
	readonly contentType: string;

	visit<T>(visitor: AttachmentVisitor<T>): T;
}

export class LocalAttachment implements Attachment {
	readonly name: string;
	readonly contentType: string;
	readonly file: File;

	constructor(file: File) {
		autoBind(this);
		this.name = file.name;
		this.contentType = file.type;
		this.file = file;
	}

	visit<T>(visitor: AttachmentVisitor<T>): T {
		return visitor.visitLocalAttachment(this);
	}
}

export class OnCloudAttachment implements Attachment {
	readonly name: string;
	readonly contentType: string;
	readonly key: string;

	constructor(key: string, name: string, contentType: string) {
		autoBind(this);
		this.key = key;
		this.name = name;
		this.contentType = contentType;
	}

	visit<T>(visitor: AttachmentVisitor<T>): T {
		return visitor.visitOnCloudAttachment(this);
	}
}
