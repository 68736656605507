import { BehaviorSubject, Subscription } from 'rxjs';
import autoBind from 'auto-bind';
import { SearchBoxQuery } from '../../src/controller/index';
import Log from '../../src/common/util/log';

const TAG = 'tabledata-bloc';

export class TableDataBloc<T extends { [key: string]: any }> {
	private _data: BehaviorSubject<T[]>;

	private _searchQuery: string;
	private _fetchMethod: () => Promise<T[]>;
	private _searchableFields: string[];

	private _subscription: Subscription;

	get data(): BehaviorSubject<T[]> {
		return this._data;
	}

	private _dataCache: T[] = [];

	constructor(fetchMethod: () => Promise<T[]>, searchQuery: SearchBoxQuery, searchableFields: string[]) {
		autoBind(this);
		this._searchQuery = '';
		this._data = new BehaviorSubject([] as T[]);
		this._subscription = searchQuery.searchQuery.subscribe(e => {
			this._searchQuery = e;
			this.updateSearchResult();
		});

		this._fetchMethod = fetchMethod;
		this._searchableFields = searchableFields;
		this.updateData();
	}

	destroy() {
		this._subscription.unsubscribe();
	}

	private updateSearchResult() {
		Log.d(TAG, 'inside applySearch: ' + this._searchQuery);
		if (this._searchQuery === '') {
			return this._data.next(this._dataCache);
		}

		let list = this._dataCache.filter((e: T) => {
			let match = false;
			this._searchableFields.forEach(f => {
				match = match || (e[f] && (e[f] as string).toLowerCase().includes(this._searchQuery.toLowerCase()));
			});
			return match;
		});

		return this._data.next(list);
	}

	public async updateData(): Promise<void> {
		Log.d(TAG, 'inside fetchData');
		this._dataCache = await this._fetchMethod();
		this._data.next(this._dataCache);
		this.updateSearchResult();
	}
}
