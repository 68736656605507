import React, { Component } from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBTooltip } from 'mdbreact';
import CreateMessageForm from '../formComponent/CreateMessageForm';
import { useMediaQuery } from 'react-responsive';
import DualLayout, { DualLayoutBloc } from '../../layout/MobileLayout/DualLayout';
import { CentralMessage, EditableCentralMessage } from '../../controller/do';
import TableData from '../common/TableData';
import MessageCard from '../CardComponent/MessageCard';
import autoBind from 'auto-bind';
import { FormBloc } from '../../controller/form_bloc';
import { AppDep, BlocProvider, StreamBuilder, StreamModal } from '../../provider';
import { TableDataBloc } from '../../controller/table_data_bloc';
import { Backend } from '../../controller/backend';
import { centralMessageSorter } from '../../controller';
import Log from '../../common/util/log';
import { GlobalContext } from '../../common/modal_boundary';
import { BehaviorSubject } from 'rxjs';
import { ProcessingModalLayout } from '../common/ProcessingModal';
import { HintComponent } from '../common/HintComponent';

const TAG = 'manage-message';

class Bloc {
	public readonly formBloc: FormBloc<EditableCentralMessage>;
	public tableDataBloc: TableDataBloc<EditableCentralMessage>;
	public readonly processingModal: BehaviorSubject<boolean> = new BehaviorSubject(
		false as boolean
	) as BehaviorSubject<boolean>;

	private readonly _api: Backend.Api;

	constructor(appDep: AppDep) {
		autoBind(this);
		this._api = appDep.api;
		this.formBloc = new FormBloc(CentralMessage.Constraints, this.onMessageValidated, {
			message: '',
			title: '',
			attachmentList: [],
			isPinned: false,
			isArchived: false,
		});
		this.tableDataBloc = new TableDataBloc(this.getCentralMessageList, appDep.searchBoxQuery, ['message', 'title']);
	}

	@GlobalContext.handlErrorAsModal([])
	async getCentralMessageList(): Promise<(CentralMessage.Type & { isPinned: boolean })[]> {
		Log.d(TAG, 'inside getCentralMessagesList');
		let centralMessageList = await this._api.getCentralMessages();
		let pinnedMessageId = (await this._api.getPinnedMessage()).pin_message_id ?? -1;
		return centralMessageList
			.sort(centralMessageSorter(pinnedMessageId))
			.map(e => (e.id === pinnedMessageId ? { ...e, isPinned: true } : { ...e, isPinned: false }));
	}

	async onMessageValidated(item: EditableCentralMessage): Promise<void> {
		Log.d(TAG, 'inside onMessageValidated', item);
		this.showProgressModal();
		if (item.id) await this.updateCentralMessage(item);
		else await this.createNewCentralMessage(item);
		this.formBloc.clearAll();
		this.removeProgressModal();
		await this.tableDataBloc.updateData();
	}

	@GlobalContext.handlErrorAsModal()
	@GlobalContext.handleSuccessAsModal(Promise, 'Central-message updated successfully')
	async updateCentralMessage(item: EditableCentralMessage): Promise<void> {
		Log.d(TAG, 'inside updateCentralMessage', item);
		await this._api.updateCentralMessage(item);
		if (item.isPinned) await this._api.updateCentralMessagePinnedId(item.id as number);
	}

	@GlobalContext.handlErrorAsModal()
	@GlobalContext.handleSuccessAsModal(Promise, 'New central-message created successfully')
	async createNewCentralMessage(item: EditableCentralMessage): Promise<void> {
		Log.d(TAG, 'inside createNewCentralMessage', item);
		let id = await this._api.createNewCentralMessage(item);
		if (item.isPinned) await this._api.updateCentralMessagePinnedId(id);
	}

	onCentralMessageClicked(item: EditableCentralMessage) {
		Log.d(TAG, 'inside onCentralMessageClicked', item);
		this.formBloc.setObjectValue(item);
	}

	showProgressModal() {
		Log.d(TAG, 'inside showProgressModal');
		this.processingModal.next(true);
	}

	public removeProgressModal() {
		Log.d(TAG, 'inside removeProgressModal');
		this.processingModal.next(false);
	}
}

export class ManageMessage extends Component<{}> {
	render() {
		return (
			<>
				<div className="manage-hint-component">
					<HintComponent hint="Message template supports following variable: {{{ first_name }}}, {{{ last_name }}}, {{{ bnp_whatsapp_group_link }}}, {{{ community_name }}}, {{{ email_id }}}, {{{ ward_name }}} and {{{ mobile_number }}}" />
				</div>
				<BlocProvider
					create={(appDep: AppDep) => new Bloc(appDep)}
					builder={(bloc: Bloc) => {
						return (
							<>
								<StreamModal onClose={bloc.removeProgressModal} stream={bloc.processingModal}>
									<ProcessingModalLayout message="Please wait while we are uploading the message." />
								</StreamModal>
								<DualLayout
									left={(dualLayoutBloc: DualLayoutBloc) => (
										<StreamBuilder
											stream={bloc.tableDataBloc.data}
											builder={(data: CentralMessage.Type[]) => {
												return (
													<div className="col-selector mt-2">
														<TableData
															deepFindSearch
															searchableFields={['data.message', 'data.title']}
															data={{
																columns: [{ label: 'Messages', field: 'message' }],
																rows: data.map(e => {
																	return {
																		data: {
																			...e,
																			created_at: e.created_at
																				? new Date(
																						e.created_at
																				  ).toLocaleString()
																				: '-',
																		},
																	};
																}),
															}}
															firstColumnBuilder={e => (
																<div
																	onClick={() => {
																		bloc.onCentralMessageClicked(e.data);
																		dualLayoutBloc.onHaveData();
																	}}
																>
																	<>
																		<MDBTooltip
																			domElement
																			tag="span"
																			material
																			placement="top"
																		>
																			<div className="send-message-card">
																				<MessageCard
																					className={'MessageCard'}
																					data={e.data}
																					border={
																						'2px solid  rgba(0,0,0,.125)'
																					}
																					title
																				/>
																			</div>
																			<span>Click to select</span>
																		</MDBTooltip>
																	</>
																</div>
															)}
														/>
													</div>
												);
											}}
										/>
									)}
									right={(dualLayoutBloc: DualLayoutBloc) => (
										<CreateMessageForm
											formBloc={bloc.formBloc}
											onClose={dualLayoutBloc.onDataRemoved}
										/>
									)}
								/>
							</>
						);
					}}
				/>
			</>
		);
	}
}
