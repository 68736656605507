import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import TemporaryDrawer from '../../layout/SideNav';

export class NavBrand extends Component {
	render() {
		return (
			<>
				<div className="ml-2" style={{ display: 'flex' }}>
					<div className="mr-2 mt-2" style={{ width: '50px' }}>
						<img style={{ height: 'auto' }} className="img-fluid" src="/logo.png" alt="logo" />
					</div>
					<div className="brand-div">
						<Navbar.Brand>
							<span className="brand-name"> Bengaluru NavaNirmana Party</span>
							<span className="brand-name-short"> BNP</span>
						</Navbar.Brand>
						<small className="small-brand">B-LINK</small>
					</div>
				</div>
			</>
		);
	}
}

export default NavBrand;
